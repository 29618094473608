import React from 'react';

import cn from 'classnames';
import { Translation, useTranslation } from 'react-i18next';
import { NumberValue, scaleLinear } from 'd3-scale';
import { color } from 'd3-color';
import { connect } from 'react-redux';
import { axisRight } from 'd3-axis';
import { format } from 'd3-format';
import { select } from 'd3-selection';

import {
  toQueryParams,
  differenceAbsRGB,
  debounce,
  validateKeyboardEvent,
} from 'utils/utils';
import { IStore } from 'store/store';
import { MapIdentifier } from 'store/map';
import {
  DynamicLegendStyleTag,
  LegendConfiguration,
} from 'domain/models/map.models';
import { AppConstants, WmsParams } from 'core/constants';
import {
  diffUint8ClampedArray,
  extractDataForLegendSegments,
  hexToRgb,
  ParsedLegendSegment,
  RGBColorArray,
} from 'utils/html-legend.utils';
import { HatchingType, ValueType } from 'domain/models/map-configuration.model';

import { ArrowDownIcon, EditIcon } from 'components/shared/icons';
import { HatchingSegments } from './HatchingSegments';
import { LegendEditTooltip } from './LegendEditTooltip';

import './JSONLegend.scss';
import { isDeviceZoomed } from 'hooks/useDevicePixelRatio';

export type LegendSegment = {
  from: RGBColorArray;
  to: RGBColorArray;
  index: number;
};

export interface JSONLegendProps {
  legendParameters: { [K: string]: string };
  variable: any;
  valueType: ValueType;
  mirroring: boolean;
  side: 'left' | 'right';
  hatching: HatchingType;
  selectedLegendSegments: LegendSegment[];
  hoverColor: Uint8ClampedArray | null;
  legendConfiguration?: LegendConfiguration;
  t?: any;
  onClick(segment: LegendSegment, segments: ParsedLegendSegment[]): void;
}

export interface JSONLegendState {
  opened: boolean;
  parsedLegend: ParsedLegendSegment[];
  showConfigTooltip: boolean;
  configTooltipData?: LegendConfiguration;
  segmentHeight: number;
  colorIndicator: {
    height: number;
    value: number;
    index: number;
  } | null;
}

export class JSONLegend extends React.Component<
  JSONLegendProps,
  JSONLegendState
> {
  static initialState: JSONLegendState = {
    opened: true,
    parsedLegend: [],
    showConfigTooltip: false,
    segmentHeight: 20,
    colorIndicator: null,
  };

  constructor(props: JSONLegendProps) {
    super(props);

    this.state = JSONLegend.initialState;

    this.toggleEditLegendTooltip = this.toggleEditLegendTooltip.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  async componentDidMount() {
    let segmentHeight;
    this.setupResizeListener();
    try {
      const rawLegend = await this.loadLegendXML();
      const parsedLegend = this.parseRawLegend(rawLegend);
      const tooltipConfig = this.extractConfig(parsedLegend);

      segmentHeight =
        Math.min(400, window.innerHeight / 2) / parsedLegend.length;

      this.setState(
        {
          parsedLegend,
          configTooltipData: tooltipConfig,
          segmentHeight,
        },
        () => {
          debounce(() => this.renderLegendAxis(), 100)();
        }
      );
    } catch (err) {
      console.error(err);
    }
  }

  async componentDidUpdate(prevProps: JSONLegendProps) {
    let segmentHeight;
    if (
      prevProps.legendParameters !== this.props.legendParameters ||
      this.props.legendConfiguration !== prevProps.legendConfiguration
    ) {
      try {
        const rawLegend = await this.loadLegendXML();
        const parsedLegend = this.parseRawLegend(rawLegend);
        const tooltipConfig = this.extractConfig(parsedLegend);
        segmentHeight =
          Math.min(400, window.innerHeight / 2) / parsedLegend.length;

        this.setState(
          {
            parsedLegend,
            configTooltipData: tooltipConfig,
            segmentHeight,
          },
          () => {
            debounce(() => this.renderLegendAxis(), 100)();
          }
        );
      } catch (err) {
        console.error(err);
      }
    }

    if (
      diffUint8ClampedArray(prevProps.hoverColor, this.props.hoverColor) &&
      !isDeviceZoomed()
    ) {
      if (this.props.hoverColor) {
        const colorIndicator = this.getColorIndicator(
          segmentHeight || this.state.segmentHeight
        );
        this.setState({ colorIndicator });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  setupResizeListener() {
    window.addEventListener('resize', this.resizeHandler, { passive: true });
  }

  resizeHandler() {
    const segmentHeight =
      Math.min(400, window.innerHeight / 2) / this.state.parsedLegend.length;

    this.setState({ segmentHeight }, () => {
      this.renderLegendAxis();
    });
  }

  parseRawLegend(rawLegend: string): ParsedLegendSegment[] {
    return extractDataForLegendSegments(rawLegend);
  }

  loadLegendXML(): Promise<string> {
    let fullParams: any = this.props.legendParameters;
    if (this.props.legendConfiguration) {
      fullParams = {
        ...fullParams,
        ...this.props.legendConfiguration,
        STYLES: DynamicLegendStyleTag,
      };
    }
    const queryParams = toQueryParams(fullParams);
    if (queryParams === '') {
      return new Promise((res) => res(''));
    }
    const url = `${AppConstants.Api.baseUrl}/wms?${queryParams}&${WmsParams.getLegendRequest}&${WmsParams.legendFormat}`;
    return fetch(url, {
      headers: {
        Authorization: AppConstants.Api.authorization.token,
        'Content-Type': 'text/xml',
      },
    }).then((response: Response) => response.text());
  }

  extractConfig(
    segments: ParsedLegendSegment[]
  ): LegendConfiguration | undefined {
    const _segments = [...segments].slice();
    const sorted = _segments.sort((s1, s2) => s2.meanValue - s1.meanValue);
    const first = sorted.find(
      (s) => !isNaN(parseFloat(s.minValue)) && !isNaN(parseFloat(s.maxValue))
    );
    const last = [...sorted]
      .slice()
      .reverse()
      .find(
        (s) => !isNaN(parseFloat(s.minValue)) && !isNaN(parseFloat(s.maxValue))
      );

    if (first && last) {
      const styleMax = parseFloat(first.minValue);
      const _tmp = parseFloat(first.maxValue);
      const styleMin = parseFloat(last.maxValue);
      const styleTickInterval = styleMax - _tmp;

      return {
        styleMax,
        styleMin,
        styleTickInterval,
      };
    }
  }

  toggleEditLegendTooltip() {
    this.setState({
      showConfigTooltip: !this.state.showConfigTooltip,
    });
  }

  getLegendUnits(variable: any, valueType: string) {
    return (variable?.units ?? [])[valueType];
  }

  fixInfinityValues(val: string): string {
    if (val === 'Infinity') {
      return 'Max';
    }

    if (val === '-Infinity') {
      return 'Min';
    }

    return val;
  }

  onLegendSegmentClick(segment: ParsedLegendSegment) {
    const payload: Partial<LegendSegment> = {
      from: hexToRgb(segment.firstColor),
      to: hexToRgb(segment.lastColor),
      index: segment.index,
    };

    if (payload.from && payload.to) {
      this.props.onClick(payload as LegendSegment, this.state.parsedLegend);
    }
  }

  getSelectedSegmentPosition(
    segment: ParsedLegendSegment
  ): 'top' | 'bottom' | 'none' | null {
    if (this.props.selectedLegendSegments.length < 2) {
      return null;
    }

    const sortedSegments = this.props.selectedLegendSegments.sort(
      (s1, s2) => s2.index - s1.index
    );

    const segmentIndex = segment.index;
    const firstIndex = sortedSegments[0];

    if (segmentIndex === firstIndex.index) {
      return 'top';
    }

    const lastIndex = sortedSegments[sortedSegments.length - 1];
    if (segmentIndex === lastIndex.index) {
      return 'bottom';
    }

    return 'none';
  }

  private getColorIndicator(segmentHeight: number) {
    let index = -1;
    const [r, g, b] = this.props.hoverColor!;

    const targetColor = color(`rgb(${r}, ${g}, ${b})`)?.rgb()!;

    const segment = this.state.parsedLegend.find((s, i) => {
      const firstRgb = color(s.firstColor)?.rgb(),
        lastRgb = color(s.lastColor)?.rgb();

      if (firstRgb && lastRgb) {
        if (
          Math.max(Math.min(firstRgb.r, lastRgb.r) - 1, 0) <= targetColor.r &&
          Math.min(Math.max(firstRgb.r, lastRgb.r) + 1, 255) >= targetColor.r &&
          Math.max(Math.min(firstRgb.g, lastRgb.g) - 1, 0) <= targetColor.g &&
          Math.min(Math.max(firstRgb.g, lastRgb.g) + 1, 255) >= targetColor.g &&
          Math.max(Math.min(firstRgb.b, lastRgb.b) - 1, 0) <= targetColor.b &&
          Math.min(Math.max(firstRgb.b, lastRgb.b) + 1, 255) >= targetColor.b
        ) {
          index = i;
          return true;
        }
      }
      return false;
    });

    if (!segment) {
      return null;
    }

    const diff = differenceAbsRGB(
      color(segment.firstColor)?.rgb()!,
      color(segment.lastColor)?.rgb()!
    );
    const diffWF = differenceAbsRGB(
      color(segment.firstColor)?.rgb()!,
      targetColor
    );

    const heightScale = scaleLinear()
      .range([0, segmentHeight])
      .domain([0, diff]);

    const valueScale = scaleLinear()
      .range([
        0,
        Math.abs(parseFloat(segment.maxValue) - parseFloat(segment.minValue)),
      ])
      .domain([0, diff]);

    const height = heightScale(diffWF) as number;
    const scaleR = valueScale(diffWF) as number;

    const tmpvalue = Math.min(
      parseFloat(segment.minValue),
      parseFloat(segment.maxValue)
    );

    const value = tmpvalue + scaleR;

    return {
      height,
      value,
      index,
    };
  }

  renderLegendAxis() {
    if (!this.state.configTooltipData) {
      return null;
    }
    const totalHeight =
      this.state.segmentHeight * this.state.parsedLegend.length;
    const { styleMax, styleMin } = this.state.configTooltipData;

    const scale = scaleLinear()
      .domain([styleMax, styleMin])
      .range([0, totalHeight]);

    const formatters = {
      K: format('~s'), // Números grandes. Añade K, M, etc...
      N: format(''), // Números enteros menores de 999
      M: format('.2f'), // Números con decimales, formatea a 2 decimales
    };

    const axis = axisRight(scale)
      .ticks(this.state.segmentHeight > 15 ? 10 : 5)
      .tickFormat((n: NumberValue) => {
        if (n > 999 || n < -999) {
          return formatters.K(n);
        } else if (Number.isInteger(n)) {
          return formatters.N(n);
        } else {
          return formatters.M(n);
        }
      });

    select(`#svg-axis-${this.props.side}`).remove();

    select(`.legend.${this.props.side}`)
      .append('svg')
      .attr('id', `svg-axis-${this.props.side}`)
      .attr('width', 20)
      .attr('height', totalHeight + 20)
      .classed('axis-svg', true)
      .append('g')
      .classed('axis-container', true)
      .call(axis);

    select(`#svg-axis-${this.props.side}`)
      .selectAll('.tick>text')
      .each(function (text, index, list) {
        const el = select(this);
        if (index === list.length - 1) {
          el.text(`< ${text}`);
        } else if (index === 0) {
          el.text(`> ${text}`);
        }
      });
  }

  getSegmentRenderInfo(segment: ParsedLegendSegment): {
    position: 'first' | 'last' | null;
  } {
    let position: any = null;

    if (segment.index === 0) {
      position = 'first';
    } else if (segment.index === this.state.parsedLegend.length - 1) {
      position = 'last';
    } else {
      position = null;
    }

    return {
      position,
    };
  }

  renderLegendSegment({
    defs,
    height,
  }: {
    defs: ParsedLegendSegment;
    height: number;
  }) {
    const gradient = `linear-gradient(to top, ${defs.firstColor}, ${defs.lastColor})`;

    let { position } = this.getSegmentRenderInfo(defs);

    const smallTooltips = true;

    const firstValue =
      defs.minLabel || this.fixInfinityValues(defs.minValue as string);
    const lastValue =
      defs.maxLabel || this.fixInfinityValues(defs.maxValue as string);

    const isSelected = this.props.selectedLegendSegments.find(
      (sl) => sl.index === defs.index
    );

    const minMaxCN = this.getSelectedSegmentPosition(defs);

    return (
      <div
        className={`legend-item__container ${isSelected ? 'active' : ''}`}
        key={defs.index}
      >
        <div
          tabIndex={0}
          className={`legend-item ${position ?? ''}`}
          aria-label={this.props.t('components:legend.segment.aria.label', {
            from: lastValue,
            to: firstValue,
            units: this.getLegendUnits(
              this.props.variable,
              this.props.valueType
            ),
          })}
          aria-checked={Boolean(isSelected)}
          style={{ backgroundImage: gradient, height: `${height}px` }}
          onClick={() => this.onLegendSegmentClick(defs)}
          onKeyDown={(evt) => {
            validateKeyboardEvent(() => this.onLegendSegmentClick(defs))(evt);
          }}
        />
        <div
          className={`max-min__container ${smallTooltips ? 'small' : ''} ${
            minMaxCN || ''
          }`}
          style={{ height: `${height}px` }}
        >
          <span className='max'>
            {!isNaN(Number(firstValue))
              ? Number(firstValue).toFixed(2)
              : firstValue}
          </span>
          <span className='min'>
            {!isNaN(Number(lastValue))
              ? Number(lastValue).toFixed(2)
              : lastValue}
          </span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className={cn('ipcc-json-legend-container', this.props.side, {
          mirroring: this.props.mirroring,
          open: this.state.opened,
          close: !this.state.opened,
          side: this.props.side,
        })}
      >
        <LegendEditTooltip
          onClose={this.toggleEditLegendTooltip}
          show={this.state.showConfigTooltip}
          tooltipConfig={this.state.configTooltipData}
          valueType={this.props.valueType}
          mapIdentifier={
            this.props.side === 'left' ? 'primaryMap' : 'secondaryMap'
          }
        />
        <div className='legend-units'>
          {this.getLegendUnits(this.props.variable, this.props.valueType)}
          <EditIcon
            className='edit-legend-icon'
            tabIndex={0}
            onClick={this.toggleEditLegendTooltip}
            onKeyDown={(evt) => {
              validateKeyboardEvent(this.toggleEditLegendTooltip)(evt);
            }}
            title={'Edit legend'}
          />
        </div>
        <div className='legend-wrapper'>
          <div className={`legend ${this.props.side}`}>
            {this.state.parsedLegend.map(
              (ls: ParsedLegendSegment, index: number) => {
                return this.renderLegendSegment({
                  defs: ls,
                  height: this.state.segmentHeight,
                });
              }
            )}
            {this.state.colorIndicator ? (
              <div
                className='color-indicator'
                style={{
                  bottom: `${
                    this.state.segmentHeight *
                      (this.state.parsedLegend.length -
                        this.state.colorIndicator.index -
                        1) +
                    this.state.colorIndicator.height
                  }px`,
                }}
              />
            ) : null}
          </div>
        </div>

        {['ANOMALY', 'RELATIVE_ANOMALY', 'TREND'].includes(
          this.props.valueType
        ) && (
          <HatchingSegments
            side={this.props.side}
            hatching={this.props.hatching}
            valueType={this.props.valueType}
          />
        )}

        <Translation ns='components'>
          {(t) => (
            <i
              role='button'
              title={
                this.state.opened
                  ? t('components:legend.collapseButton.collapse.title')
                  : t('components:legend.collapseButton.expand.title')
              }
              className={`resize-legend ${
                this.state.opened ? 'open' : 'close'
              }`}
            >
              <ArrowDownIcon
                tabIndex={0}
                aria-label={
                  this.state.opened
                    ? t('components:legend.collapseButton.collapse.aria.label')
                    : t('components:legend.collapseButton.expand.aria.label')
                }
                width='20px'
                height='15px'
                onClick={() => {
                  this.setState({ opened: !this.state.opened });
                }}
                onKeyDown={(evt) => {
                  validateKeyboardEvent(() =>
                    this.setState({ opened: !this.state.opened })
                  )(evt);
                }}
                className=''
              />
            </i>
          )}
        </Translation>
      </div>
    );
  }
}

const refLegend = React.forwardRef(
  (props: JSONLegendProps, ref: React.Ref<JSONLegend>) => {
    const { t } = useTranslation('components');

    const newProps = { ...props, t };

    return <JSONLegend {...newProps} ref={ref} />;
  }
);

const mapStoreToProps = (store: IStore, props: JSONLegendProps) => {
  let mapId: MapIdentifier;
  if (props.side === 'left') {
    mapId = 'primaryMap';
  } else {
    mapId = 'secondaryMap';
  }

  return {
    legendConfiguration: store.map[mapId].legendConfiguration,
  };
};

export default connect(mapStoreToProps)(refLegend);
