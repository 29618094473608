import React from 'react';

import { useTranslation } from 'react-i18next';

import { getDeepestValue, formatNumber } from 'utils/utils';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import { FeatureInfo } from 'domain/models/map.models';
import { ValueTypePrefixes } from 'domain/models/map-configuration.model';
import { MapIdentifier } from 'store/map';
import { getNumberOfSignificantDecimals } from 'utils/plots/plot.utils';

import { PlusIcon } from 'components/shared/icons';

import './InfoTooltip.scss';

interface InfoTooltipProps {
  featureInfo: FeatureInfo[];
  selectedOptions: ControllerOptionsState;
  mapIdentifier: MapIdentifier;
  onClose(evt: any): void;
}

export const InfoTooltip = (props: InfoTooltipProps) => {
  const { t, i18n } = useTranslation(['components', 'config']);

  React.useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        document.removeEventListener('keydown', handler);
        props.onClose(evt);
      }
    };

    document.addEventListener('keydown', handler, { passive: true });

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);

  const fixCoord = (coord: number) => coord.toFixed(2);
  const point = props.featureInfo[0].point;
  const layer = props.selectedOptions.variable;
  const magnitudeName = props.selectedOptions.valueType;
  const data = props.featureInfo
    .filter((info: FeatureInfo) => {
      const magnitude_suffix =
        magnitudeName === 'VALUE' ? '' : ValueTypePrefixes[magnitudeName];
      return info.name === layer.code + magnitude_suffix;
    })
    .map((info: FeatureInfo) => {
      let datum = parseFloat(getDeepestValue<any, string>(info.data));
      return {
        name: info.name,
        units: typeof info.units === 'string' ? info.units : '',
        value: formatNumber(datum, i18n.language, {
          maximumFractionDigits: 1,
        }),
      };
    });

  const magnitude = (varType: string) =>
    varType === 'VALUE'
      ? ''
      : ' (' + t('config:magnitude.' + varType.toLowerCase()) + ')';
  const variable = (varName: string) => t('config:variable.' + varName);

  return (
    <div className='ipcc-feature-tooltip'>
      <div
        className='close-icon '
        id={`feature-tooltip-closer-${props.mapIdentifier}`}
        title={t('infoTooltip.close.title')}
      >
        <PlusIcon
          tabIndex={0}
          aria-label={t('infoTooltip.close.title')}
          onClick={(evt: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            evt.stopPropagation();
            props.onClose(evt);
          }}
          width='15px'
        />
      </div>
      <div className='feature-tooltip-title'>
        {' '}
        {variable(layer.name) + magnitude(magnitudeName)}{' '}
      </div>
      <div className='feature-tooltip-point'>
        {' '}
        <span className='label'>
          {t('components:infoTooltip.data.point.title')}:
        </span>
        <span className='value'>{`Lon: ${fixCoord(point[0])}° Lat: ${fixCoord(
          point[1]
        )}°`}</span>
      </div>
      <div className='feature-tooltip-value'>
        {data.map((d) => (
          <div key={d.name}>
            <span className='label'>
              {t('components:infoTooltip.data.value.title')}:
            </span>
            <span className='value'>{`${d.value || '-'} ${
              layer.units[props.selectedOptions.valueType] ?? ''
            }`}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
