import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';

import { Routes } from 'core';

import { MapPage } from 'containers/MapPage';
import { CoreLayout } from 'components/core-layout/CoreLayout';
import { useTranslation } from 'react-i18next';

// Lazy loaded static pages
const LandingPage = React.lazy(
  () =>
    import(/* webpackChunkName: "landing.static" */ 'containers/LandingPage')
);

const ReviewContainer = React.lazy(
  () =>
    import(/* webpackChunkName: "review.page" */ 'containers/ReviewContainer')
);

const RegionalInformationAboutPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "regional-information-about.static" */ 'containers/RegionalInformationAbout'
    )
);

const RegionalInformationGuidancePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "regional-information-guidance.static" */ 'containers/RegionalInformationGuidance'
    )
);

const RegionalSynthesisAboutPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "regional-synthesis-about.static" */ 'containers/RegionalSynthesisAbout'
    )
);

const LicensePage = React.lazy(
  () =>
    import(/* webpackChunkName: "license.static" */ 'containers/LicensePage')
);
const DocumentationPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "documentation.static" */ 'containers/DocumentationPage'
    )
);
const CIDMapPage = React.lazy(
  () => import(/* webpackChunkName: "cid-map.module" */ 'containers/CIDMapPage')
);

export const Router = () => {
  const { pathname } = useLocation();
  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    if (pathname !== Routes.CID) {
      i18n.changeLanguage('en');
    }
  }, [pathname]);

  return (
    <Switch>
      <Route path={Routes.Landing} exact component={LandingPage} />
      <Route path={Routes.AppRoot}>
        <CoreLayout>
          <Switch>
            <Route
              path={`${Routes.Review}/:reviewCode`}
              component={ReviewContainer}
            />
            <Route
              path={Routes.Documentation}
              component={() => <DocumentationPage />}
            />
            <Route
              path={Routes.RegionalInformationAbout}
              component={() => <RegionalInformationAboutPage />}
            />
            <Route
              path={Routes.RegionalInformationGuidance}
              component={() => <RegionalInformationGuidancePage />}
            />
            <Route
              path={Routes.RegionalSyntesisAbout}
              component={() => <RegionalSynthesisAboutPage />}
            />
            <Route path={Routes.License} component={() => <LicensePage />} />
            <Route path={Routes.Map} component={MapPage} />
            <Route path={Routes.CID} component={CIDMapPage} />
            <Route path={Routes.AppRoot}>
              <Redirect to={Routes.Landing} />
            </Route>
          </Switch>
        </CoreLayout>
      </Route>
      <Route path='/'>
        <Redirect to={Routes.Landing} />
      </Route>
    </Switch>
  );
};
