import { useState, useEffect } from 'react';

import { getIsMobile } from 'utils/device';

export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  const handler = () => {
    setIsMobile(getIsMobile);
  };

  useEffect(() => {
    window.addEventListener('resize', handler, { passive: true });
    return () => window.removeEventListener('resize', handler);
  }, []);

  return isMobile;
}
