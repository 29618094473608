import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { ConfidenceValues } from 'domain/models/cid.model';

export const selectedFeatureStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(196, 196, 196, 1)',
    width: 1,
  }),
  fill: new Fill({
    color: 'rgba(84, 146, 205, 0.4)',
  }),
});

export const hoveredFeatureStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(196, 196, 196, 1)',
    width: 1,
  }),
  fill: new Fill({
    color: 'rgba(190, 190, 190, 0.4)',
  }),
});

export const unselectedFeatureStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(196, 196, 196, 1)',
    width: 1,
  }),
  fill: new Fill({
    color: 'rgba(0,0,0,0)',
  }),
});

export const defaultCIDStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(191, 191, 191, 1)',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(191, 191, 191, 0.8',
  }),
});

export const CIDColors = {
  '6': { base: 'rgba(142, 71, 132, 1)', map: 'rgba(142, 71, 132, 0.8)' },
  '5': { base: 'rgba(226, 159, 186 , 1)', map: 'rgba(226, 159, 186 , 0.8)' },
  '4': { base: 'rgba(247, 247, 247, 1)', map: 'rgba(247, 247, 247, 0.8)' },
  '3': { base: 'rgba(234, 191, 121, 1)', map: 'rgba(234, 191, 121, 0.8)' },
  '2': { base: 'rgba(204, 102, 51 , 1)', map: 'rgba(204, 102, 51 , 0.8)' },
  '1': { base: 'rgba(191, 191, 191, 1)', map: 'rgba(191, 191, 191, 0.8' },
};

const stroke = new Stroke({
  color: 'rgba(191, 191, 191, 0.8)',
  width: 1,
});

export const CIDstyles: { [K in ConfidenceValues]: Style } = {
  '6': new Style({
    stroke,
    fill: new Fill({
      color: CIDColors[6].map,
    }),
  }),
  '5': new Style({
    stroke,
    fill: new Fill({
      color: CIDColors[5].map,
    }),
  }),
  '4': new Style({
    stroke,
    fill: new Fill({
      color: CIDColors[4].map,
    }),
  }),
  '3': new Style({
    stroke,
    fill: new Fill({
      color: CIDColors[3].map,
    }),
  }),
  '2': new Style({
    stroke,
    fill: new Fill({
      color: CIDColors[2].map,
    }),
  }),
  1: defaultCIDStyle,
};
