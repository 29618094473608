import { AppConstants } from 'core/constants';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import { Metadata } from 'domain/models/metadata.model';
import { IPCCProjection } from 'domain/models/map.models';
import { Url } from 'simple-query-params';
import { WMSKeeper } from 'utils/wms-url-keeper';
import { HatchingType } from 'domain/models/map-configuration.model';

const getBBox = (url: any) : string | undefined => {
  if (url.getParam("BBOX") && url.getParam("BBOX").value) {
    return url.getParam("BBOX").value
  }
  return undefined;
}

export const getMetadata = (
  selectedOptions: ControllerOptionsState, projection: IPCCProjection, hatching: HatchingType
): Promise<Metadata[]> => {
  if (!selectedOptions.dataset?.model) {
    throw new Error('Empty parameters');
  }

  const params = getParams(selectedOptions, projection, hatching);

  return fetch(
    AppConstants.Api.baseUrl + AppConstants.Api.endpoints.metadata + params,
    {
      method: 'GET',
      headers: { Authorization: AppConstants.Api.authorization.token }
    }
  ).then(r => r.json());
};

export const exportMetadataURL = (
  selectedOptions: ControllerOptionsState, projection: IPCCProjection, hatching: HatchingType
): string => {
  if (!selectedOptions.dataset?.model) {
    throw new Error('Empty parameters');
  }
  const params = getParams(selectedOptions, projection, hatching);
  return AppConstants.Api.baseUrl + AppConstants.Api.endpoints.metadataExport + params;
};

const getParams = (selectedOptions: ControllerOptionsState, projection: IPCCProjection, hatching: string) => {

  const url = new Url(WMSKeeper.getUrl());
  const bbox : string = getBBox(url) || "";
  return Object.entries({
    project: selectedOptions.dataset.model.code,
    scenario: selectedOptions.scenario.code,
    variable: selectedOptions.variable.code,
    temporalFilter: selectedOptions.season.code,
    valueType: selectedOptions.valueType,
    baseline: selectedOptions.baseline.code,
    period: selectedOptions.period.wmsCode,
    projection: projection.epsg,
    bbox: bbox,
    hatching: hatching,
  }).reduce((acc, [key, val]) => {
    return `${acc}&${key}=${val}`;
  }, '?');
}

