import React from 'react';

import ReactSelect, { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';

import './Select.scss';

type SelectValue = string | string[] | number;

interface SelectProps {
  id: string;
  label?: string;
  title?: string;
  placeholder?: string;
  className?: string;
  value: SelectValue;
  options: any;
  onChange({ label, value }: { label: string; value: SelectValue }): void;
  [K: string]: any;
}

export const Select = React.forwardRef(
  (
    {
      className,
      title,
      label,
      id,
      value,
      options,
      onChange,
      placeholder,
      ...rest
    }: React.PropsWithChildren<SelectProps>,
    ref: React.Ref<any>
  ) => {
    const { t } = useTranslation('components');

    const isMobile = useIsMobile();

    const selectedOption = React.useMemo(() => {
      return options.find((o: any) => o.value === value);
    }, [value, options]);

    const Option = (props: any) => {
      return (
        <div
          title={props.title ?? props.label}
          tabIndex={0}
          role='listitem'
          aria-label={t('components:select.aria.option', {
            label: props.title ?? props.label,
          })}
          aria-selected={props.isSelected}
        >
          <components.Option {...props} />
        </div>
      );
    };

    return (
      <div
        className={`ipcc-select ${isMobile ? 'native' : ''} ${className || ''}`}
        title={title}
      >
        {label && (
          <label className='ipcc-select__label' htmlFor={id}>
            {label}
          </label>
        )}
        {!isMobile ? (
          <ReactSelect
            role='listitem'
            aria-label={t('components:select.aria.select', {
              label: rest['aria-label'] ?? label,
            })}
            tabIndex={rest.tabIndex || 0}
            ref={ref}
            id={id}
            className={`select ${className ?? ''}`}
            classNamePrefix='ipcc-react-select'
            value={selectedOption}
            menuPosition='fixed'
            options={options}
            components={{ Option }}
            onChange={(value: any) =>
              onChange({ label: '', value: value.value })
            }
          />
        ) : (
          <select
            ref={ref}
            title={title}
            placeholder={placeholder || ''}
            className='ipcc-select__select-native'
            onChange={(evt: any) => {
              onChange({ value: evt.target.value, label: '' });
            }}
            value={value}
            id={id}
            {...rest}
          >
            {options.map(({ label, value }: { label: string; value: any }) => (
              <option value={value} key={value}>
                {' '}
                {label}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }
);
