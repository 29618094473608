
export const getShowTour = (page: 'atlas' | 'cid' | 'charts') => {
  try {
    const raw = localStorage.getItem(`ipcc-${page}::show-tour`);
    return raw !== null ? JSON.parse(raw) : true;
  } catch (err) {
    return true;
  }
};

export const setShowTour = (page: 'atlas' | 'cid' | 'charts', show: boolean) => {
  try {
    localStorage.setItem(`ipcc-${page}::show-tour`, JSON.stringify(show));
  } catch (err) {
    // ignore
  }
};