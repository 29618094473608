import { clear } from './utils';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import { TFunction } from 'i18next';
import {
  ValueTypePrefixes,
  RegionSet,
  GeojsonsByRegionSetAndProjection,
} from 'domain/models/map-configuration.model';
import { DeepNullable, Nullable } from 'domain/models/shared.types';
import {
  IPCCProjection,
  IPCCProjections,
  RegionConfiguration,
} from 'domain/models/map.models';
import { FeatureLike } from 'ol/Feature';
import { getWidth } from 'ol/extent';
import { PlotType } from 'domain/models/charts.model';

type MapCaptionRT = {
  dataset: string;
  variable: string;
  valueType: string;
  scenario: string;
  period: string;
  timeFilter: string;
  title: string;
  mapExportTitle: string;
};

export const getResolutions = (extent: any) => {
  let resolutions = new Array(22);
  let matrixIds = new Array(22);
  let tileSizes = new Array(22);
  const size = getWidth(extent) / 256;
  for (let z = 0; z < 22; ++z) {
    // generate resolutions and matrixIds arrays for this WMTS
    tileSizes[z] = [1024, 1024];
    resolutions[z] = size / Math.pow(2, z);
    matrixIds[z] = z;
  }
  return { resolutions, matrixIds, tileSizes };
};

export const isStereographic = (proj: IPCCProjection) => {
  return ['EPSG:3413', 'EPSG:3412'].includes(proj.epsg);
};

export const mapIdsToFeatures = (ids: string[], features: any[]) =>
  clear(
    ids.map((id: string) => {
      const found = features.find((f: any) => f.id == id);
      return found ? found.properties : undefined;
    })
  );

const seasonLiteral = (
  code: string,
  options: ControllerOptionsState,
  t?: TFunction
) => {
  if (code !== 'custom' && code !== 'year') {
    const firstMonth = options.season.code.substring(0, 3);
    const lastMonth = options.season.code.substring(3, 7);
    let literal = t ? t('config:season.' + firstMonth) : firstMonth;
    if (lastMonth) {
      literal += t
        ? ' ' + t('config:season.to') + ' ' + t('config:season.' + lastMonth)
        : `-${lastMonth}`;
    }
    return literal;
  } else {
    return t ? t('config:season.' + code) : code;
  }
};

export const selectedOptionsToReadableText = (
  options: ControllerOptionsState,
  t: TFunction,
  mirroring: boolean = false,
  forChart?: PlotType
): MapCaptionRT | null => {
  if (options && options.dataset && options.variable) {
    const hidePeriod =
      forChart &&
      ['scatter', 'stripes', 'seasonal-stripes', 'gwl-plot', 'table'].includes(
        forChart
      );

    const isHistorical =
      options.dataset.model.family === 'Historical-Projections';
    const dataset = t(`config:dataset.${options.dataset.model.name}`);
    const variable = t(`config:variable.${options.variable.code}`);
    const varUnits = options.variable.units[options.valueType];
    const valueType =
      options.valueType === 'VALUE'
        ? ''
        : t(`config:magnitude.${options.valueType.toLowerCase()}`);
    const scenario =
      options.dataset.model.family !== 'Historical-Projections'
        ? t(`config:scenario.${options.scenario?.code.toLowerCase()}`)
        : '';
    const period = !hidePeriod
      ? t(
          `config:period.${options.period?.code.toLowerCase()}.${
            isHistorical ? 'caption' : 'label'
          }`,
          { defaultValue: '' }
        )
      : '';
    const timeFilter = seasonLiteral(options.season.code, options, t);
    const baseline =
      (options.valueType !== 'VALUE' && options.valueType !== 'TREND') ||
      !options.dataset.model.family.toLowerCase().includes('historical')
        ? t(`config:baseline.${options.baseline.code.toLowerCase()}.label`)
        : '';

    const baselineStr =
      baseline && options.valueType !== 'VALUE' ? `(rel. to ${baseline})` : '';

    const modelCount = getModelCount(options);

    const modelCountStr = modelCount
      ? t('map:controls.caption.modelCount.label', { modelCount })
      : '';

    const title = `${dataset} - ${variable} ${valueType || ''} ${varUnits}${
      mirroring ? '\n' : ' - '
    }${period} ${scenario} ${baselineStr} - ${timeFilter} ${modelCountStr}`;

    const mapExportTitle = `${variable} - ${valueType} (${varUnits})\n${period} ${
      hidePeriod ? scenario : `(${scenario})`
    } ${baselineStr}\n${dataset} - ${timeFilter} ${modelCountStr}`;

    return {
      dataset,
      variable,
      valueType: valueType || '',
      scenario,
      period,
      timeFilter,
      title,
      mapExportTitle,
    };
  }
  return null;
};

export const selectedOptionsToCodes = (options: ControllerOptionsState) => {
  const dataset = options.dataset.model.code;
  const variable = options.variable.code;
  const units = options.variable.units[options.valueType];
  const scenario =
    options.dataset.model.family !== 'Historical' ? options.scenario.code : '';
  const period = options.period.code;
  const baseline =
    options.valueType !== 'VALUE' ||
    !options.dataset.model.family.toLowerCase().includes('historical')
      ? options.baseline.code
      : '';
  const timeFilter = seasonLiteral(options.season.code, options);

  return `${dataset}-${variable}-${options.valueType}-${units}-${period}-${scenario}-${baseline}-${timeFilter}`;
};

export const selectedOptionsToLegendParameters = (
  selectedOptions: ControllerOptionsState
) => {
  if (selectedOptions.dataset) {
    return {
      dataset: `${selectedOptions.dataset.model.code}-${selectedOptions.scenario.code}`,
      layer:
        selectedOptions.variable.code +
        ValueTypePrefixes[selectedOptions.valueType],
      style: selectedOptions.variable.styles[selectedOptions.valueType],
    };
  } else {
    return {};
  }
};

export const getModelCount = (
  options: ControllerOptionsState
): number | undefined => {
  if (
    options.dataset?.modelCount &&
    options.scenario?.code &&
    options.variable?.code &&
    options.period?.code
  ) {
    const scenarioCount = options.dataset.modelCount[options.scenario.code];
    if (scenarioCount) {
      const variableCount = scenarioCount[options.variable.code] ?? undefined;
      if (variableCount) {
        if (variableCount[options.period.code]) {
          return variableCount[options.period.code] ?? undefined;
        } else {
          return variableCount.all ?? undefined;
        }
      }
    }
  }
  return undefined;
};

export const isFeatureAllowed = (
  feature: FeatureLike | string | any,
  selectedOptions: DeepNullable<ControllerOptionsState>,
  regionsConfig: Nullable<RegionConfiguration>,
  regionSet: RegionSet
): boolean | null => {
  const getFeatureName = (f: FeatureLike | string) => {
    if ('string' === typeof f) {
      return f;
    } else if (f.getProperties) {
      return f.getProperties().Acronym;
    } else {
      return (f as any).properties.Acronym;
    }
  };
  if (feature && selectedOptions && regionsConfig) {
    const config = regionsConfig[selectedOptions.dataset?.model.name || ''];
    if (config) {
      const regConfig = config[regionSet];
      if (regConfig) {
        if (regConfig.includes(getFeatureName(feature))) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  return null;
};

export const getRegionSetToLoad = (
  regionSet: RegionSet,
  selectedProjection: keyof IPCCProjections
) => {
  return GeojsonsByRegionSetAndProjection[selectedProjection][regionSet];
};

export const areAllRegionsSelected = (
  geojson: any,
  selectedRegions: any[],
  selectedOptions: DeepNullable<ControllerOptionsState>,
  regionsConfig: Nullable<RegionConfiguration>,
  regionSet: RegionSet
): boolean => {
  const allowedFeatures = getAllowedRegions(
    geojson,
    selectedOptions,
    regionsConfig,
    regionSet
  );
  return allowedFeatures.every((f: any) =>
    selectedRegions.find((sr: any) => sr.id === f.id)
  );
};

export const getAllowedRegions = (
  geojson: any,
  selectedOptions: DeepNullable<ControllerOptionsState>,
  regionsConfig: Nullable<RegionConfiguration>,
  regionSet: RegionSet
) => {
  return geojson.features
    .filter(
      (f: any) =>
        isFeatureAllowed(f, selectedOptions, regionsConfig, regionSet) !== false
    )
    .map((f: any) => f.properties);
};
