import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { Link, NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { Routes } from 'core';
import { IStore } from 'store/store';
import { showTour } from 'store/core';
import { useIsMobile } from 'hooks/useIsMobile';
import { validateKeyboardEvent } from 'utils/utils';

import { LangSelect } from 'components/lang-select/LangSelect';
import ipccLogo from 'assets/icons/ipcc_altlogo_full_white.png';
import { MobileMenu } from 'components/mobile-menu/MobileMenu';
import { AnimatedMenuButton } from 'components/animated-menu-button/AnimatedMenuButton';
import { ArrowDownIcon, InfoIcon } from 'components/shared/icons';

import './Header.scss';
import { AtlasMode, setAtlasMode } from 'store/map';

export const Header = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { mode } = useSelector((store: IStore) => store.map.commons);

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const { t } = useTranslation(['components']);

  const isMobile = useIsMobile();

  const onShowTour = () => {
    if (pathname === Routes.Map) {
      dispatch(showTour('atlas'));
    } else if (pathname === Routes.CID) {
      dispatch(showTour('cid'));
    }
  };

  const title = React.useMemo(() => {
    return t('components:header.title.title', {
      path:
        pathname === Routes.Map
          ? t('components:header.title.paths.regionalInformation', {
              mode: t(
                `components:header.title.regionalInformationMode.${mode}`
              ),
            })
          : t('components:header.title.paths.regionalSynthesis'),
    });
  }, [pathname, t, mode]);

  return (
    <>
      <div className={`ipcc-app__header ${isMobile ? 'mobile' : ''}`}>
        <div className='ipcc-app__left-side' role='navigation'>
          <Link
            to={Routes.Landing}
            title={t('components:header.images.ipcc.title')}
            aria-label={t('components:header.images.ipcc.aria.label')}
            tabIndex={0}
            className='ipcc-logo-link'
          >
            <img
              alt={t('components:header.images.ipcc.aria.alt')}
              src={ipccLogo}
              className='ipcc-logo'
            />
          </Link>
          <div className='ipcc-text__container'>
            <span
              className='main-title'
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>
        <div className='ipcc-app__right-side'>
          <div className='ipcc-app__link-container'>
            <HomeDropdown />
            {pathname !== Routes.Documentation && (
              <NavLink
                activeClassName='active'
                tabIndex={0}
                aria-label={t('components:header.menu.about.aria.label')}
                className='ipcc-header-link'
                to={
                  pathname === Routes.CID
                    ? Routes.RegionalSyntesisAbout
                    : Routes.RegionalInformationAbout
                }
                target='_blank'
              >
                {t('components:header.menu.about.title')}
              </NavLink>
            )}

            {pathname.includes(Routes.Map) && (
              <NavLink
                activeClassName='active'
                tabIndex={0}
                aria-label={t('components:header.menu.guidance.aria.label')}
                className='ipcc-header-link'
                to={Routes.RegionalInformationGuidance}
                target='_blank'
              >
                {t('components:header.menu.guidance.title')}
              </NavLink>
            )}

            <NavLink
              activeClassName='active'
              tabIndex={0}
              aria-label={t('components:header.menu.license.aria.label')}
              className='ipcc-header-link'
              to={Routes.License}
              target='_blank'
            >
              {t('components:header.menu.license.title')}
            </NavLink>
            {/* {pathname === Routes.CID && <LangSelect />} */}
            {pathname === Routes.Map && (
              <InfoIcon
                width={15}
                height={15}
                title={t('components:header.infoButton')}
                className='show-tour-icon'
                onClick={onShowTour}
                onKeyDown={validateKeyboardEvent(onShowTour)}
                tabIndex={0}
              />
            )}
          </div>
        </div>
        <AnimatedMenuButton
          open={showMobileMenu}
          cssClass='ipcc-header__menu-icon'
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
      </div>
      <CSSTransition
        in={showMobileMenu}
        timeout={300}
        classNames='ipcc-mobile-menu-transition'
        unmountOnExit
      >
        <MobileMenu onRouteSelected={() => setShowMobileMenu(false)} />
      </CSSTransition>
    </>
  );
};

const HomeDropdown = () => {
  const { t } = useTranslation('components');

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { mode } = useSelector((store: IStore) => store.map.commons);

  const navigateToRegionalInformation = () => {
    if (mode === AtlasMode.COMPLETE) {
      dispatch(setAtlasMode(AtlasMode.SIMPLE));
    } else {
      dispatch(setAtlasMode(AtlasMode.COMPLETE));
    }

    history.push(Routes.Map);
  };

  const regionalInformationLinkLabel = React.useMemo(() => {
    if (location.pathname !== Routes.Map) {
      return t(`components:header.menu.dropdown.regionalInformation.title`);
    }
    if (mode === AtlasMode.COMPLETE) {
      return `${t(
        `components:header.menu.dropdown.regionalInformation.title`
      )} (${t(
        `components:header.menu.dropdown.regionalInformation.modes.${AtlasMode.SIMPLE}`
      )})`;
    } else {
      return `${t(
        `components:header.menu.dropdown.regionalInformation.title`
      )} (${t(
        `components:header.menu.dropdown.regionalInformation.modes.${AtlasMode.COMPLETE}`
      )})`;
    }
  }, [mode, t, location]);

  return (
    <div className={`home-dropdown`}>
      <div className='home-link-container'>
        <NavLink
          activeClassName='active'
          tabIndex={0}
          exact
          title={t('components:header.menu.home.title')}
          aria-label={t('components:header.menu.home.aria.label')}
          className='home-link'
          to={Routes.Landing}
        >
          {t('components:header.menu.home.title')}
          <ArrowDownIcon className='dropdown-arrow' width={12} height={12} />
        </NavLink>
      </div>
      <div className='dropdown-body'>
        <div className='dropdown-link-container'>
          <a
            tabIndex={0}
            aria-label={t(
              'components:header.menu.dropdown.regionalInformation.aria.label'
            )}
            className={`ipcc-header-link`}
            onClick={navigateToRegionalInformation}
          >
            {regionalInformationLinkLabel}
          </a>
        </div>
        <div className='dropdown-link-container'>
          <NavLink
            activeClassName='active'
            exact
            tabIndex={0}
            aria-label={t(
              'components:header.menu.dropdown.regionalSynthesis.aria.label'
            )}
            className='ipcc-header-link'
            to={Routes.CID}
          >
            {t('components:header.menu.dropdown.regionalSynthesis.title')}
          </NavLink>
        </div>
        <div className='dropdown-link-container'>
          <NavLink
            activeClassName='active'
            exact
            tabIndex={0}
            aria-label={t(
              'components:header.menu.dropdown.documentation.aria.label'
            )}
            className='ipcc-header-link'
            to={Routes.Documentation}
          >
            {t('components:header.menu.dropdown.documentation.title')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
