import { LegendSegment } from 'components/json-legend/JSONLegend';

type RGBAObject = { r: number; g: number; b: number; a: number };

const isWasmSupported = ((): boolean => {
  try {
    if (
      typeof WebAssembly === 'object' &&
      typeof WebAssembly.instantiate === 'function'
    ) {
      return true;
    }
  } catch (e) {}
  return false;
})();

export class CanvasFilter {
  private _originalData: ImageData | undefined = undefined;
  private _masking: boolean = false;
  private waskMask?: (
    js_image_data: Uint8ClampedArray,
    js_segments: any
  ) => Uint8ClampedArray;

  constructor() {
    if (isWasmSupported) {
      console.log(
        'Browser supports WebAssembly, loading canvas-masker wasm module'
      );
      import('canvas-masker').then(({ mask }) => {
        this.waskMask = mask;
      });
    } else {
      console.log(
        'Browser don`t supports WebAssembly, js version will be used'
      );
    }
  }

  public unmask(canvas: HTMLCanvasElement): void {
    this._unmask(canvas);
  }

  public setOriginalData(canvas: HTMLCanvasElement): void {
    if (!canvas) {
      return;
    }
    if (canvas.clientHeight === 0 || canvas.clientWidth === 0) {
      return;
    }

    const ctx = canvas.getContext('2d');

    this._originalData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
  }

  public maskAllSegments(
    canvas: HTMLCanvasElement,
    segments: LegendSegment[]
  ): ImageData {
    const ctx = canvas.getContext('2d', { desynchronized: true });
    const imageData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
    const { width, height } = canvas;

    const data = Uint8ClampedArray.from(imageData.data.slice());
    if (this.waskMask) {
      const _segments = segments.map(({ from, to }) => ({ from, to }));
      const _imageData = this.waskMask(data, _segments);
      return new ImageData(_imageData, width, height);
    } else {
      let passed = false;
      for (let y = 0; y < data.length; y += 4) {
        passed = false;
        const [r, g, b, a] = data.slice(y, y + 4);
        if (a == 0) continue;
        for (let segment of segments) {
          const { from, to } = segment;
          if (
            !(
              Math.min(from[0], to[0]) - 1 <= r &&
              Math.max(from[0], to[0]) + 1 >= r &&
              Math.min(from[1], to[1]) - 1 <= g &&
              Math.max(from[1], to[1]) + 1 >= g &&
              Math.min(from[2], to[2]) - 1 <= b &&
              Math.max(from[2], to[2]) + 1 >= b
            )
          ) {
            continue;
          } else {
            passed = true;
            break;
          }
        }

        if (!passed) {
          data[y + 3] = 90;
        }
      }
      return new ImageData(data, width, height);
    }
  }

  private _mask({
    color,
    canvas,
  }: {
    color: RGBAObject;
    canvas: HTMLCanvasElement;
  }): void {
    if (this._originalData) {
      this.unmask(canvas);
      this._originalData = undefined;
    }
    const ctx = canvas.getContext('2d');
    this._originalData = ctx!.getImageData(0, 0, canvas.width, canvas.height);
    const data = Uint8ClampedArray.from(this._originalData.data.slice());
    for (let y = 0; y < data.length; y += 4) {
      const [r, g, b, a] = data.slice(y, y + 4);
      if ((color.r !== r || color.g !== g || color.b !== b) && a !== 0) {
        data[y + 3] = 90;
      }
    }
    const { width, height } = canvas;
    const newImageData = new ImageData(data, width, height);
    ctx!.putImageData(newImageData, 0, 0);
  }

  private _unmask(canvas: HTMLCanvasElement): void {
    const ctx = canvas.getContext('2d');
    if (this._originalData) {
      ctx!.putImageData(this._originalData, 0, 0);
      this._originalData = undefined;
    }
  }
}
