import { RegionSet } from './map-configuration.model';
export interface FeatureInfo {
  data: { [k: string]: number };
  dims: string;
  name: string;
  units: string;
  point: number[];
}

export type Mask = 'landMask' | 'mountainMask' | 'none'

export interface IPCCProjections {
  'EPSG:54030': IPCCProjection;
  'EPSG:3413': IPCCProjection;
  'EPSG:3412': IPCCProjection;
  'EPSG:54030pc': IPCCProjection;
}

export interface IPCCProjection {
  code: keyof IPCCProjections;
  epsg: string;
  proj4: string;
  zoom: number;
  center_lat: number;
  center_long: number;
  label: string;
  extent?: [number, number, number, number];
}

export interface RegionConfiguration {
  [model: string]: {
    [regionSet in RegionSet]: string[];
  };
}

export const DynamicLegendStyleTag = 'dynamic/nearest' as const;
export interface LegendConfiguration {
  styleMin: number;
  styleMax: number;
  styleTickInterval: number;
}