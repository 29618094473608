import React, { Suspense } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from 'store/store';
import { UrlKeeper } from 'utils/initial-url-keeper';

import { Loading } from 'components/loading-map-indicator/Loading';
import { Router } from 'components/router/Router';
import { Credits } from 'components/credits/Credits';

function App() {
  UrlKeeper.setUrl(new URL(window.location.href));
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Loading show />}>
          <Credits />
          <Router />
        </Suspense>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
