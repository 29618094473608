import {
  MapConfigurationModel,
  MapConfigurationVariable,
  MapConfigurationScenario,
  MapConfigurationTempFilter,
  MapConfigurationPeriod,
  ValueType,
  MapConfigurationBaseline
} from 'domain/models/map-configuration.model';
import { DeepNullable } from 'domain/models/shared.types';

export interface ControllerOptionsState {
  dataset: MapConfigurationModel;
  variable: MapConfigurationVariable;
  scenario: MapConfigurationScenario;
  baseline: MapConfigurationBaseline;
  period: MapConfigurationPeriod;
  season: MapConfigurationTempFilter;
  valueType: ValueType;
}
export type NullableControllerOptionsState = DeepNullable<ControllerOptionsState>;


export interface ControllerOptionsData {
  models: MapConfigurationModel[];
  variables: MapConfigurationVariable[];
  scenarios: MapConfigurationScenario[];
  temporalFilters: MapConfigurationTempFilter[];
  periods: MapConfigurationPeriod[];
  magnitudes: ValueType[];
  baselines: MapConfigurationBaseline[];
}

export const initialData: ControllerOptionsData = {
  models: [],
  variables: [],
  scenarios: [],
  temporalFilters: [],
  periods: [],
  magnitudes: [],
  baselines: []
};

export const initialState: NullableControllerOptionsState = {
  dataset: null,
  variable: null,
  scenario: null,
  baseline: null,
  period: null,
  season: null,
  valueType: null
};

export interface SectionsDiff {
  dataset: boolean;
  variable: boolean;
  period: boolean;
  season: boolean;
}