import React from 'react';

import { useTranslation } from 'react-i18next';

import { HatchingType } from 'domain/models/map-configuration.model';

import { Select } from 'components/shared/select/Select';

import { MapIdentifier, setHatching } from 'store/map';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/store';

import './MapControls.scss';

const CHANGE_VALUES: { [k in HatchingType]: string } = {
  DISABLED: 'components:legend.hatching.DISABLED',
  SIMPLE: 'components:legend.hatching.SIMPLE',
  COMPREHENSIVE: 'components:legend.hatching.COMPREHENSIVE',
};

const TREND_VALUES: { [k in HatchingType]?: string } = {
  DISABLED: CHANGE_VALUES.DISABLED,
  SIMPLE: 'components:legend.hatching.SIGNIFICANCE',
};

export const HatchingControl = ({
  cssClass,
  mapIdentifier,
}: {
  cssClass?: string;
  mapIdentifier: MapIdentifier;
}) => {
  const { t } = useTranslation(['map', 'components']);

  const dispatch = useDispatch();

  const hatching = useSelector(
    (store: IStore) => store.map[mapIdentifier].hatching
  );

  const valueType = useSelector(
    (store: IStore) => store.map.primaryMap.selectedOptions.valueType
  );

  const onChange = React.useCallback(
    ({ value }: { value: HatchingType }) => {
      if (value !== hatching) {
        dispatch(setHatching(value, mapIdentifier));
      }
    },
    [dispatch, hatching, mapIdentifier]
  );

  const options = React.useMemo(
    () => (valueType === 'TREND' ? TREND_VALUES : CHANGE_VALUES),
    [valueType]
  );

  React.useEffect(() => {
    if (!options[hatching]) {
      const simple = options.SIMPLE;
      if (simple) {
        onChange({ value: 'SIMPLE' });
      } else {
        onChange({ value: 'DISABLED' });
      }
    }
  }, [options]);

  return (
    <Select
      label={t('map:controls.hatching.label')}
      className={cssClass}
      value={hatching}
      options={Object.entries(options).map(([hatching, label]) => ({
        value: hatching,
        label: t(`${label}.label`),
      }))}
      onChange={onChange}
    />
  );
};
