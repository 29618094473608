import React from 'react';

import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

import { ReactTooltipWrapper } from '../react-tooltip-wrapper/ReactTooltipWrapper';
import { QuestionIcon } from 'components/shared/icons';

import { useIsMobile } from 'hooks/useIsMobile';
import { validateKeyboardEvent } from 'utils/utils';

import './RadioButton.scss';

export const RadioButton = ({
  label,
  name,
  uKey,
  checked,
  disabled = false,
  title = label,
  description,
  onChange,
  className,
}: any) => {
  const isMobile = useIsMobile();

  const tooltipRef = React.useRef<HTMLDivElement>(null);

  return (
    <label
      className={cn('radio', { checked }, { disabled }, className)}
      title={title}
      tabIndex={disabled ? -1 : 0}
      role='listitem'
      onKeyDown={validateKeyboardEvent(onChange)}
    >
      <div
        className='description-icon-wrapper'
        tabIndex={disabled ? -1 : 0}
        ref={tooltipRef}
        data-tooltip={description}
        title=''
        data-tip
        data-for={`${name}_tooltip_${uKey}`}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }}
        onFocus={() =>
          tooltipRef.current && description
            ? ReactTooltip.show(tooltipRef.current as any)
            : null
        }
        onBlur={() => ReactTooltip.hide()}
      >
        {description && (
          <QuestionIcon
            className='description-icon'
            width='15px'
            height='15px'
            fill='white'
          ></QuestionIcon>
        )}
        <ReactTooltipWrapper
          className='tooltip'
          id={`${name}_tooltip_${uKey}`}
          delayHide={isMobile ? 0 : 500}
          effect='solid'
          event={isMobile ? 'click' : undefined}
          offset={{ left: -4 }}
          clickable
        >
          <span
            dangerouslySetInnerHTML={{ __html: description }}
            aria-label={description}
          />
        </ReactTooltipWrapper>
      </div>
      {label}
      <input
        disabled={disabled}
        aria-label={label}
        type='radio'
        className='hidden'
        onChange={onChange}
        checked={checked}
        name={name}
      />
      <span className='label' />
    </label>
  );
};
