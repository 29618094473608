import { ReactTooltipWrapper } from 'components/shared/react-tooltip-wrapper/ReactTooltipWrapper';
import { Routes } from 'core';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { getReviewCode } from 'services/map.service';
import { addAlert } from 'store/core';

import './ChartErrorMessage.scss';

interface ChartErrorMessageProps {
  show: boolean;
}
export const ChartErrorMessage = (props: ChartErrorMessageProps) => {
  const { t } = useTranslation('chart');

  const location = useLocation();

  const dispatch = useDispatch();

  const [code, setCode] = React.useState('');

  React.useEffect(() => {
    if (props.show) {
      getReviewCode().then((c) =>
        setCode(`${window.location.origin}${Routes.Review}/${c}`)
      );
    }
  }, [location, props.show]);

  const copyToClipboard = () => {
    if (!code) {
      return;
    }
    try {
      navigator.clipboard.writeText(code);
      dispatch(
        addAlert({
          message: t('chart:singleErrorBoundary.alert'),
          variant: 'success',
          duration: 4000,
        })
      );
    } catch (err) {
      // Ignore, user should copy url manually
    }
  };

  return props.show ? (
    <div className='charts-error-boundary'>
      <div className='title'>{t('chart:singleErrorBoundary.title')}</div>
      <div className='body'>
        <span
          className='body-message'
          dangerouslySetInnerHTML={{
            __html: t('chart:singleErrorBoundary.body'),
          }}
        />
        <span
          className='code-container'
          onClick={copyToClipboard}
          data-for='copy-to-clipboard'
          data-tip='Click to copy into the clipboard'
        >
          {code}
        </span>
        <ReactTooltipWrapper
          id='copy-to-clipboard'
          effect='solid'
          place='top'
          className='tooltip'
        />
      </div>
    </div>
  ) : null;
};
