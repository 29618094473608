import React from 'react';

import { RadioButton } from '../radio-button/RadioButton';

import './RadioListSelector.scss';

export const RadioListSelector = ({
  title,
  options,
  radioGroup,
  selected,
  className = '',
  ariaLabel = '',
  onValueChange,
  getKey,
}: {
  title: string;
  options: {
    name: string;
    value: any;
    disabled?: boolean;
    title?: string;
    description?: string;
    className?: string;
  }[];
  radioGroup: string;
  selected: any;
  ariaLabel?: string;
  className?: string;
  onValueChange(value: any): void;
  getKey?(opt: { name: string; value: any }): any;
}) => {
  return (
    <div className={`radio-list-selector ${className}`} aria-label={ariaLabel}>
      <span className='radio-list-selector__title'>{title}</span>
      <div className='radio-list-selector__body' role='list'>
        {options.map(
          ({ name, value, disabled, title, description, className }) => (
            <div
              key={(getKey && getKey({ name, value })) || value}
              className={`radio-list-selector__radio ${
                disabled ? 'disabled' : ''
              }`}
            >
              <RadioButton
                title={name}
                label={name}
                uKey={(getKey && getKey({ name, value })) || value}
                disabled={disabled}
                onChange={() => (!disabled ? onValueChange(value) : null)}
                checked={selected === value}
                description={description}
                name={radioGroup}
                className={className}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};
