import { AtlasMode, SimpleMapView } from 'store/map';
import { Projections } from 'core/map.projections';
import { ParamMapPrefix } from './commons';
import { pluck } from 'utils/utils';
import {
  FullParams,
  IPCCUrlParams,
  IPCCUrlPlotParams,
  IPCCUrlParamsType,
  IPCCUrlCIDParams,
} from 'domain/models/url.model';

export class Serializer {
  private _params: IPCCUrlParams | IPCCUrlCIDParams;

  constructor(type: IPCCUrlParamsType) {
    this._params = { type } as IPCCUrlParams;
  }

  setPrimaryMapParams(params: FullParams): this {
    return this._setParamsForMap(params, ParamMapPrefix.Primary);
  }

  setSecondaryMapParams(params: FullParams): this {
    return this._setParamsForMap(params, ParamMapPrefix.Secondary);
  }

  setCommonParams(
    params: SimpleMapView & { proj: string; mode: AtlasMode } = {
      zoom: 3,
      center: [0, 0],
      proj: Projections['EPSG:54030'].epsg,
      mode: AtlasMode.COMPLETE,
    }
  ): this {
    const { center, proj, zoom, mode } = params;

    this._params = {
      ...this._params,
      commons: {
        lat: center[0] | 0,
        lng: center[1] | 0,
        zoom: zoom | 0,
        proj: proj as any,
        mode,
      },
    };
    return this;
  }

  setCidMapParameter(
    params: Partial<
      Pick<
        IPCCUrlCIDParams,
        'mode' | 'selectedIndex' | 'selectedVariable' | 'selectedCountry'
      >
    >
  ): this {
    if (this._params.type === 'CID') {
      this._params = {
        ...this._params,
        ...params,
      };
    }
    return this;
  }

  setPlotState(plot: IPCCUrlPlotParams): this {
    this._params = {
      ...this._params,
      plot,
    };
    return this;
  }

  getParams<T extends IPCCUrlParams | IPCCUrlCIDParams>(): {
    raw: T;
    encoded: string;
  } {
    return {
      raw: this._params as T,
      encoded: btoa(JSON.stringify(this._params)),
    };
  }

  private _setParamsForMap(
    params: FullParams,
    prefix: string = ParamMapPrefix.Primary
  ): this {
    if (this._params.type === 'ATLAS') {
      this._params = {
        ...this._params,
        [prefix === ParamMapPrefix.Primary ? 'primary' : 'secondary']: {
          ...(this._params as IPCCUrlParams)[
            prefix === ParamMapPrefix.Primary ? 'primary' : 'secondary'
          ],
          scenario: params.scenario.code,
          period: params.period.wmsCode,
          season: params.season.code,
          dataset: params.dataset.model.code,
          variable: params.variable.code,
          valueType: params.valueType,
          hatching: params.hatching,
          regionSet: params.regionSet,
          baseline: params.baseline.code,
          regionsSelected: pluck(params.regions, 'id'),
        },
      };
    }
    return this;
  }
}
