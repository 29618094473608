import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addAlert } from 'store/core';
import { useIsMobile } from 'hooks/useIsMobile';

import { Tooltip } from 'components/shared/tooltip/Tooltip';

import './PermalinkTooltip.scss';
import { Routes } from 'core';

export const PermalinkTooltip = ({
  code,
  onClose,
}: {
  code: string;
  onClose(): void;
}) => {
  const { t } = useTranslation('components');

  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const codeParagraph = React.useRef<HTMLParagraphElement>(null);

  const copyToClipboard = () => {
    const showAlert = () =>
      dispatch(
        addAlert({
          message: t('components:permalink.copyToClipboard.alert.message'),
          title: t('components:permalink.copyToClipboard.alert.title'),
          variant: 'success',
          duration: 3000,
        })
      );

    if (codeParagraph.current) {
      if (window.getSelection) {
        const selection = window.getSelection();
        if (selection) {
          const range = document.createRange();
          range.selectNodeContents(codeParagraph.current);
          selection.removeAllRanges();
          selection.addRange(range);
          try {
            document.execCommand('copy');
            showAlert();
          } catch (err) {
            console.error(err, 'Security Error. Cannot copy selection');
          }
        }
      } else {
        console.warn('Could not select text in node: Unsupported browser.');
      }
    }
  };

  return (
    <Tooltip
      className='permalink-tooltip'
      backdrop
      left='calc(50% - 150px)'
      top='calc(50% - 150px)'
      onClose={onClose}
    >
      <div className='container'>
        <p className='title'>
          {t('components:permalink.tooltip.paragraphs.first')}
        </p>
        <p
          className='code-wrapper'
          onClick={copyToClipboard}
          title={t('components:permalink.copyToClipboard.button.title')}
          ref={codeParagraph}
        >
          {`${window.location.origin}${Routes.Review}/${code.trim()}`}
        </p>
      </div>
    </Tooltip>
  );
};
