import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ControllerOptionsState } from 'components/option-selector/options.model';
import {
  areAllRegionsSelected,
  getRegionSetToLoad,
  selectedOptionsToReadableText,
} from 'utils/map.utils';
import { pluck } from 'utils/utils';
import { IStore } from 'store/store';

import './MapCaption.scss';

export const MapCaption = ({
  optionsSelected,
  regionsSelected,
  primary,
  mirroring,
}: {
  optionsSelected: ControllerOptionsState;
  regionsSelected: any[];
  primary: boolean;
  mirroring: boolean;
}) => {
  const { t } = useTranslation(['map', 'config']);

  const geoJson = useSelector((store: IStore) => store.map.commons.geoJson);

  const selectedRegionSetConfiguration = useSelector(
    (store: IStore) => store.map.commons.regionsConfig
  );
  const regionSetSelected = useSelector(
    (store: IStore) => store.map.primaryMap.regionSet
  );

  const mapProjection = useSelector(
    (store: IStore) => store.map.commons.referenceProjection
  );

  const geojson =
    geoJson[getRegionSetToLoad(regionSetSelected, mapProjection.code)];
  let allRegionsSelected = false;
  if (geojson && regionSetSelected !== 'none') {
    allRegionsSelected = areAllRegionsSelected(
      geojson,
      regionsSelected,
      optionsSelected,
      selectedRegionSetConfiguration,
      regionSetSelected
    );
  }

  const mapTitle = selectedOptionsToReadableText(optionsSelected, t, mirroring);
  const regionsTitle = pluck(regionsSelected, 'Acronym') || [];

  if (mapTitle && mapTitle.title) {
    let regions;
    if (!allRegionsSelected) {
      regions =
        regionsTitle.length > 0
          ? `${t('map:controls.caption.regions.title')}: ${regionsTitle
              .map((reg: string) => t('config:regions.' + reg))
              .join(', ')}`
          : '';
    } else {
      regions = '(Global)';
    }
    return (
      <div
        tabIndex={0}
        aria-label={t('map:controls.caption.aria.label', {
          options: mapTitle.title,
          regions: regions,
        })}
        className={`ipcc-map__caption ${mirroring ? 'mirroring' : ''}`}
        id={primary ? 'primary-map-caption' : ''}
      >
        <div
          className='map__caption-wrapper'
          title={`${mapTitle.title} ${regions}`}
        >
          {mapTitle.title}
          {regions !== '' && (
            <>
              <br /> {regions}
            </>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
