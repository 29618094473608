export const WMSNames = {
  period: 'DIM_period',
  season: 'DIM_time_filter',
  baseline: 'DIM_baseline',
  dataset: 'dataset',
  layers: 'layers'
};

export const ParamMapPrefix = {
  Primary: 'm1_',
  Secondary: 'm2_'
};

export const UrlExtras = {
  Lat: 'lat',
  Lng: 'lng',
  Zoom: 'zoom',
  RegionSet: 'reg_set',
  RegionsSelected: 'regions',
  Proj: 'proj',
  PlotState: 'plotState'
};
