import React from 'react';

import cn from 'classnames';

import './ControlsContainer.scss';

export interface ControlsContainerProps {
  containerHeight: number;
  side?: 'right' | 'left';
  orientation?: 'horizontal' | 'vertical';
  cssClass?: string;
}

export const ControlsContainer = (
  props: React.PropsWithChildren<ControlsContainerProps>
) => {
  const className = cn(
    'ipcc-controls__container',
    'ol-overlaycontainer-stopevent',
    props.orientation || 'vertical',
    props.side || 'right',
    { collapsed: props.containerHeight < 640 },
    props.cssClass
  );
  return <div className={className}>{props.children}</div>;
};
