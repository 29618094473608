import React from 'react';

import cn from 'classnames';

import { scaleLinear } from 'd3-scale';

import { useIsMobile } from 'hooks/useIsMobile';
import { mergeRefs, validateKeyboardEvent } from 'utils/utils';
import { isLandscape } from 'utils/device';

import './ExpandableBaseControl.scss';
import { useTranslation } from 'react-i18next';

const noop = () => {};

export interface ExpandableBaseControlProps {
  onClick?: VoidFunction;
  open: boolean;
  cssClass?: string;
  title?: string;
  tooltip?: string;
  icon: React.ReactElement;
  mirroring?: boolean;
}
export const ExpandableBaseControl = React.forwardRef<
  any,
  React.PropsWithChildren<ExpandableBaseControlProps>
>(
  (
    {
      onClick = noop,
      open,
      cssClass = '',
      title = '',
      tooltip = '',
      icon,
      mirroring,
      children,
    }: React.PropsWithChildren<ExpandableBaseControlProps>,
    ref: any
  ) => {
    const { t } = useTranslation('map');

    const isMobile = useIsMobile();

    const container = React.useRef<HTMLDivElement>(null);

    const renderChildControl = (
      child: React.ReactElement<ExpandableChildControlProps>,
      index: number
    ): JSX.Element | null => {
      if (container.current && child !== null && child !== undefined) {
        const { width, height } = container.current.getBoundingClientRect();
        if (open) {
          const childW = 36,
            childH = 36;
          const margin = 10;
          const delay = (childCount - index) * 150;

          let childX: number = 0;
          let childY: number = 0;

          if (isMobile && isLandscape()) {
            const totalWidth = (childW + margin) * childCount;
            const midPoint = totalWidth / 2;
            childY = -(height + margin);

            const scale = scaleLinear()
              .domain([0, childCount - 1])
              .range([midPoint, -midPoint])
              .clamp(true);

            childX = Number(-scale(index)!.toFixed());
          } else if (mirroring) {
            const totalWidth = (childW + margin) * childCount;
            const midPoint = totalWidth / 2;
            childY = height;

            const scale = scaleLinear()
              .domain([0, childCount - 1])
              .range([midPoint, -midPoint]);
            childX = Number(-scale(index)!.toFixed());
          } else {
            childX = -(width - margin) - index * (childW + margin);
          }

          return React.isValidElement(child) ? (
            <InternalExpandableChildControl
              {...(child.props as ExpandableBaseControlProps)}
              top={childY}
              left={childX}
              open={open}
              delay={delay}
            >
              {(child.props as any).children}
            </InternalExpandableChildControl>
          ) : null;
        } else {
          return React.isValidElement(child) ? (
            <InternalExpandableChildControl
              {...(child.props as ExpandableBaseControlProps)}
              top={0}
              left={0}
              delay={100}
              open={open}
            >
              {(child.props as any).children}
            </InternalExpandableChildControl>
          ) : null;
        }
      }
      return null;
    };

    const validChildren: any[] = [];
    React.Children.map(children, (child) => {
      if (child !== null && child !== undefined) {
        validChildren.push(child);
      }
    });
    const childCount = React.Children.count(validChildren);

    return (
      <div
        className={cn(
          'expandable-base-control',
          cssClass,
          open ? 'opened' : 'closed',
          isMobile ? 'mobile' : ''
        )}
        ref={mergeRefs(container, ref)}
      >
        <div
          role='button'
          aria-pressed={open}
          tabIndex={0}
          aria-label={t('map:controls.aria.label', { label: tooltip })}
          className='father-control'
          data-tooltip={tooltip}
          title={title}
          onClick={onClick}
          onKeyDown={validateKeyboardEvent(() => onClick && onClick())}
        >
          {icon}
        </div>
        <div className='expandable-container' role={'group'}>
          {React.Children.map(validChildren, (child: any, index) =>
            React.isValidElement(child)
              ? renderChildControl(child as any, index)
              : null
          )}
        </div>
      </div>
    );
  }
);

export interface ExpandableChildControlProps
  extends Omit<ExpandableBaseControlProps, 'open' | 'icon'> {}
export const ExpandableChildControl = React.forwardRef<
  any,
  React.PropsWithChildren<ExpandableChildControlProps>
>(({}: React.PropsWithChildren<ExpandableChildControlProps>, ref: any) => {
  throw new Error(
    'ExpandableChildControl is only intended to be renderer inside ExpandableBaseControl'
  );
});

interface InternalExpandableChildControlProps
  extends ExpandableChildControlProps {
  top: number;
  left: number;
  delay: number;
  open: boolean;
}
const InternalExpandableChildControl = React.forwardRef<
  any,
  React.PropsWithChildren<InternalExpandableChildControlProps>
>(
  (
    {
      left,
      top,
      delay,
      tooltip,
      title,
      cssClass,
      open,
      onClick,
      children,
    }: React.PropsWithChildren<InternalExpandableChildControlProps>,
    ref: any
  ) => {
    const { t } = useTranslation('map');
    return (
      <div
        role='listitem'
        tabIndex={open ? 0 : -1}
        aria-label={t('map:controls.aria.label', { label: tooltip ?? title })}
        onClick={onClick}
        title={title}
        className={`internal-child-control ${cssClass}`}
        style={{
          transform: `translate(${left}px, ${top}px)`,
          transitionDelay: `${delay}ms`,
        }}
        data-tooltip={tooltip}
      >
        {children}
      </div>
    );
  }
);
