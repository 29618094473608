import { createActionCreator, createReducer } from 'deox';
import View from 'ol/View';

import {
  Projections,
  ProjectionsNames,
  registerProjection,
} from 'core/map.projections';
import { IPCCProjection } from 'domain/models/map.models';
import {
  CIDConfiguration,
  CIDMode,
  CIDVariable,
} from 'domain/models/cid.model';

export interface SimpleMapView {
  center: [number, number];
  zoom: number;
}

registerProjection(ProjectionsNames.EPSG_54030);
// #region - Actions
const SET_CONFIGURATION = '[CID Map] :: Set configuration';
const SET_REFERENCE_PROJECTION = '[CID Map] :: Set reference projection';
const SET_VIEW = '[CID Map] :: Set map view';
const SET_SIMPLE_VIEW = '[CID Map] :: Set map simple view';
const SET_REGIONS_GEOJSON = '[CID Map] :: Set AR6 regions geojson';
const SET_COASTLINE_GEOJSON = '[CID Map] :: Set Coastline geojson';
const SET_MAP_SELECTED_INDEX = '[CID Map] :: Set map selected options';
const SET_MAP_SELECTED_VARIABLE = '[CID Map] :: Set map selected variable';
const SET_SELECTED_COUNTRY = '[CID Map] :: Set selected country';
const SET_MODE = '[CID Map] :: Set CID mode';

export const setConfiguration = createActionCreator(
  SET_CONFIGURATION,
  (resolve) => (config: CIDConfiguration) => resolve(config)
);

export const setReferenceProjection = createActionCreator(
  SET_REFERENCE_PROJECTION,
  (resolve) => (projection: IPCCProjection) => resolve(projection)
);

export const setMapView = createActionCreator(
  SET_VIEW,
  (resolve) => (view: View | SimpleMapView) => resolve(view)
);

export const setSimpleMapView = createActionCreator(
  SET_SIMPLE_VIEW,
  (resolve) => (view: SimpleMapView | View) => resolve(view)
);

export const setAr6RegionsGeojson = createActionCreator(
  SET_REGIONS_GEOJSON,
  (resolve) => (geoJson: object) => resolve(geoJson)
);

export const setCoastlineGeojson = createActionCreator(
  SET_COASTLINE_GEOJSON,
  (resolve) => (geoJson: object) => resolve(geoJson)
);

export const setSelectedIndex = createActionCreator(
  SET_MAP_SELECTED_INDEX,
  (resolve) => (option: string | string[]) => resolve(option)
);

export const setSelectedVariable = createActionCreator(
  SET_MAP_SELECTED_VARIABLE,
  (resolve) => (variable: CIDVariable) => resolve(variable)
);

export const setSelectedCountry = createActionCreator(
  SET_SELECTED_COUNTRY,
  (resolve) => (country: string) => resolve(country)
);

export const setMode = createActionCreator(
  SET_MODE,
  (resolve) => (mode: CIDMode) => resolve(mode)
);

// #endregion

// #region - Store Definition

export interface CIDMapStore {
  commons: {
    config: CIDConfiguration;
    referenceProjection: IPCCProjection;
    simpleView: SimpleMapView;
    view: View;
    ar6RegionsGeojson: any;
    coastlineGeojson: any;
  };
  selectedIndex: string | string[];
  selectedVariable: CIDVariable;
  selectedCountry?: string;
  mode: CIDMode;
}

export const initialState: CIDMapStore = {
  commons: {
    config: null as unknown as any[],
    referenceProjection: Projections['EPSG:54030'],
    simpleView: {
      center: [0, 0],
      zoom: 0,
    },
    ar6RegionsGeojson: null,
    coastlineGeojson: null,
    view: new View({
      zoomFactor: 1.5,
      center: [0, 0],
      zoom: 4,
      minZoom: 1,

      projection: Projections[ProjectionsNames.EPSG_54030].epsg,
    }),
  },
  selectedIndex: ['mean_air_temperature'],
  selectedVariable: 'confidence',
  mode: 'HEX',
};

// #endregion

// #region - Reducer

export const cidMapReducer = createReducer(initialState, (handle) => [
  handle(setConfiguration, (state, { payload }) => ({
    ...state,
    commons: {
      ...state.commons,
      config: payload,
    },
  })),
  handle(setReferenceProjection, (state, { payload }) => {
    try {
      registerProjection(payload.epsg as any);
    } catch (err) {
      registerProjection('EPSG:54030');
    }
    const _mapview = state.commons.view;
    const mapView = new View({
      zoomFactor: 1.5,
      center: _mapview.getCenter(),
      zoom: _mapview.getZoom(),
      projection: payload.epsg,
    });
    return {
      ...state,
      commons: {
        ...state.commons,
        referenceProjection: payload,
        view: mapView,
      },
    };
  }),
  handle(setMapView, (state, { payload }) => {
    let view: View;
    if (payload instanceof View) {
      view = payload;
    } else {
      view = state.commons.view;
      view.setCenter(payload.center);
      view.setZoom(payload.zoom);
    }
    return {
      ...state,
      commons: { ...state.commons, view },
    };
  }),
  handle(setSimpleMapView, (state, { payload }) => {
    let simpleView: SimpleMapView;
    if (payload instanceof View) {
      const [lat, lng] = payload.getCenter() || [0, 0];
      simpleView = {
        center: [lat, lng],
        zoom: payload.getZoom() || 1,
      };
    } else {
      simpleView = payload;
    }
    return { ...state, commons: { ...state.commons, simpleView } };
  }),
  handle(setAr6RegionsGeojson, (state, { payload }) => ({
    ...state,
    commons: {
      ...state.commons,
      ar6RegionsGeojson: payload,
    },
  })),
  handle(setCoastlineGeojson, (state, { payload }) => ({
    ...state,
    commons: {
      ...state.commons,
      coastlineGeojson: payload,
    },
  })),
  handle(setSelectedIndex, (state, { payload }) => ({
    ...state,
    selectedIndex: payload,
  })),
  handle(setSelectedVariable, (state, { payload }) => ({
    ...state,
    selectedVariable: payload,
  })),
  handle(setSelectedCountry, (state, { payload }) => ({
    ...state,
    selectedCountry: payload,
  })),
  handle(setMode, (state, { payload }) => ({
    ...state,
    mode: payload,
  })),
]);

// #endregion
