import React from 'react';

import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { useIsMobile } from 'hooks/useIsMobile';

import { PlusIcon } from 'components/shared/icons';

import './RegionalInformationMobileWarning.scss';

export const RegionalInformationMobileWarning = () => {
  const { t } = useTranslation('components');

  const isMobile = useIsMobile();

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setShow(true), 1000);
  }, []);

  return isMobile ? (
    <CSSTransition
      timeout={300}
      classNames='regional-information-mobile-warning-transition'
      unmountOnExit
      in={show}
    >
      <div className='regional-information-mobile-warning'>
        <PlusIcon className='close-icon' onClick={() => setShow(false)} />
        <span className='content'>
          {t('components:regionalInformationMobileWarning.content')}
        </span>
      </div>
    </CSSTransition>
  ) : null;
};
