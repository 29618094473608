import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Reactour from 'reactour';
import { hideTour } from 'store/core';
import { useIsMobile } from 'hooks/useIsMobile';

import { IStore } from 'store/store';
import { createAtlasTourSteps } from './atlas-tour.steps';

import './Common.scss';

export const AtlasTour = () => {
  const { t } = useTranslation('atlas-tour');

  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const tourRef = React.useRef<Reactour | null>(null);

  const steps = React.useMemo(
    () => createAtlasTourSteps(t, dispatch),
    [t, dispatch]
  );

  const isOpen = useSelector((store: IStore) => store.core.showTour.atlas);

  const onClose = () => {
    dispatch(hideTour('atlas'));
    dispatch(hideTour('charts'));
    (tourRef.current as any)?.gotoStep(0);
  };

  return isMobile ? null : (
    <Reactour
      ref={tourRef}
      rounded={5}
      steps={steps}
      isOpen={isOpen}
      closeWithMask={false}
      onRequestClose={onClose}
      showButtons={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
    />
  );
};
