export class WMSKeeper {
  private static _url: string;

  static setUrl(url: string) {
    WMSKeeper._url = url;
  }

  static getUrl(): string {
    return WMSKeeper._url;
  }
}
