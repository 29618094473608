import React from 'react';

import { CSSTransition } from 'react-transition-group';

import './Loading.scss';

export const Loading = ({
  show,
  cssClass
}: {
  show: boolean;
  cssClass?: string;
}) => {
  return (
    <CSSTransition
      in={show}
      unmountOnExit
      timeout={200}
      classNames='loading-progress-indicator-transition'
    >
      <div className={`ipcc-atlas__loading-indicator ${cssClass || ''}`}>
        <svg className='circular' viewBox='25 25 50 50'>
          <circle
            className='path'
            cx='50'
            cy='50'
            r='20'
            fill='none'
            strokeWidth='4'
            strokeMiterlimit='10'
          />
        </svg>
      </div>
    </CSSTransition>
  );
};
