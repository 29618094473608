import React, {
  useState,
  useRef,
  FunctionComponent,
  HTMLAttributes,
} from 'react';

import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { useIsMobile } from 'hooks/useIsMobile';
import { debounce, validateKeyboardEvent } from 'utils/utils';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import './DropDown.scss';

interface DropDownBoxProps
  extends Omit<
    HTMLAttributes<HTMLDivElement>,
    'onMouseEnter' | 'onMouseLeave'
  > {
  animation: 'dropDown' | 'downUp' | 'fade';
  onDropDownHover?(): void;
  onDropDownLeave?(): void;
  id?: string;
}

export const DropDownBox: FunctionComponent<DropDownBoxProps> = ({
  onDropDownHover,
  onDropDownLeave, // This should be done in order to avoid react warning about unknown property for div
  ...props
}: React.PropsWithChildren<DropDownBoxProps>) => {
  const isMobile = useIsMobile();

  const showHandler = () => {
    setShow(true);
    onDropDownHover && onDropDownHover();
  };

  const hideHandler = () => {
    setShow(false);
    onDropDownLeave && onDropDownLeave();
  };

  React.useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        document.removeEventListener('keydown', handler);
        hideHandler();
      }
    };

    document.addEventListener('keydown', handler, { passive: true });

    return () => {
      document.removeEventListener('keydown', handler);
    };
  });

  /* Used only on mobile env */
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, isMobile ? hideHandler : () => {});
  /* */

  const [show, setShow] = useState(false);

  if (React.Children.count(props.children) !== 2) {
    throw new Error(
      'Two children are expected to render DropDownBox: Header and Body'
    );
  }

  const [Header, Body] = React.Children.toArray(props.children);

  return (
    <div
      {...props}
      ref={containerRef}
      onKeyDown={validateKeyboardEvent(showHandler)}
      // onBlur={hideHandler}

      onMouseEnter={showHandler}
      onMouseLeave={debounce(hideHandler, 100)}
    >
      <div
        role='button'
        onTouchEnd={show ? hideHandler : showHandler}
        onClick={!isMobile ? (show ? hideHandler : showHandler) : undefined}
      >
        {Header}
      </div>
      <CSSTransition
        in={show}
        timeout={100}
        unmountOnExit
        classNames='drop-down-body-transition'
      >
        <div className={cn('dropdown__body', { [props.animation]: true })}>
          {Body}
        </div>
      </CSSTransition>
    </div>
  );
};
