import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'core/i18n';

import './index.scss';
import 'ol/ol.css';

// Issue https://gitlab.predictia.es/ipcc/web/issues/89
// Based on https://stackoverflow.com/a/36948490/6139307
NodeList.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];
HTMLCollection.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

const onNewContentAvailable = (serviceWorker) => {
  const confirmation = window.confirm(
    'A new version of IPCC Atlas is available. Refresh?'
  );
  if (confirmation) {
    serviceWorker.unregister().then(() => {
      window.location.reload();
    });
  }
};

// serviceWorker.register({ onUpdate: onNewContentAvailable });
