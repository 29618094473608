import React, { useCallback } from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'store/store';
import { popAlert, removeAlert } from 'store/core';

import { Header } from '../header/Header';
import { AlertContainer, Alert, AlertProps } from 'components/alert/Alert';

import './CoreLayout.scss';

export function CoreLayout({children}: React.PropsWithChildren<{}>) {
  const alerts = useSelector((store: IStore) => store.core.alerts);

  const dispatch = useDispatch();

  const renderAlert = useCallback(
    (props: AlertProps & { id: number }) => {
      let timeoutId = setTimeout(() => {
        dispatch(popAlert());
        clearTimeout(timeoutId);
      }, props.duration || 2000);
      return (
        <CSSTransition
          key={props.id}
          timeout={300}
          classNames='alert-transition'
          unmountOnExit
          mountOnEnter
        >
          <Alert
            {...props}
            onDismiss={() => {
              dispatch(removeAlert(props.id));
              clearTimeout(timeoutId);
            }}
          />
        </CSSTransition>
      );
    },
    [dispatch]
  );

  return (
    <div className='core-layout'>
      <Header />
      <main role='main' className='ipcc-body'>
        {children}
      </main>
      <AlertContainer>
        <TransitionGroup className='alert-container-transition'>
          {alerts.map(renderAlert)}
        </TransitionGroup>
      </AlertContainer>
    </div>
  );
}
