import React from 'react';

import { useTranslation } from 'react-i18next';

import { BaseControl } from './base/BaseControl';
import { PointIndicatorIcon } from 'components/shared/icons';

export const InfoControl = ({
  onClick,
  cssClass
}: {
  onClick?: () => any;
  cssClass?: string;
}) => {
  const { t } = useTranslation(['map']);
  return (
    <BaseControl
      cssClass={`info-control ${cssClass}`}
      title={t('map:controls.info.title')}
      tooltip={t('map:controls.info.tooltip')}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <PointIndicatorIcon height='25px' width='25px' fill='white'/>
    </BaseControl>
  );
};
