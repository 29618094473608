export const Routes = {
  Landing: '/',
  AppRoot: '/',
  Map: '/regional-information',
  CID: '/regional-synthesis',
  Review: '/permalink',
  RegionalInformationAbout: '/regional-information/about',
  RegionalInformationGuidance: '/regional-information/guidance',
  RegionalSyntesisAbout: '/regional-synthesis/about',
  Documentation: '/documentation',
  License: '/license',
};
