import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SelectAllIcon } from 'components/shared/icons';
import {
  areAllRegionsSelected,
  getAllowedRegions,
  getRegionSetToLoad,
} from 'utils/map.utils';
import { IStore } from 'store/store';
import { setRegionsSelected } from 'store/map';

import './SelectAllControl.scss';

export const SelectAllControl = () => {
  const { t } = useTranslation(['map']);
  const dispatch = useDispatch();

  const selectedRegionSetConfiguration = useSelector(
    (store: IStore) => store.map.commons.regionsConfig
  );

  const regionSetSelected = useSelector(
    (store: IStore) => store.map.primaryMap.regionSet
  );

  const primaryMapSelectedOptions = useSelector(
    (store: IStore) => store.map.primaryMap.selectedOptions
  );

  const geoJsons = useSelector((store: IStore) => store.map.commons.geoJson);

  const mapProjection = useSelector(
    (store: IStore) => store.map.commons.referenceProjection
  );

  const regionsSelected = useSelector(
    (store: IStore) => store.map.primaryMap.regionsSelected
  );

  const activeSelectAllRegionsButton = React.useMemo(() => {
    const geojson =
      geoJsons[getRegionSetToLoad(regionSetSelected, mapProjection.code)];
    if (geojson && geojson.features) {
      return areAllRegionsSelected(
        geojson,
        regionsSelected,
        primaryMapSelectedOptions,
        selectedRegionSetConfiguration,
        regionSetSelected
      );
    } else {
      return false;
    }
  }, [
    regionsSelected,
    regionSetSelected,
    primaryMapSelectedOptions,
    selectedRegionSetConfiguration,
  ]);

  const selectAllRegions = () => {
    if (activeSelectAllRegionsButton) {
      dispatch(setRegionsSelected([], 'primaryMap'));
    } else {
      const allowedRegions = getAllowedRegions(
        geoJsons[getRegionSetToLoad(regionSetSelected, mapProjection.code)],
        primaryMapSelectedOptions,
        selectedRegionSetConfiguration,
        regionSetSelected
      );
      dispatch(setRegionsSelected(allowedRegions, 'primaryMap'));
    }
  };

  const isNone = React.useMemo(
    () => regionSetSelected === 'none',
    [regionSetSelected]
  );

  return (
    <div
      className={`select-all-control ${
        activeSelectAllRegionsButton && !isNone ? 'active' : ''
      } ${isNone ? 'disabled' : ''}`}
      title={
        activeSelectAllRegionsButton
          ? t('map:controls.selectAllRegions.active')
          : t('map:controls.selectAllRegions.inactive')
      }
      onClick={selectAllRegions}
    >
      <SelectAllIcon width={30} height={30} strokeWidth={4} stroke='white' />
    </div>
  );
};
