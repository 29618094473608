import React, { Dispatch } from 'react';

import { TFunction } from 'i18next';
import { ReactourStep } from 'reactour';

import { BasicStep, InitialStep } from './Common';
import { showTour } from 'store/core';

export const createAtlasTourSteps = (
  t: TFunction,
  dispatch: Dispatch<any>
): ReactourStep[] => {
  const initialStep: ReactourStep = {
    content: ({ goTo, inDOM, close, step }) => (
      <InitialStep
        title={t('steps.intro.title')}
        onNextClick={() => goTo(step)}
        onSkipClick={close}
      >
        <div className='atlas-first-step-container'>
          <span>{t('steps.intro.content')}</span>
          <ul>
            <li>{t('steps.intro.list.0')}</li>
            <li>{t('steps.intro.list.1')}</li>
            <li>{t('steps.intro.list.2')}</li>
          </ul>
        </div>
      </InitialStep>
    ),
  };

  const changeSubmitStep: ReactourStep = {
    selector: '.map__container__options',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onNextClick={() => goTo(step)}
          onPrevClick={() => goTo(step - 2)}
        >
          <p>{t('steps.autoSubmit.content')}</p>
        </BasicStep>
      );
    },
  };

  const datasetStep: ReactourStep = {
    selector: '#dataset-option-selector-primaryMap',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onNextClick={() => {
            goTo(step);
          }}
          onPrevClick={() => {
            goTo(step - 2);
          }}
        >
          <p>{t('steps.dataset.content')}</p>
        </BasicStep>
      );
    },
  };

  const variableStep: ReactourStep = {
    selector: '#variable-option-selector-primaryMap',
    content: ({ goTo, inDOM, close, step }) => {
      return (
        <BasicStep
          onNextClick={() => goTo(step)}
          onPrevClick={() => goTo(step - 2)}
        >
          <p>{t('steps.variables.content')}</p>
        </BasicStep>
      );
    },
  };

  const valuePeriodStep: ReactourStep = {
    selector: '#period-option-selector-primaryMap',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onNextClick={() => goTo(step)}
          onPrevClick={() => goTo(step - 2)}
        >
          <p>{t('steps.valuePeriod.content')}</p>
        </BasicStep>
      );
    },
  };

  const seasonStep: ReactourStep = {
    selector: '#season-option-selector-primaryMap',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onNextClick={() => goTo(step)}
          onPrevClick={() => goTo(step - 2)}
        >
          <p>{t('steps.season.content')}</p>
        </BasicStep>
      );
    },
  };

  const mapSelectorsStep: ReactourStep = {
    selector: '.map-selectors-container',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onNextClick={() => goTo(step)}
          onPrevClick={() => goTo(step - 2)}
        >
          <p>{t('steps.mapSelectors.content')}</p>
        </BasicStep>
      );
    },
  };

  const legendStep: ReactourStep = {
    selector: '.ipcc-json-legend-container',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.legend.content')}</p>
        </BasicStep>
      );
    },
  };

  const mapControlsStep: ReactourStep = {
    selector: '.ipcc-controls__container',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.content')}</p>
        </BasicStep>
      );
    },
  };

  const zoomStep: ReactourStep = {
    selector: '.zoom-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.zoom.content')}</p>
        </BasicStep>
      );
    },
  };

  const projectionsStep: ReactourStep = {
    selector: '.projection-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.projections.content')}</p>
        </BasicStep>
      );
    },
  };

  const selectAllStep: ReactourStep = {
    selector: '.select-all-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.selectAllRegions.content')}</p>
        </BasicStep>
      );
    },
  };

  const metadataStep: ReactourStep = {
    selector: '.metadata-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.metadata.content')}</p>
        </BasicStep>
      );
    },
  };

  const shareStep: ReactourStep = {
    selector: '.share-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.share.content')}</p>
        </BasicStep>
      );
    },
  };

  const pointInfoStep: ReactourStep = {
    selector: '.info-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.pointInfo.content')}</p>
        </BasicStep>
      );
    },
  };

  const compareMapsStep: ReactourStep = {
    selector: '.mirror-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.compareMaps.content')}</p>
        </BasicStep>
      );
    },
  };

  const fullPageStep: ReactourStep = {
    selector: '.full-screen-control',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      return (
        <BasicStep
          onPrevClick={() => goTo(step - 2)}
          onNextClick={() => goTo(step)}
        >
          <p>{t('steps.mapControls.fullPage.content')}</p>
        </BasicStep>
      );
    },
  };

  const clickOnRegionStep: ReactourStep = {
    selector: '.map canvas',
    content: ({ goTo, inDOM, close, step }) => {
      if (!inDOM) {
        goTo(step);
      }
      const node = document.querySelector('.map');
      const handler = () => {
        close();
        dispatch(showTour('charts'));
        node?.removeEventListener('click', handler);
      };
      node?.addEventListener('click', handler);

      return (
        <BasicStep onPrevClick={() => goTo(step)}>
          <p>{t('steps.clickOnRegion.content')}</p>
        </BasicStep>
      );
    },
  };

  return [
    initialStep,
    datasetStep,
    variableStep,
    valuePeriodStep,
    seasonStep,
    changeSubmitStep,
    mapSelectorsStep,
    legendStep,
    mapControlsStep,
    zoomStep,
    projectionsStep,
    selectAllStep,
    metadataStep,
    shareStep,
    pointInfoStep,
    compareMapsStep,
    fullPageStep,
    clickOnRegionStep,
  ];
};
