import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { groupBy, getValueAt, debounce } from 'utils/utils';
import { AtlasMode, setLegendConfiguration } from 'store/map';
import { IStore } from 'store/store';
import {
  getNextData,
  getSectionsDiff,
  shouldResetLegendConfig,
  sort as sortByWeight,
} from './option.utils';
import {
  ControllerOptionsData,
  initialState,
  initialData,
  NullableControllerOptionsState,
  SectionsDiff,
  ControllerOptionsState,
} from './options.model';

import { RadioListSelector } from 'components/shared/list-selector/RadioListSelector';
import { DateSelector } from 'components/date-selector/DateSelector';
import { OptionSelector } from './OptionSelector';
import {
  MapConfigurationVariable,
  MapConfigurationModel,
  ValueType,
  MapConfigurationScenario,
  MapConfigurationPeriod,
  MapConfigurationTempFilter,
} from 'domain/models/map-configuration.model';
import {
  GlobeIcon,
  TemperatureIcon,
  ChartLineIcon2,
  CalendarIcon,
} from 'components/shared/icons';

import './Options.scss';

export const OptionController = ({
  onOptionsChange,
  selectedOptions = initialState,
  config,
  mapIdentifier,
}: any) => {
  const { t } = useTranslation(['components', 'config']);

  const dispatch = useDispatch();

  const mode = useSelector((store: IStore) => store.map.commons.mode);

  const [data, setData] = useState<ControllerOptionsData>(initialData);

  const update = (
    state: NullableControllerOptionsState,
    changedOption?: keyof NullableControllerOptionsState
  ) => {
    const { nextState, nextData } = getNextData(state, config);
    const _diff = getSectionsDiff(state, nextState, changedOption);
    const resetLegendConfig = shouldResetLegendConfig(changedOption);
    setData(nextData);
    onOptionsChange(nextState);
    if (resetLegendConfig) {
      dispatch(setLegendConfiguration(undefined, mapIdentifier));
    }
    debounce(() => makeBlink(_diff), 200)();
  };

  useEffect(() => {
    update(selectedOptions || initialState);
  }, [config]);

  const makeBlink = (diff: SectionsDiff) => {
    const changedElements = Object.entries(diff)
      .filter(([k, v]) => !!v)
      .map(([k]) => `${k}-option-selector-${mapIdentifier}`);

    const changeColors = (bg: string, color: string) => {
      for (let id of changedElements) {
        const el = document.getElementById(id);
        if (el) {
          el.style.backgroundColor = bg;
          el.style.color = color;
        }
      }
    };

    changeColors('#5492cdAA', 'white');

    debounce(() => changeColors('white', '#5492cd'), 300)();
  };

  const onOptionChange = (
    optionName: keyof NullableControllerOptionsState,
    value: any
  ): void => {
    update({ ...selectedOptions, [optionName]: value }, optionName);
  };

  const buildDatasetBody = () => {
    const groupedModels = groupBy<MapConfigurationModel>(data.models, [
      'model',
      'family',
    ]);

    const sortModelsFn = (
      m1: MapConfigurationModel,
      m2: MapConfigurationModel
    ): number => {
      if (
        m1.model.weight &&
        !isNaN(m1.model.weight) &&
        m2.model.weight &&
        !isNaN(m2.model.weight)
      ) {
        return Number(m1.model.weight) - Number(m2.model.weight);
      } else if (m1.model.weight) {
        return -1;
      } else if (m2.model.weight) {
        return 1;
      } else {
        return t(`config:dataset.${m1.model.name}`).localeCompare(
          t(`config:dataset.${m2.model.name}`)
        );
      }
    };

    const sortInfo: any = {
      projections: 10,
      'historical-projections': 20,
      historical: 30,
      paleoclimate: 40,
    };

    return (
      <div className='options__body'>
        {Object.entries(groupedModels)
          .sort(
            (entry1, entry2) =>
              sortInfo[entry1[0].toLocaleLowerCase()] -
              sortInfo[entry2[0].toLocaleLowerCase()]
          )
          .map(([K, mapModel]: [string, MapConfigurationModel[]]) => (
            <div key={K} className='options__body__list-container'>
              <RadioListSelector
                ariaLabel={t(
                  'components:optionSelector.dataset.' +
                    K.toLowerCase() +
                    '.aria.label'
                )}
                title={t(
                  'components:optionSelector.dataset.' +
                    K.toLowerCase() +
                    '.title'
                )}
                selected={selectedOptions.dataset}
                radioGroup='dataset'
                options={mapModel
                  .sort(sortModelsFn)
                  .map((model: MapConfigurationModel) => ({
                    name: t('config:dataset.' + model.model.name),
                    value: model,
                  }))}
                onValueChange={(value) => onOptionChange('dataset', value)}
                getKey={(opt) => opt.value.model.code}
              />
            </div>
          ))}
      </div>
    );
  };

  const buildVariableBody = () => {
    const groupedVariables = groupBy<MapConfigurationVariable>(
      data.variables,
      'variableType'
    );

    // @see https://gitlab.predictia.es/ipcc/data-preparation-scripts/-/issues/116#note_7883
    const isDisabled = (variable: MapConfigurationVariable) => {
      const datasetVars = selectedOptions.dataset.variables;

      return !(datasetVars ?? []).includes(variable.code);
    };

    const { atmosphere, ocean, drivers } = groupedVariables;

    const isHighlightVariable = (varCode: string): boolean => {
      const variable = data.variables.find((v) => varCode === v.code);
      if (variable && variable.link === 'highlight') {
        return true;
      }
      return false;
    };

    const VariableGroup = ({
      group,
      variables,
    }: {
      group: string;
      variables: MapConfigurationVariable[];
    }) => (
      <div className='options__body__list-container'>
        <RadioListSelector
          title={t('components:optionSelector.variable.' + group + '.title')}
          selected={selectedOptions.variable}
          radioGroup='variable'
          options={sortByWeight(variables).map(
            (variable: MapConfigurationVariable) => ({
              name: t('config:variable.' + variable.code),
              value: variable,
              description: t(`config:variableDescription.${variable.code}`, {
                defaultValue: null,
              }),
              disabled: isDisabled(variable),
              className: isHighlightVariable(variable.code) ? 'highlight' : '',
            })
          )}
          onValueChange={(value) => onOptionChange('variable', value)}
          getKey={(opt) => opt.value.code}
        />
      </div>
    );

    return (
      <div className='options__body'>
        <VariableGroup group='atmosphere' variables={atmosphere} />
        <div className='variable-group-column'>
          <VariableGroup group='ocean' variables={ocean} />
          <VariableGroup group='drivers' variables={drivers} />
        </div>
      </div>
    );
  };

  const buildScenarioBody = () => {
    const getUnit = (valueType: ValueType) => {
      return (selectedOptions.variable?.units ?? [])[valueType];
    };

    const isHistorical =
      (selectedOptions as ControllerOptionsState).dataset?.model.family ===
      'Historical-Projections';

    return (
      <div className='options__body value-period'>
        <div key='valueTypes' className='options__body__list-container'>
          <RadioListSelector
            className='magnitude-radio-list'
            title={
              t('components:optionSelector.variable.magnitude.title') + ':'
            }
            selected={selectedOptions.valueType}
            radioGroup='valueTypes'
            options={data.magnitudes
              .sort((m1, m2) => m1.localeCompare(m2))
              .map((valType: ValueType) => ({
                name: `${t(
                  'config:magnitude.' + valType.toLowerCase()
                )} (${getUnit(valType)})`,
                value: valType,
              }))}
            onValueChange={(value) => onOptionChange('valueType', value)}
          />
        </div>
        <div className='value-period-row'>
          <div className='options__body__list-container' key='Periods'>
            <RadioListSelector
              title={
                mode === AtlasMode.COMPLETE
                  ? t('components:optionSelector.scenario.period.title')
                  : ''
              }
              selected={selectedOptions.period}
              radioGroup='period'
              options={data.periods.map((period: MapConfigurationPeriod) => ({
                name: t(
                  `config:period.${period.code.toLowerCase()}.${
                    isHistorical ? 'caption' : 'label'
                  }`
                ),
                value: period,
              }))}
              onValueChange={(value) => onOptionChange('period', value)}
              getKey={(opt) => opt.value.code}
            />
          </div>

          {mode === AtlasMode.COMPLETE && (
            <div className='options__body__list-container' key='Scenarios'>
              <RadioListSelector
                title={t('components:optionSelector.scenario.scenario.title')}
                selected={selectedOptions.scenario}
                radioGroup='scenario'
                options={data.scenarios.map(
                  (scen: MapConfigurationScenario) => ({
                    name: t('config:scenario.' + scen.code.toLowerCase()),
                    value: scen,
                    disabled: scen.disabled,
                    title: scen.disabled
                      ? t(
                          'components:optionSelector.scenario.scenario.disabled.notSupportedMag'
                        )
                      : undefined,
                  })
                )}
                onValueChange={(value) => onOptionChange('scenario', value)}
                getKey={(opt) => opt.value.code}
              />
            </div>
          )}

          {!['VALUE', 'TREND'].includes(selectedOptions.valueType) &&
            mode === AtlasMode.COMPLETE && (
              <div className='options__body__list-container' key='Baselines'>
                <RadioListSelector
                  title={t('components:optionSelector.scenario.baseline.title')}
                  selected={selectedOptions.baseline}
                  radioGroup='baseline'
                  options={data.baselines.map((bl) => ({
                    name: t(`config:baseline.${bl.code.toLowerCase()}.label`),
                    value: bl,
                  }))}
                  onValueChange={(value) => onOptionChange('baseline', value)}
                  getKey={(opt) => opt.value.code}
                />
              </div>
            )}
        </div>
      </div>
    );
  };

  const buildSeasonBody = () => {
    let customDateSelected = false;

    const scroll = debounce(() => {
      const dateSelector = document.getElementById(
        'date-selector'
      ) as HTMLDivElement;
      const offsetTop = dateSelector?.offsetTop;
      if (offsetTop) {
        const container = (
          document.getElementById('date-container') as HTMLDivElement
        ).parentElement?.parentElement;
        if (container) {
          container.scrollTop = offsetTop;
        }
      }
    }, 100);

    const intOnOptionChange = (value: MapConfigurationTempFilter) => {
      if (
        selectedOptions.season.code !== value.code &&
        value.code === 'custom'
      ) {
        customDateSelected = true;
        onOptionChange('season', {
          code: 'JanFeb',
          startDate: 1,
          length: 1,
          filterType: 'ALL',
        });
        scroll();
      } else {
        onOptionChange('season', value);
        if (value.length !== 1) {
          customDateSelected = false;
        }
      }
    };

    const customOption = {
      name: t('config:season.custom'),
      value: { code: 'custom', filterType: 'ALL', startMonth: 1, length: 1 },
    };

    const seasonLiteral = (code: string) => {
      if (code !== 'custom' && code !== 'year') {
        const firstMonth = code.substring(0, 3);
        const lastMonth = code.substring(3, 7);
        let literal = t('config:season.' + firstMonth);
        if (lastMonth) {
          literal +=
            ' ' + t('config:season.to') + ' ' + t('config:season.' + lastMonth);
        }
        return literal;
      } else {
        return t('config:season.' + code);
      }
    };

    let options = data.temporalFilters.map(
      (fs: MapConfigurationTempFilter) => ({
        name: seasonLiteral(fs.code),
        value: fs,
      })
    );

    const found = options.find(
      (tf: any) =>
        selectedOptions.season && selectedOptions.season.code === tf.value.code
    );

    if (
      selectedOptions.variable &&
      selectedOptions.variable.temporalFilter.includes('ALL')
    ) {
      options = options.concat(customOption);
    }

    let startMonth, endMonth;
    if ((!found || customDateSelected) && selectedOptions.season) {
      const { code } = selectedOptions.season;
      startMonth = code.substring(0, 3);
      endMonth = code.substring(3, 7) || startMonth;
      customDateSelected = true;
      scroll();
    }

    return (
      <div className='options__body' id='date-container'>
        <div className='options__body__list-container season' key='season'>
          <RadioListSelector
            title={t('components:optionSelector.season.title')}
            selected={
              !found || customDateSelected
                ? customOption.value
                : selectedOptions.season
            }
            radioGroup='season'
            options={options}
            onValueChange={intOnOptionChange}
            getKey={(opt) => opt.value.code}
          />
          {customDateSelected ? (
            <DateSelector
              startMonth={startMonth}
              endMonth={endMonth}
              onChange={(val) => intOnOptionChange(val)}
              temporalFilters={config.temporalFilters}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const isObservations = React.useMemo(
    () =>
      (selectedOptions as ControllerOptionsState).dataset?.model.family ===
      'Historical',
    [selectedOptions]
  );

  return (
    <>
      {mode === AtlasMode.COMPLETE && (
        <OptionSelector
          id={`dataset-option-selector-${mapIdentifier}`}
          icon={<GlobeIcon width='25px' height='25px' />}
          title={t('components:optionSelector.dataset.title')}
          subtitle={getValueAt(selectedOptions.dataset as any, [
            'model',
            'name',
          ])}
          body={buildDatasetBody()}
        />
      )}
      <OptionSelector
        id={`variable-option-selector-${mapIdentifier}`}
        icon={<TemperatureIcon width='25px' height='25px' />}
        title={t('components:optionSelector.variable.title')}
        subtitle={getValueAt(selectedOptions.variable as any, 'name')}
        body={buildVariableBody()}
        className='variable-selector'
      />
      <OptionSelector
        id={`period-option-selector-${mapIdentifier}`}
        icon={<ChartLineIcon2 width='25px' height='25px' />}
        title={
          isObservations
            ? t('components:optionSelector.scenario.altTitle')
            : t('components:optionSelector.scenario.title')
        }
        subtitle={`${getValueAt(
          selectedOptions.scenario as any,
          'name'
        )}: ${getValueAt(selectedOptions.period as any, 'name')}`}
        body={buildScenarioBody()}
      />
      <OptionSelector
        id={`season-option-selector-${mapIdentifier}`}
        icon={<CalendarIcon width='25px' height='25px' />}
        title={t('components:optionSelector.season.title')}
        subtitle={getValueAt(selectedOptions.season as any, 'code')}
        body={buildSeasonBody()}
        last
      />
    </>
  );
};
