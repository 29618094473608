import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { LegendConfiguration } from 'domain/models/map.models';
import { MapIdentifier, setLegendConfiguration } from 'store/map';
import { IStore } from 'store/store';
import { ValueType } from 'domain/models/map-configuration.model';

import { Tooltip } from 'components/shared/tooltip/Tooltip';

import './LegendEditTooltip.scss';

export const LegendEditTooltip = ({
  mapIdentifier,
  show,
  tooltipConfig,
  valueType,
  onClose,
}: {
  mapIdentifier: MapIdentifier;
  show: boolean;
  tooltipConfig?: LegendConfiguration;
  valueType: ValueType;
  onClose: VoidFunction;
}) => {
  const { t } = useTranslation('components');
  const dispatch = useDispatch();

  const isChange = ['ANOMALY', 'RELATIVE_ANOMALY'].includes(valueType);

  const firstInputRef = React.useRef<HTMLInputElement>(null);

  const config = useSelector(
    (store: IStore) => store.map[mapIdentifier].legendConfiguration
  );

  const [formData, setFormData] = React.useState<LegendConfiguration>(
    config ||
      tooltipConfig || { styleMax: 10, styleMin: 0, styleTickInterval: 1 }
  );

  React.useEffect(() => {
    setFormData(
      config ||
        tooltipConfig || { styleMax: 10, styleMin: 0, styleTickInterval: 1 }
    );
  }, [config, tooltipConfig]);

  React.useEffect(() => {
    if (show && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [show]);

  const onChange = <K extends keyof LegendConfiguration>(
    key: K,
    value: number | null
  ) => {
    let newData: LegendConfiguration;
    if (value === null) {
      newData = {
        ...formData,
        [key]: '',
      };

      setFormData(newData);
      return;
    }
    if (isChange && key !== 'styleTickInterval') {
      let styleMin: number, styleMax: number;
      if (key === 'styleMin') {
        styleMin = value;
        styleMax = styleMin < 0 ? Math.abs(styleMin) : 0;
      } else {
        styleMax = value;
        styleMin = styleMax > 0 ? -styleMax : 0;
      }
      newData = {
        ...formData,
        styleMax,
        styleMin,
      };
    } else {
      newData = {
        ...formData,
        [key]: value,
      };
    }

    setFormData(newData);
  };

  const onConfirm = () => {
    dispatch(setLegendConfiguration(formData, mapIdentifier));
    intOnClose();
  };

  const reset = () => {
    dispatch(setLegendConfiguration(undefined, mapIdentifier));
  };

  const intOnClose = () => {
    setFormData(
      config ||
        tooltipConfig || { styleMax: 10, styleMin: 0, styleTickInterval: 1 }
    );
    onClose();
  };

  return (
    <CSSTransition
      in={show}
      unmountOnExit
      classNames='legend-config-tooltip-transition'
      timeout={300}
    >
      <Tooltip
        onClose={intOnClose}
        className='legend-edit-tooltip'
        left='50px'
        top='-110px'
      >
        <div className='legend-config-description'>
          {t('components:legendTooltip.description')}
        </div>

        <div className='form'>
          <label className='label'>
            {t('components:legendTooltip.form.maxValue.label')}:
            <input
              ref={firstInputRef}
              aria-label={t(
                'components:legendTooltip.form.maxValue.aria.label'
              )}
              tabIndex={0}
              className='input'
              type='number'
              name='maxValue'
              step={0.1}
              onChange={({ target }) =>
                onChange('styleMax', target.value ? Number(target.value) : null)
              }
              value={formData.styleMax}
              min={isChange ? 0 : formData.styleMin}
              max={1000}
            />
          </label>
          <label className='label'>
            {t('components:legendTooltip.form.minValue.label')}:
            <input
              className='input'
              type='number'
              tabIndex={0}
              aria-label={t(
                'components:legendTooltip.form.minValue.aria.label'
              )}
              name='minValue'
              step={0.1}
              onChange={({ target }) =>
                onChange('styleMin', target.value ? Number(target.value) : null)
              }
              value={formData.styleMin}
              max={isChange ? 0 : formData.styleMax}
              min={-1000}
            />
          </label>
          <label className='label'>
            {t('components:legendTooltip.form.tickValue.label')}:
            <input
              className='input'
              type='number'
              tabIndex={0}
              aria-label={t(
                'components:legendTooltip.form.tickValue.aria.label'
              )}
              name='tickValue'
              step={0.1}
              min={0.1}
              onChange={({ target }) =>
                onChange(
                  'styleTickInterval',
                  target.value ? Number(target.value) : null
                )
              }
              value={formData.styleTickInterval}
            />
          </label>
        </div>
        <div className='button-row'>
          <button
            tabIndex={0}
            className='button confirm'
            aria-label={t(
              'components:legendTooltip.form.button.reset.aria.label'
            )}
            onClick={reset}
          >
            {t('components:legendTooltip.form.button.reset.label')}
          </button>
          <button
            tabIndex={0}
            className='button cancel'
            aria-label={t(
              'components:legendTooltip.form.button.cancel.aria.label'
            )}
            onClick={intOnClose}
          >
            {t('components:legendTooltip.form.button.cancel.label')}
          </button>
          <button
            tabIndex={0}
            className='button confirm'
            disabled={
              Number.isNaN(formData.styleMax) ||
              Number.isNaN(formData.styleMin) ||
              Number.isNaN(formData.styleTickInterval)
            }
            aria-label={t(
              'components:legendTooltip.form.button.confirm.aria.label'
            )}
            onClick={onConfirm}
          >
            {t('components:legendTooltip.form.button.confirm.label')}
          </button>
        </div>
      </Tooltip>
    </CSSTransition>
  );
};
