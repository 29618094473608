import { createActionCreator, createReducer } from 'deox';
import View from 'ol/View';
import { get as getProjection } from 'ol/proj';

import {
  AvailableGeojson,
  HatchingType,
  MapConfiguration,
  RegionSet,
} from 'domain/models/map-configuration.model';
import {
  Projections,
  ProjectionsNames,
  registerProjection,
} from 'core/map.projections';
import { Nullable } from 'domain/models/shared.types';
import {
  ControllerOptionsState,
  NullableControllerOptionsState,
} from 'components/option-selector/options.model';
import { initialState as selectedOptionsInitialState } from 'components/option-selector/options.model';
import {
  IPCCProjection,
  IPCCProjections,
  LegendConfiguration,
  RegionConfiguration,
} from 'domain/models/map.models';

const getFullProjection = (code: keyof IPCCProjections) => {
  const proj = getProjection(code);
  const ownProj = Projections[code];
  if (proj) {
    proj.setExtent(
      ownProj.extent || [
        -18019909.21177587, -9009954.605703328, 18019909.21177587,
        9009954.605703328,
      ]
    );
    proj.setWorldExtent([-179, -89.99, 179, 89.99]);
    return proj;
  }
  return code;
};

registerProjection(ProjectionsNames.EPSG_54030);
const defaulProj = getFullProjection(ProjectionsNames.EPSG_54030);

export enum AtlasMode {
  COMPLETE = 'complete_atlas',
  SIMPLE = 'simple_atlas',
}

export type MapIdentifier = keyof Omit<MapStore, 'commons'>;
export interface SimpleMapView {
  center: [number, number];
  zoom: number;
}

export type RegionSetAsGeoJson = {
  [K in AvailableGeojson]: any;
};

// #region - Actions

type aux = { [K: string]: any };

const SET_CONFIGURATION = '[Map] :: Set configuration';
const SET_COASTLINE_GEOJSON = '[Map] :: Set Coastline GeoJSON';
const SET_REFERENCE_PROJECTION = '[Map] :: Set reference projection';
const SET_POSITION = '[Map] :: Set position';
const SET_VIEW = '[Map] :: Set map view';
const SET_SIMPLE_VIEW = '[Map] :: Set map simple view';
const SET_REGIONS_SELECTED = '[Map] :: Set regions selected';
const SELECT_REGION = '[Map] :: Select region';
const SET_MAP_SELECTED_OPTIONS = '[Map] :: Set map selected options';
const SET_REGION_SET = '[Map] :: Set region set';
const SET_GEOJSON = '[Map] :: Set GeoJSON';
const SET_POINT_INFO = '[Map] :: Set Point Info';
const SET_REGIONS_CONFIGURATION = '[Map] :: Set regions configuration';
const SET_HATCHING = '[Map] :: Set hatching';
const SET_LEGEND_CONFIGURATION = '[Map] :: Set legend configuration';
const SET_ATLAS_MODE = '[Map] :: Set atlas mode';

export const setConfiguration = createActionCreator(
  SET_CONFIGURATION,
  (resolve) => (config: MapConfiguration) => resolve(config)
);

export const setCoastlineGeoJSON = createActionCreator(
  SET_COASTLINE_GEOJSON,
  (resolve) => (coastlineGeoJSON: aux) => resolve(coastlineGeoJSON)
);

export const setReferenceProjection = createActionCreator(
  SET_REFERENCE_PROJECTION,
  (resolve) => (projection: IPCCProjection) => resolve(projection)
);

export const setPosition = createActionCreator(
  SET_POSITION,
  (resolve) => (position: aux) => resolve(position)
);

export const setMapView = createActionCreator(
  SET_VIEW,
  (resolve) => (view: View | SimpleMapView) => resolve(view)
);

export const setSimpleMapView = createActionCreator(
  SET_SIMPLE_VIEW,
  (resolve) => (view: SimpleMapView | View) => resolve(view)
);

export const setRegionsSelected = createActionCreator(
  SET_REGIONS_SELECTED,
  (resolve) => (regions: aux[], mapIdentifier: MapIdentifier) =>
    resolve({ regions, mapIdentifier })
);

export const selectRegion = createActionCreator(
  SELECT_REGION,
  (resolve) => (region: aux, mapIdentifier?: MapIdentifier) =>
    resolve({ region, mapIdentifier })
);

export const setSelectedOptions = createActionCreator(
  SET_MAP_SELECTED_OPTIONS,
  (resolve) =>
    (options: ControllerOptionsState, mapIdentifier?: MapIdentifier) =>
      resolve({ options, mapIdentifier })
);

export const setRegionSet = createActionCreator(
  SET_REGION_SET,
  (resolve) => (regionSet: RegionSet, mapIdentifier: MapIdentifier) =>
    resolve({ regionSet, mapIdentifier })
);

export const setGeoJson = createActionCreator(
  SET_GEOJSON,
  (resolve) => (geoJson: any, regionSet: AvailableGeojson) =>
    resolve({ geoJson, regionSet })
);

export const setPointInfo = createActionCreator(
  SET_POINT_INFO,
  (resolve) => (featureInfo: any, mapIdentifier: MapIdentifier) =>
    resolve({ featureInfo, mapIdentifier })
);

export const setRegionsConfiguration = createActionCreator(
  SET_REGIONS_CONFIGURATION,
  (resolve) => (config: RegionConfiguration) => resolve({ config })
);

export const setHatching = createActionCreator(
  SET_HATCHING,
  (resolve) => (hatching: HatchingType, mapIdentifier: MapIdentifier) =>
    resolve({ hatching, mapIdentifier })
);

export const setLegendConfiguration = createActionCreator(
  SET_LEGEND_CONFIGURATION,
  (resolve) =>
    (config: LegendConfiguration | undefined, mapIdentifier: MapIdentifier) =>
      resolve({ config, mapIdentifier })
);

export const setAtlasMode = createActionCreator(
  SET_ATLAS_MODE,
  (resolve) => (mode: AtlasMode) => resolve(mode)
);

// #endregion

// #region - State

export interface SingleMapStore {
  selectedOptions: NullableControllerOptionsState;
  regionSet: RegionSet;
  regionsSelected: any[];
  featureInfo: any | null;
  hatching: HatchingType;
  legendConfiguration?: LegendConfiguration;
}

export interface MapStore {
  commons: {
    config: Nullable<MapConfiguration>;
    coastlineGeoJson: Nullable<aux>;
    position: Nullable<aux>;
    referenceProjection: IPCCProjection;
    view: View;
    simpleView: SimpleMapView;
    geoJson: RegionSetAsGeoJson;
    regionsConfig: Nullable<RegionConfiguration>;
    mode?: AtlasMode;
  };
  primaryMap: SingleMapStore;
  secondaryMap: SingleMapStore;
}

const initialState: MapStore = {
  commons: {
    coastlineGeoJson: null,
    config: null,
    position: null,
    referenceProjection: Projections[ProjectionsNames.EPSG_54030],
    view: new View({
      zoomFactor: 1.5,
      center: [0, 0],
      zoom: 4,
      minZoom: 3,
      maxZoom: 7.5,
      projection: defaulProj,
    }),
    simpleView: {
      center: [0, 0],
      zoom: 2,
    },
    geoJson: {
      ar6pacific: null,
      ar6: null,
      biome: null,
      biomepacific: null,
      monsoon: null,
      monsoonpacific: null,
      continent: null,
      continentpacific: null,
      riverbasin: null,
      riverbasinpacific: null,
      smallisland: null,
      smallislandpacific: null,
      none: null,
    },
    regionsConfig: null,
    mode: undefined,
  },
  primaryMap: {
    selectedOptions: selectedOptionsInitialState,
    regionSet: 'ar6',
    regionsSelected: [],
    featureInfo: null,
    hatching: 'DISABLED',
  },
  secondaryMap: {
    selectedOptions: selectedOptionsInitialState,
    regionSet: 'ar6',
    regionsSelected: [],
    featureInfo: null,
    hatching: 'DISABLED',
  },
};

// #endregion

// #region - Reducers

export const mapReducer = createReducer(initialState, (handle) => [
  handle(setConfiguration, (state, { payload }) => ({
    ...state,
    commons: { ...state.commons, config: payload },
  })),

  handle(setCoastlineGeoJSON, (state, { payload }) => ({
    ...state,
    commons: { ...state.commons, coastlineGeoJson: payload },
  })),

  handle(setPosition, (state, { payload }) => ({
    ...state,
    commons: { ...state.commons, position: payload },
  })),

  handle(setReferenceProjection, (state, { payload }) => {
    try {
      registerProjection(payload.code as any);
    } catch (err) {
      registerProjection('EPSG:54030');
    }
    const _mapview = state.commons.view;
    const mapView = new View({
      zoomFactor: 1.5,
      center: _mapview.getCenter(),
      zoom: _mapview.getZoom(),
      projection: getFullProjection(payload.code),
      maxZoom: 7.5,
      minZoom: 3,
    });
    return {
      ...state,
      commons: {
        ...state.commons,
        referenceProjection: payload,
        view: mapView,
      },
    };
  }),

  handle(setMapView, (state, { payload }) => {
    let view: View;
    if (payload instanceof View) {
      view = payload;
    } else {
      view = state.commons.view;
      view.setCenter(payload.center);
      view.setZoom(payload.zoom);
    }
    return {
      ...state,
      commons: { ...state.commons, view },
    };
  }),

  handle(setSimpleMapView, (state, { payload }) => {
    let simpleView: SimpleMapView;
    if (payload instanceof View) {
      const [lat, lng] = payload.getCenter() || [0, 0];
      simpleView = {
        center: [lat, lng],
        zoom: payload.getZoom() || 1,
      };
    } else {
      simpleView = payload;
    }
    return { ...state, commons: { ...state.commons, simpleView } };
  }),

  handle(setRegionsSelected, (state, { payload }) => ({
    ...state,
    [payload.mapIdentifier || 'primaryMap']: {
      ...state[payload.mapIdentifier || 'primaryMap'],
      regionsSelected: payload.regions,
    },
  })),

  handle(selectRegion, (state, { payload }) => {
    const map = payload.mapIdentifier
      ? state[payload.mapIdentifier]
      : state.primaryMap;
    const found = map.regionsSelected.find(
      (r: aux) => r.code === payload.region.code
    );
    const newRegions = found
      ? map.regionsSelected.filter((r: aux) => r.code === payload.region.code)
      : map.regionsSelected.concat(payload);

    return {
      ...state,
      [payload.mapIdentifier || 'primaryMap']: {
        ...state[payload.mapIdentifier || 'primaryMap'],
        regionsSelected: newRegions,
      },
    };
  }),

  handle(setSelectedOptions, (state, { payload }) => {
    return {
      ...state,
      [payload.mapIdentifier || 'primaryMap']: {
        ...state[payload.mapIdentifier || 'primaryMap'],
        selectedOptions: payload.options,
      },
    };
  }),

  handle(setRegionSet, (state, { payload }) => {
    return {
      ...state,
      [payload.mapIdentifier || 'primaryMap']: {
        ...state[payload.mapIdentifier || 'primaryMap'],
        regionSet: payload.regionSet,
      },
    };
  }),

  handle(setGeoJson, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        geoJson: {
          ...state.commons.geoJson,
          [payload.regionSet]: payload.geoJson || {},
        },
      },
    };
  }),

  handle(setPointInfo, (state, { payload }) => {
    return {
      ...state,
      [payload.mapIdentifier || 'primaryMap']: {
        ...state[payload.mapIdentifier || 'primaryMap'],
        featureInfo: payload.featureInfo,
      },
    };
  }),

  handle(setRegionsConfiguration, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        regionsConfig: payload.config,
      },
    };
  }),
  handle(setHatching, (state, { payload }) => {
    return {
      ...state,
      [payload.mapIdentifier || 'primaryMap']: {
        ...state[payload.mapIdentifier || 'primaryMap'],
        hatching: payload.hatching,
      },
    };
  }),
  handle(setLegendConfiguration, (state, { payload }) => {
    const mapId = payload.mapIdentifier || 'primaryMap';

    return {
      ...state,
      [mapId]: {
        ...state[mapId],
        legendConfiguration: payload.config,
      },
    };
  }),
  handle(setAtlasMode, (state, { payload }) => {
    return {
      ...state,
      commons: {
        ...state.commons,
        mode: payload,
      },
    };
  }),
]);

// #endregion
