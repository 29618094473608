import React from 'react';

import { useTranslation } from 'react-i18next';

import { BaseControl } from './base/BaseControl';
import { MetadataIcon } from 'components/shared/icons';
import { Loading } from 'components/loading-map-indicator/Loading';

export const MetadataControl = ({
  onClick,
  disabled,
  loading,
  cssClass
}: {
  disabled: boolean;
  loading: boolean;
  onClick?: () => any;
  cssClass?: string;
}) => {
  const { t } = useTranslation(['map']);

  const getTitle = () => {
    if (disabled && !loading) {
      return t('map:controls.metadata.noData')
    }

    if (loading) {
      return t('map:controls.metadata.loading')
    }

    if (!disabled && !loading) {
      return t('map:controls.metadata.title')
    }
  }

  return (
    <BaseControl
      cssClass={`metadata-control ${cssClass}`}
      onClick={() => {
        !disabled && !loading && onClick && onClick();
      }}
      title={getTitle()}
      tooltip={t('map:controls.metadata.tooltip')}
    >
      {!loading &&
        <MetadataIcon height="25px" width="25px" fill="white" />
      }
      <Loading show={loading} />
    </BaseControl>
  );
};
