import i18next from 'i18next';
import i18nextBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { APP_BUILD_UUID } from './constants';

i18next
  .use(i18nextBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'translation',
    fallbackNS: 'translation',
    nsSeparator: ':',
    load: 'languageOnly',
    lng: 'en',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?uuid=${APP_BUILD_UUID}`,
    },
    cleanCode: true,
  });

export default i18next;

export const Langs: { label: string; value: string; matchers: string[] }[] = [
  { label: 'ES', value: 'es', matchers: ['es', 'es-es'] },
  { label: 'EN', value: 'en', matchers: ['en', 'en-en', 'en-us'] },
];
