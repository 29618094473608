import { IPCCUrlParams, IPCCUrlCIDParams } from 'domain/models/url.model';
import { AtlasMode } from 'store/map';
import { defaultPlotState } from 'utils/plots/plot-state.controller';

const exportPath = '/export';
const exportSVG = exportPath + '/svg';
const exportMap = exportPath + '/map';

const devUrl =
  //
  //  'http://10.11.12.13:18092'; // Predictia (default)
  // 'http://10.11.12.246:8081'; // Max PC
  // 'http://10.11.12.168:8080'; // Max pc predictia
  // 'https://ipcc-atlas1-c1924be4.nip.io' // ifca1
  // 'https://ipcc-atlas.predictia.es'; // Staging
  // 'https://interactive-atlas.ipcc.ch'; // Producción
  'http://localhost:18092'; // Tunel a pro
// 'http://10.11.12.199:8080'; // dev David

export const AppConstants = {
  Api: {
    baseUrl:
      process.env.NODE_ENV === 'development' ? devUrl : window.location.origin,
    endpoints: {
      config: '/config.json',
      configSimple: '/config/simple.json',
      savePermalink: '/permalink/save',
      getPermalink: '/permalink/find',
      export: {
        svg: {
          pdf: `${exportSVG}/pdf`,
          png: `${exportSVG}/png`,
        },
        map: {
          png: `${exportMap}/png`,
          tiff: `${exportMap}/tif`,
          netcdf: `${exportMap}/netcdf`,
          cid: {
            png: `${exportPath}/cid/png`,
          },
        },
      },
      metadata: '/metadata/map',
      metadataExport: '/metadata/map/export',
      modelList: '/model/{{model}}/{{variable}}/{{scenario}}.json',
    },
    authorization: {
      token: `Basic ${btoa('reviewer:internalreview')}`,
    },
  },
} as const;

export const WmsParams = {
  service: 'service=WMS',
  version: 'version=1.3.0',
  getLegendRequest: 'request=getLegendGraphic',
  getFeatureInfoRequest: 'request=GetPointValue',
  legendWidth: 'width=65',
  legendHeight: 'height=300',
  legendFormat: 'format=xml',
  legendTransparent: 'transparent=true',
};

export const Theme = {
  Colors: {
    Primary: '#5492cd',
    PrimaryLg: '#6a9dd2',
    Secondary: 'white',
  },
};

// Default hash Predictia Dev env

const devConfig: string = btoa(
  JSON.stringify({
    type: 'ATLAS',
    commons: {
      lat: 9772.9869976703,
      lng: 400692.46690475324,
      zoom: 4,
      proj: 'EPSG:54030',
      mode: AtlasMode.COMPLETE,
    },
    primary: {
      period: '1980-2015',
      season: 'year',
      dataset: 'OBS-W5E5',
      scenario: 'observations',
      variable: 'tas',
      valueType: 'VALUE',
      regionSet: 'ar6',
      baseline: 'WMO2',
      hatching: 'SIMPLE',
      regionsSelected: [],
    },
    plot: defaultPlotState,
  } as IPCCUrlParams)
);

// Default has ifca1 env

const ifca1Config: string = btoa(
  JSON.stringify({
    type: 'ATLAS',
    commons: {
      lat: 9772.9869976703,
      lng: 400692.46690475324,
      zoom: 4,
      proj: 'EPSG:54030',
      mode: AtlasMode.COMPLETE,
    },
    primary: {
      period: '1980-2015',
      season: 'year',
      dataset: 'OBS-BerkeleyEarth',
      scenario: 'observations',
      variable: 'tas',
      valueType: 'VALUE',
      regionSet: 'ar6',
      baseline: 'preIndustrial',
      hatching: 'SIMPLE',
      regionsSelected: [],
    },
    plot: defaultPlotState,
  } as IPCCUrlParams)
);

// Default hash Predictia env

const stagingConfig = btoa(
  JSON.stringify({
    type: 'ATLAS',
    commons: {
      lat: 9772.9869976703,
      lng: 400692.46690475324,
      zoom: 4,
      proj: 'EPSG:54030',
      mode: AtlasMode.COMPLETE,
    },
    primary: {
      period: '2',
      season: 'year',
      dataset: 'CMIP6',
      scenario: 'ssp585',
      variable: 'tas',
      valueType: 'ANOMALY',
      regionSet: 'ar6',
      hatching: 'SIMPLE',
      baseline: 'preIndustrial',
      regionsSelected: [],
    },
    plot: defaultPlotState,
  } as IPCCUrlParams)
);

export const DefaultHash: string = ((env: string | undefined) => {
  console.log(`Running on environment: ${env}`);
  switch (env) {
    case 'dev':
      return '';
    case 'ifca1':
      return ifca1Config;
    case 'staging':
      return stagingConfig;
    default:
      return stagingConfig;
  }
})(process.env.REACT_APP_ATLAS_ENV);

export const DefaultCIDHash: string = btoa(
  JSON.stringify({
    type: 'CID',
    selectedIndex: 'mean_air_temperature',
    selectedVariable: 'confidence',
    mode: 'HEX',
    commons: {
      lat: 9772.9869976703,
      lng: 400692.46690475324,
      zoom: 4,
      proj: 'EPSG:54030',
    },
  } as IPCCUrlCIDParams)
);

export const ScenarioColor: { [K: string]: { line: string; area: string } } = {
  rcp26: {
    line: '#003466',
    area: '#4393c3',
  },
  rcp45: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  rcp85: {
    line: '#990002',
    area: '#fcd1c5',
  },
  ssp126: {
    line: '#003466',
    area: '#4393c3',
  },
  ssp1: {
    line: '#003466',
    area: '#4393c3',
  },
  ssp245: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  ssp2: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  ssp3: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  ssp370: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  ssp4: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  ssp5: {
    line: '#70a0cd',
    area: '#92c5de',
  },
  ssp585: {
    line: '#990002',
    area: '#fcd1c5',
  },
  historical: {
    line: '#808080',
    area: '#c4c4c4',
  },
  observations: {
    line: '#808080',
    area: '#c4c4c4',
  },
  paleoclimate: {
    line: '#808080',
    area: '#c4c4c4',
  },
};

export const GLWColors = [
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
];

export const APP_BUILD_UUID =
  process.env.REACT_APP_ATLAS_UUID ?? Date.now().toString();
