import React from 'react';

import { useTranslation } from 'react-i18next';

import { BaseControl } from './base/BaseControl';
import { CompareMapsIcon, NoCompareMapsIcon } from 'components/shared/icons';

export const MirrorControl = ({
  onClick,
  cssClass,
  mirroring,
}: {
  onClick?: () => any;
  cssClass?: string;
  mirroring: boolean;
}) => {
  const { t } = useTranslation(['map']);
  return (
    <BaseControl
      cssClass={`mirror-control ${cssClass}`}
      onClick={() => {
        onClick && onClick();
      }}
      title={
        mirroring
          ? t('map:controls.unmirror.title')
          : t('map:controls.mirror.title')
      }
      tooltip={
        mirroring
          ? t('map:controls.unmirror.tooltip')
          : t('map:controls.mirror.tooltip')
      }
    >
      {mirroring ? (
        <NoCompareMapsIcon height='30px' width='30px' fill='white' />
      ) : (
        <CompareMapsIcon height='30px' width='30px' fill='white' />
      )}
    </BaseControl>
  );
};
