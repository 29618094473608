import React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import OlMap from 'ol/Map';
import { Select } from 'ol/interaction';
import { pointerMove } from 'ol/events/condition';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import Image from 'ol/Image';
import { ImageWMS, TileWMS, Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import Collection from 'ol/Collection';
import MapEvent from 'ol/MapEvent';
import View from 'ol/View';
import BaseLayer from 'ol/layer/Base';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Feature, { FeatureLike } from 'ol/Feature';
import Overlay from 'ol/Overlay';
import Graticule from 'ol/layer/Graticule';
import { FullScreen, Control } from 'ol/control';
import { get as getProjection } from 'ol/proj';

import { ProjectionsNames } from 'core/map.projections';
import {
  IPCCProjection,
  FeatureInfo,
  RegionConfiguration,
} from 'domain/models/map.models';
import * as DeviceUtils from 'utils/device';
import { IStore } from 'store/store';
import { debounce, head, last, throttle } from 'utils/utils';
import {
  selectedFeatureStyle,
  unselectedFeatureStyle,
  hoveredFeatureStyle,
} from 'core/map.styles';
import { CanvasFilter } from 'utils/canvas.utils';
import { AppConstants } from 'core/constants';
import { WMSKeeper } from 'utils/wms-url-keeper';
import { AlertProps } from 'components/alert/Alert';
import { Nullable, DeepNullable } from 'domain/models/shared.types';
import {
  setMapView,
  setSimpleMapView,
  MapIdentifier,
  setReferenceProjection,
  setPointInfo,
  SimpleMapView,
} from 'store/map';

import { addAlert } from 'store/core';

import { ControlsContainer } from '../map-controls/base/ControlsContainer';

import JSONLegenFRef, {
  LegendSegment,
} from 'components/json-legend/JSONLegend';
import { MobileContainer } from 'components/map-controls/MobileContainer';
import { InfoTooltip } from 'components/info-tooltip/InfoTooltip';
import { Loading } from 'components/loading-map-indicator/Loading';
import { FullScreenControl } from 'components/map-controls/FullScreenControl';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import {
  CoastlineByProjection,
  HatchingType,
  RegionSet,
  ValueType,
} from 'domain/models/map-configuration.model';
import {
  getResolutions,
  isFeatureAllowed,
  isStereographic,
} from 'utils/map.utils';
import {
  RGBColorArray,
  ParsedLegendSegment,
  getSegmentsBetween,
} from 'utils/html-legend.utils';

import './Map.scss';
import './Map.responsive.scss';
import Geometry from 'ol/geom/Geometry';
import TileGrid from 'ol/tilegrid/TileGrid';
import RenderFeature from 'ol/render/Feature';
import { MapBrowserEvent } from 'ol';
import BaseEvent from 'ol/events/Event';
import { getReviewCode } from 'services/map.service';
import { Routes } from 'core';

const layersId = {
  regions: 'regionsLayer',
  coastline: 'coastlineLayer',
};

interface MapOwnProps extends WithTranslation {
  mirroring: boolean;
  url: string;
  geoJson: any;

  mapIdentifier: MapIdentifier;
  mapView: View;
  mapProjection: IPCCProjection;

  selectedRegions: any[];

  legendParameters: any;
  valueType: ValueType;

  graticuleActive: boolean;

  variable: any;

  infoActive: boolean;

  setSelectedRegions(regions: any[]): void;
  onMapClick(evt: any): void;
}

interface MapConnectStateToProps {
  featureInfo: any;
  regionsConfig: Nullable<RegionConfiguration>;
  selectedOptions: DeepNullable<ControllerOptionsState>;
  regionSet: RegionSet;
  hatching: HatchingType;
}

interface MapConnectDispatchToProps {
  setReferenceProjection(proj: IPCCProjection): void;
  setMapView(view: View): void;
  setSimpleMapView(simpleView: SimpleMapView): void;
  setPointInfo(featureInfo: any, mapIdentifier: MapIdentifier): void;
  addAlert(options: AlertProps): void;
}

type MapProps = MapOwnProps &
  MapConnectDispatchToProps &
  MapConnectStateToProps;

interface MapState {
  map: OlMap;
  mapHeight: number;
  showProgress: boolean;
  mapControlsOpen: boolean;
  closingTooltip: boolean;
  selectedLegendSegments: LegendSegment[];
  hoverColor: Uint8ClampedArray | null;
}

const graticuleFactory = (opts: any = {}) =>
  new Graticule({
    strokeStyle: new Stroke({
      color: 'rgba(255,120,0,0.9)',
      width: 2,
      lineDash: [0.5, 4],
    }),
    visible: false,
    extent: [-18000000, -9000000, 18000000, 9000000],
    wrapX: false,
    showLabels: true,
    maxLines: 10,
    zIndex: 102,
    ...opts,
  });

class MapComponent extends React.Component<
  React.PropsWithChildren<MapProps>,
  MapState
> {
  static initialState: MapState = {
    map: null as any,
    mapHeight: 800,
    showProgress: true,
    mapControlsOpen: false,
    closingTooltip: false,
    selectedLegendSegments: [],
    hoverColor: null,
  };

  private canvasFilter: CanvasFilter = new CanvasFilter();

  private masking: boolean = false;
  private prevZoom: number = 0;

  private mapId = `map__${Date.now()}`;
  private coastlineLayer: VectorLayer = new VectorLayer({
    zIndex: 100,
  });
  private regionsLayer: VectorLayer = new VectorLayer({
    zIndex: 101,
  });
  private wmsTileLayer: TileLayer = new TileLayer({
    zIndex: 3,
  });
  private wmsImageLayer: ImageLayer = new ImageLayer({
    zIndex: 3,
  });
  private overlay = new Overlay({
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
  });
  private tooltipContainer: any;

  private fullScreenControl = new FullScreen();
  private graticule: Graticule;

  private selectedSegmentsBetween?: LegendSegment[];

  private cleanCanvas?: CanvasRenderingContext2D;
  private isHoverFeature: boolean = false;

  constructor(props: MapProps) {
    super(props);

    this.graticule = graticuleFactory({
      showLabels: this.props.mapView.getZoom() > 6.3,
      visible: this.props.graticuleActive,
    });

    this.state = MapComponent.initialState;
    this.tooltipContainer = React.createRef();

    this.regionsLayer.set('id', layersId.regions);
    this.coastlineLayer.set('id', layersId.coastline);

    this.hoverInteraction = this.hoverInteraction.bind(this);

    this.onPointerMove = this.onPointerMove.bind(this);
    this.onRegionSelected = this.onRegionSelected.bind(this);
    this.onMoveEnd = this.onMoveEnd.bind(this);
    this.onTooltipClose = this.onTooltipClose.bind(this);
    this.onLegendClick = this.onLegendClick.bind(this);
    this.mask = this.mask.bind(this);
    this.getCanvasColor = throttle(this.getCanvasColor.bind(this), 100);

    window.addEventListener('resize', () => {
      this.setState({ mapHeight: this.getMapHeight() });
    });
  }

  componentDidMount() {
    document.body.style.overflowY = 'hidden';

    const m = new OlMap({
      target: this.mapId,
      view: this.props.mapView,
      controls: new Collection<Control>().extend([this.fullScreenControl]),
      layers: [this.graticule],
    });
    this.overlay.setElement(this.tooltipContainer.current);
    this.setState(
      {
        map: m,
        mapHeight: this.getMapHeight(),
      },
      () => {
        const selection = new Select({
          condition: pointerMove,
          style: this.hoverInteraction,
        });
        this.state.map.addInteraction(selection);

        this.state.map.on('moveend', this.onMoveEnd as any);

        this.state.map.on('pointermove', this.onPointerMove);
        this.state.map.on('singleclick', this.onRegionSelected as any);

        this.setCoastlineLayer();
        this.setRegionsLayer();
        if (isStereographic(this.props.mapProjection)) {
          this.setWmsImageLayer();
        } else {
          this.setWmsTileLayer();
        }

        this.wmsTileLayer.on('postrender', this.applyMask.bind(this));
        this.wmsImageLayer.on('postrender', this.applyMask.bind(this));

        this.state.map.addOverlay(this.overlay);
        this.state.map.render();

        (window as any).ipccMapInstance = this.state.map;
      }
    );

    DeviceUtils.onOrientationChange(() => {
      debounce(() => {
        this.setState({ mapHeight: this.getMapHeight() });
      }, 100)();
    });
  }

  componentDidUpdate(prevProps: MapProps) {
    if (prevProps.url !== this.props.url) {
      if (isStereographic(this.props.mapProjection)) {
        this.setWmsImageLayer();
      } else {
        this.setWmsTileLayer();
      }
      this.onTooltipClose();
      this.setState(
        {
          selectedLegendSegments: [],
        },
        () => {
          this.masking = false;
        }
      );
    }

    if (prevProps.mapProjection !== this.props.mapProjection) {
      this.cleanMap();

      this.setCoastlineLayer();
      this.setRegionsLayer();
      this.graticule.changed();
      this.resetGraticule(this.props.mapView.getZoom() > 6.3);

      if (isStereographic(this.props.mapProjection)) {
        this.setWmsImageLayer();
      } else {
        this.setWmsTileLayer();
      }
    }

    if (prevProps.graticuleActive !== this.props.graticuleActive) {
      this.setGraticule();
    }

    if (
      prevProps.geoJson !== this.props.geoJson ||
      prevProps.selectedRegions !== this.props.selectedRegions ||
      prevProps.mirroring !== this.props.mirroring
    ) {
      this.setRegionsLayer();
    }

    this.state.map.setView(this.props.mapView);

    if (this.props.mirroring !== prevProps.mirroring) {
      this.setState({ mapHeight: this.getMapHeight() });
    }

    if (this.props.featureInfo !== prevProps.featureInfo) {
      this.updateInfoTooltip();
    }

    if (
      this.props.selectedOptions.dataset?.model !==
      prevProps.selectedOptions.dataset?.model
    ) {
      this.checkSelectedRegions();
    }

    debounce(() => {
      this.state.map.updateSize();
      this.state.map.render();
    }, 100)();
  }

  componentWillUnmount() {
    this.cleanMap();
    this.state.map && this.state.map.dispose();
    document.body.style.overflowY = 'unset';
  }

  private getMapHeight(): number {
    const windowHeight = document.documentElement.clientHeight;

    const caption = document.getElementById(
      `${this.props.mapIdentifier}-map-caption`
    );
    let captionHeight = DeviceUtils.getIsMobile() ? 0 : 23;
    if (caption) {
      captionHeight = caption.clientHeight;
    }

    return DeviceUtils.isSmallScreen() || DeviceUtils.getIsMobile()
      ? windowHeight - 20 - captionHeight
      : windowHeight -
          85 -
          40 -
          (this.props.mirroring ? 0 : 43) -
          captionHeight;
  }

  private setCoastlineLayer(): void {
    if (this.coastlineLayer.getSource()) {
      this.coastlineLayer.getSource().clear();
    }

    this.coastlineLayer.setSource(
      new VectorSource({
        url: CoastlineByProjection[this.props.mapProjection.code],
        format: new GeoJSON({
          featureProjection: getProjection(this.props.mapProjection.code),
          dataProjection:
            this.props.mapProjection.code === 'EPSG:54030pc'
              ? getProjection(this.props.mapProjection.code)
              : undefined,
        }),
      })
    );
    this.coastlineLayer.setStyle(
      () =>
        new Style({
          stroke: new Stroke({
            color: 'rgba(40, 40, 40, 1)',
            width: 1,
          }),
        })
    );
    this.coastlineLayer.setMap(this.state.map);
  }

  private async setRegionsLayer() {
    if (this.props.geoJson) {
      if (this.regionsLayer.getSource()) {
        this.regionsLayer.getSource().clear();
        this.regionsLayer.getSource().dispose();
      }

      // Firefox por algun motivo representa el Oceano Ártico en todo el mapa con la proyección
      // estereográfica (Sur) y como no deberia verse en esta proyección, pues la quitamos ¯\_(ツ)_/¯

      let geoJson = this.props.geoJson;

      if (this.props.mapProjection.code === ProjectionsNames.EPSG_3412) {
        geoJson = {
          ...geoJson,
          features: geoJson.features.filter(
            (f: any) => f.properties.Acronym !== 'ARO'
          ),
        };
      }

      this.regionsLayer.setSource(
        new VectorSource({
          features: new GeoJSON().readFeatures(geoJson, {
            featureProjection: getProjection(this.props.mapProjection.code),
            dataProjection:
              this.props.mapProjection.code === 'EPSG:54030pc'
                ? getProjection(this.props.mapProjection.code)
                : undefined,
          }),
        })
      );

      this.regionsLayer.setStyle((feature: FeatureLike) => {
        return this.props.selectedRegions.find((region: any) => {
          return region.id === feature.getId();
        }) && !this.props.mirroring
          ? selectedFeatureStyle
          : unselectedFeatureStyle;
      });
      this.regionsLayer.setMap(this.state.map);
    }
  }

  private setGraticule() {
    if (this.props.graticuleActive) {
      this.graticule.setVisible(true);
    } else {
      this.graticule.setVisible(false);
    }
  }

  private resetGraticule(showLabels: boolean) {
    let visible = this.props.graticuleActive;
    this.state.map.removeLayer(this.graticule);
    this.graticule = graticuleFactory({ showLabels, visible });
    this.state.map.addLayer(this.graticule);
  }

  onMoveEnd(evt: MapEvent) {
    const view = evt.map.getView();
    const zoom = view.getZoom();
    const limit = 6.3;

    if (zoom > limit && this.prevZoom < limit) {
      this.resetGraticule(true);
    } else if (zoom < limit && this.prevZoom > limit) {
      this.resetGraticule(false);
    }
    this.prevZoom = zoom;
    this.props.setSimpleMapView(view as any);
  }

  private checkSelectedRegions() {
    const newAllowedFeatures = this.props.selectedRegions.filter(
      (feat: any) => {
        const allowed = isFeatureAllowed(
          feat.Acronym,
          this.props.selectedOptions,
          this.props.regionsConfig,
          this.props.regionSet
        );
        return allowed === true || allowed === null;
      }
    );
    this.props.setSelectedRegions(newAllowedFeatures);
  }

  private async onRegionSelected(evt: any) {
    if ((this.props.mirroring && !this.props.infoActive) || this.masking) {
      return;
    }
    const pixel = this.state.map.getEventPixel(evt.originalEvent);
    if (this.props.infoActive) {
      let allowed: boolean | null = null;
      this.state.map.forEachFeatureAtPixel(pixel, (feature) => {
        allowed = isFeatureAllowed(
          feature,
          this.props.selectedOptions,
          this.props.regionsConfig,
          this.props.regionSet
        );
      });
      if (allowed === null || allowed === true) {
        !this.state.closingTooltip && this.props.onMapClick(evt);
      }
    } else {
      this.state.map.forEachFeatureAtPixel(
        pixel,
        (feature) => {
          const allowed = isFeatureAllowed(
            feature,
            this.props.selectedOptions,
            this.props.regionsConfig,
            this.props.regionSet
          );
          if (allowed === false) {
            return;
          }
          if (
            this.props.selectedRegions.find(
              (region: any) => region.id === feature.getId()
            )
          ) {
            this.props.setSelectedRegions(
              this.props.selectedRegions.filter(
                (region: any) => region.id !== feature.getId()
              )
            );
            (feature as Feature<Geometry>).setStyle(unselectedFeatureStyle);
          } else {
            this.props.setSelectedRegions(
              this.props.selectedRegions.concat((feature as any).values_)
            );
            (feature as Feature<Geometry>).setStyle(selectedFeatureStyle);
          }
        },
        {
          layerFilter: (layer: BaseLayer) =>
            layer.get('id') === layersId.regions,
        }
      );
    }
  }

  private updateInfoTooltip() {
    if (this.props.featureInfo) {
      this.overlay.setPosition(this.props.featureInfo.originalCoords);
      // :'( No me dejaron opción...
      const closer = document.getElementById(
        `feature-tooltip-closer-${this.props.mapIdentifier}`
      );
      closer && closer.addEventListener('click', this.onTooltipClose);
    }
  }

  private applyMask(evt: any) {
    if (evt.context) {
      const canvas = evt.context.canvas;
      //if (!this.isHoverFeature) {
      // Este if impedia que el canvas que se guardase tuviese zonas con el efecto hover para que se
      // pudiese hacer bien la máscara y conseguir correctamente el color para el indicador de la leyenda. Parece que ya no hace falta :/
      // @see https://gitlab.predictia.es/ipcc/web/-/issues/338
      this.cleanCanvas =
        this.cloneCanvas(canvas as HTMLCanvasElement).getContext('2d') ??
        undefined;
      //}
      if (
        this.masking &&
        this.selectedSegmentsBetween &&
        this.selectedSegmentsBetween.length > 0
      ) {
        const imageData = this.canvasFilter.maskAllSegments(
          canvas,
          this.selectedSegmentsBetween
        );
        this.masking = true;

        if (imageData) {
          evt.context.putImageData(imageData, 0, 0);
        }
      } else {
        this.canvasFilter.unmask(canvas);
      }
    }
  }

  private setWmsTileLayer(): void {
    const proj = getProjection(this.props.mapProjection.epsg);
    const { resolutions } = getResolutions(proj.getExtent());
    this.wmsTileLayer.setSource(
      new TileWMS({
        url: this.props.url,
        projection: this.props.mapProjection.code,
        params: {},
        tileGrid: new TileGrid({
          tileSize: [1024, 1024],
          resolutions,
          extent: proj.getExtent(),
        }),
        serverType: 'geoserver',
        crossOrigin: 'Anonymous',
        tileLoadFunction: (image: any, src: string) => {
          fetch(src, {
            headers: { Authorization: AppConstants.Api.authorization.token },
          })
            .then((res: Response) =>
              res
                .blob()
                .then((imageData: Blob) => {
                  if (imageData.size === 0) {
                    loadCB();
                    throw new Error('Internal Server Error - 500');
                  }
                  WMSKeeper.setUrl(src);
                  const uri = URL.createObjectURL(imageData);
                  (image.getImage() as HTMLImageElement).src = uri;
                })
                .catch((err) => {
                  console.error(err);
                  this.showErrorMessage();
                })
            )
            .catch((err) => {
              console.error(err);
              this.showErrorMessage();
            });
        },
      })
    );

    this.wmsTileLayer.setVisible(true);
    this.wmsImageLayer.setVisible(false);

    this.wmsTileLayer.setMap(this.state.map);

    const loadCB = () => {
      this.setState({ showProgress: false });
    };

    this.wmsTileLayer
      .getSource()
      .on('tileloadstart', () => this.setState({ showProgress: true }));
    this.wmsTileLayer.getSource().on('tileloadend', loadCB);
    this.wmsTileLayer.getSource().on('tileloaderror', loadCB);
  }

  private setWmsImageLayer() {
    this.wmsImageLayer.setSource(
      new ImageWMS({
        url: this.props.url,
        projection: this.props.mapProjection.code,
        params: {},
        crossOrigin: 'Anonymous',
        imageLoadFunction: (image: Image, src: string) => {
          fetch(src, {
            headers: { Authorization: AppConstants.Api.authorization.token },
          })
            .then((res: Response) =>
              res
                .blob()
                .then((imageData: Blob) => {
                  if (imageData.size === 0) {
                    loadCB();
                    throw new Error('Internal Server Error - 500');
                  }
                  WMSKeeper.setUrl(src);
                  const uri = URL.createObjectURL(imageData);
                  (image.getImage() as HTMLImageElement).src = uri;
                })
                .catch((err) => {
                  console.error(err);
                  this.showErrorMessage();
                })
            )
            .catch((err) => {
              console.error(err);
              this.showErrorMessage();
            });
        },
      })
    );

    this.wmsTileLayer.setVisible(false);
    this.wmsImageLayer.setVisible(true);

    this.wmsImageLayer.setMap(this.state.map);

    const loadCB = () => {
      this.setState({ showProgress: false });
    };

    this.wmsImageLayer
      .getSource()
      .on('imageloadstart', () => this.setState({ showProgress: true }));
    this.wmsImageLayer.getSource().on('imageloadend', loadCB);
    this.wmsImageLayer.getSource().on('imageloaderror', loadCB);
  }

  private cleanMap() {
    if (this.state.map) {
      this.state.map.getLayers().forEach((layer: BaseLayer) => {
        this.state.map.removeLayer(layer);
        layer.dispose();
      });
      this.setState({ map: this.state.map });
      this.state.map.render();
    }
  }

  hoverInteraction(
    feature: Feature<any> | RenderFeature,
    arg1: number
  ): void | Style | Style[] | undefined {
    if (this.props.infoActive || this.masking || this.props.mirroring) {
      this.isHoverFeature = false;
      return unselectedFeatureStyle;
    }

    if (!feature.getProperties().Acronym) {
      return new Style({
        stroke: new Stroke({
          color: 'rgba(40, 40, 40, 1)',
          width: 1,
        }),
      });
    }

    const isSelected = this.props.selectedRegions.find(
      (r) => r.id === feature.getId()
    );

    if (isSelected) {
      this.isHoverFeature = true;
      return selectedFeatureStyle;
    }

    const allowed = isFeatureAllowed(
      feature,
      this.props.selectedOptions,
      this.props.regionsConfig,
      this.props.regionSet
    );

    if (allowed === false) {
      this.isHoverFeature = false;
      return unselectedFeatureStyle;
    }
    this.isHoverFeature = true;
    return hoveredFeatureStyle;
  }

  onPointerMove(event: Event | BaseEvent): void {
    const evt = event as unknown as MapBrowserEvent;
    this.getCanvasColor(evt);
    if (
      evt.dragging ||
      this.props.mirroring ||
      this.masking ||
      this.props.infoActive
    ) {
      return;
    }
    const featuresAtPixel = this.state.map.getFeaturesAtPixel(evt.pixel);
    if (featuresAtPixel.length === 0) {
      this.isHoverFeature = false;
      this.state.map.getTargetElement().style.cursor = 'default';
    } else if (!this.props.infoActive) {
      this.isHoverFeature = true;
      this.state.map.getTargetElement().style.cursor = 'pointer';
    }
  }

  getCanvasColor(event: MapBrowserEvent) {
    if (this.cleanCanvas) {
      const color = this.cleanCanvas.getImageData(
        event.pixel[0],
        event.pixel[1],
        1,
        1
      ).data;
      this.setState({ hoverColor: color });
    }
  }

  onTooltipClose(evt?: MouseEvent) {
    this.setState({ closingTooltip: true });
    evt?.stopPropagation();
    this.props.setPointInfo(null, this.props.mapIdentifier);
    const closer = document.getElementById('feature-tooltip-closer');
    closer && closer.removeEventListener('click', this.onTooltipClose);
    this.overlay.setPosition(undefined as any);
    // El evento parece ignorar el stopPropagation y se transmite hasta el mapa
    // lo que vuelve a lanzar un evento click y abre un nuevo tooltip :/
    debounce(() => this.setState({ closingTooltip: false }), 500)();
    return false;
  }

  private async showErrorMessage() {
    try {
      const reviewCode = await getReviewCode();

      const url = `${window.location.origin}${Routes.Review}/${reviewCode}`;

      this.props.addAlert({
        message: this.props.t('map:error.message', { code: url }),
        variant: 'error',
        title: this.props.t('map:error.title'),
        duration: 15000,
      });
    } catch (err) {}
  }

  private cloneCanvas(canvas: HTMLCanvasElement): HTMLCanvasElement {
    const newCanvas = document.createElement('canvas') as HTMLCanvasElement;
    const context = newCanvas.getContext('2d') as CanvasRenderingContext2D;

    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;

    context.drawImage(canvas, 0, 0);

    return newCanvas;
  }

  onLegendClick(
    legendSegment: LegendSegment,
    segments: ParsedLegendSegment[]
  ): void {
    let newLegendSegments: LegendSegment[];
    if (
      this.state.selectedLegendSegments.find(
        (l) => l.index === legendSegment.index
      )
    ) {
      newLegendSegments = this.state.selectedLegendSegments.filter(
        (l) => l.index !== legendSegment.index
      );
    } else {
      newLegendSegments = this.state.selectedLegendSegments
        .concat(legendSegment)
        .sort((l1, l2) => l1.index - l2.index);
    }

    if (newLegendSegments.length === 0) {
      this.masking = false;
      this.setState({ selectedLegendSegments: [] });
      return;
    }

    this.setState(
      {
        selectedLegendSegments: newLegendSegments,
      },
      () => {
        this.selectedSegmentsBetween = getSegmentsBetween(
          segments.slice(),
          newLegendSegments
        );
        this.mask();
      }
    );
  }

  mask(canvas?: HTMLCanvasElement) {
    if ((this.selectedSegmentsBetween?.length ?? 0) > 0) {
      this.masking = true;
    } else {
      this.masking = false;
    }
    this.wmsTileLayer.changed();
  }

  render() {
    return (
      <>
        <div
          id={this.mapId}
          className='map'
          aria-live='polite'
          tabIndex={undefined}
          style={{ height: this.state.mapHeight, width: '100%' }} // No se puede poner height: 100%
        >
          {this.props.children ? (
            DeviceUtils.getIsMobile() && !this.props.mirroring ? (
              <MobileContainer
                open={this.state.mapControlsOpen}
                onClick={() => {
                  this.setState({
                    mapControlsOpen: !this.state.mapControlsOpen,
                  });
                }}
              >
                {this.props.children}
                <FullScreenControl />
              </MobileContainer>
            ) : (
              <ControlsContainer containerHeight={this.state.mapHeight}>
                {this.props.children}
                {this.props.mapIdentifier === 'primaryMap' &&
                  !this.props.mirroring && (
                    <FullScreenControl
                      onClick={() =>
                        (this.fullScreenControl as any)['handleFullScreen_']()
                      }
                    />
                  )}
              </ControlsContainer>
            )
          ) : null}
          <Loading show={this.state.showProgress} />
          <JSONLegenFRef
            legendParameters={this.props.legendParameters}
            variable={this.props.variable}
            valueType={this.props.valueType}
            mirroring={this.props.mirroring}
            hatching={this.props.hatching}
            selectedLegendSegments={this.state.selectedLegendSegments}
            side={this.props.mapIdentifier === 'primaryMap' ? 'left' : 'right'}
            hoverColor={this.state.hoverColor}
            onClick={this.onLegendClick}
          />
        </div>
        <div
          ref={this.tooltipContainer}
          id={`feature-info-overlay-${this.props.mapIdentifier}`}
          className='feature-info-overlay'
        >
          {this.props.featureInfo && (
            <InfoTooltip
              mapIdentifier={this.props.mapIdentifier}
              featureInfo={this.props.featureInfo.data as FeatureInfo[]}
              selectedOptions={this.props.featureInfo.selectedOptions}
              onClose={this.onTooltipClose}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStoreToProps: MapStateToProps<
  MapConnectStateToProps,
  MapOwnProps,
  IStore
> = (store: IStore, props: MapOwnProps) => ({
  featureInfo: store.map[props.mapIdentifier].featureInfo,
  regionsConfig: store.map.commons.regionsConfig,
  selectedOptions: store.map[props.mapIdentifier].selectedOptions,
  regionSet: store.map[props.mapIdentifier].regionSet,
  hatching: store.map[props.mapIdentifier].hatching,
});

const mapDispatchToProps: MapDispatchToProps<
  MapConnectDispatchToProps,
  MapOwnProps
> = {
  setReferenceProjection,
  setSimpleMapView,
  setMapView,
  setPointInfo,
  addAlert,
};

export const Map = withTranslation('map')(
  connect<
    MapConnectStateToProps,
    MapConnectDispatchToProps,
    // Si da un error de que el tipo es demasiado complejo, cambiar MapOwnProps por any
    any, //MapOwnProps,
    IStore
  >(
    mapStoreToProps,
    mapDispatchToProps
  )(MapComponent)
);
