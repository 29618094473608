import React from 'react';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { debounce } from 'utils/utils';
import { useIsMobile } from 'hooks/useIsMobile';
import { ProjectionsNames } from 'core/map.projections';

import {
  MapIcon,
  GraticuleIcon,
  PacificProjIcon,
  RobinsonIcon,
  StereographicSouthIcon,
  StereographicNorthIcon,
} from 'components/shared/icons';

import { IPCCProjections } from 'domain/models/map.models';
import {
  ExpandableBaseControl,
  ExpandableChildControl,
} from './base/ExpandableBaseControl';

import './MapControls.scss';

export const ProjectionControl = ({
  cssClass,
  value,
  onClick,
  onGraticuleClick,
  graticuleActive,
  mirroring,
  disabled,
  onChange,
}: {
  cssClass?: string;
  value: any;
  mirroring: boolean;
  disabled: Array<keyof IPCCProjections>;
  graticuleActive: boolean;
  onGraticuleClick: VoidFunction;
  onClick: () => any;
  onChange: (value: any) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  const isMobile = useIsMobile();

  const { t } = useTranslation(['map']);

  const intOnChange = ({ name }: { name: keyof IPCCProjections }) => {
    if (disabled.includes(name)) {
      return;
    }
    onChange(name);
    debounce(() => setOpen(false), 100)();
  };

  const intOnClick = () => {
    setOpen(!open);
    onClick();
  };

  const className = React.useCallback(
    (optValue: keyof IPCCProjections, key: string) =>
      cn(
        'projection-option',
        key,
        open ? 'open' : 'close',
        value === optValue ? 'active' : '',
        disabled.includes(optValue) ? 'disabled' : ''
      ),
    [open, disabled, value]
  );

  return (
    <ExpandableBaseControl
      cssClass={cssClass}
      open={open}
      mirroring={mirroring}
      onClick={intOnClick}
      title={t('map:controls.projections.title')}
      tooltip={t('map:controls.projections.tooltip')}
      icon={<MapIcon width='25px' height='25px' fill='white' />}
    >
      <ExpandableChildControl
        onClick={
          [ProjectionsNames.EPSG_54030].includes(value)
            ? onGraticuleClick
            : () => {}
        }
        cssClass={`graticule-control ${graticuleActive ? 'active' : ''} ${
          ![ProjectionsNames.EPSG_54030].includes(value) ? 'disabled' : ''
        }`}
        title={t('map:controls.graticule.title')}
        tooltip={t('map:controls.graticule.title')}
      >
        <GraticuleIcon width='20px' height='20px' fill='white' />
      </ExpandableChildControl>

      <ExpandableChildControl
        title={t('map:projections.robinson.title')}
        cssClass={className(ProjectionsNames.EPSG_54030, 'robinson')}
        tooltip={t('map:projections.robinson.title')}
        onClick={() =>
          open &&
          intOnChange({
            name: 'EPSG:54030',
          })
        }
      >
        <RobinsonIcon width='20px' height='20px' />
      </ExpandableChildControl>

      <ExpandableChildControl
        title={t('map:projections.robinson_pacific.title')}
        cssClass={className(
          ProjectionsNames.EPSG_54030_Pacific,
          'robinson_pacific'
        )}
        tooltip={t('map:projections.robinson_pacific.title')}
        onClick={() =>
          open &&
          intOnChange({
            name: 'EPSG:54030pc',
          })
        }
      >
        <PacificProjIcon width='20px' height='20px' />
      </ExpandableChildControl>

      <ExpandableChildControl
        title={t('map:projections.north.title')}
        cssClass={className(ProjectionsNames.EPSG_3413, 'north')}
        tooltip={t('map:projections.north.title')}
        onClick={() =>
          open &&
          intOnChange({
            name: 'EPSG:3413',
          })
        }
      >
        <StereographicNorthIcon width='24px' height='24px' />
      </ExpandableChildControl>

      <ExpandableChildControl
        title={t('map:projections.south.title')}
        cssClass={className(ProjectionsNames.EPSG_3412, 'south')}
        tooltip={t('map:projections.south.title')}
        onClick={() =>
          open &&
          intOnChange({
            name: 'EPSG:3412',
          })
        }
      >
        <StereographicSouthIcon width='20px' height='20px' />
      </ExpandableChildControl>
    </ExpandableBaseControl>
  );
};
