import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  lazy,
  Suspense,
} from 'react';

import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch, batch } from 'react-redux';
import cn from 'classnames';
import MapBrowserPointerEvent from 'ol/MapBrowserEvent';
import { transform } from 'ol/proj';
import { useTranslation } from 'react-i18next';

import { deserialize, serialize } from 'utils/url-serializer';
import { Nullable } from 'domain/models/shared.types';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import { addAlert } from 'store/core';
import { Projections } from 'core/map.projections';
import { AppConstants, DefaultHash } from 'core/constants';
import { useIsMobile } from 'hooks/useIsMobile';
import { ShareController } from 'utils/share.controller';
import { IStore } from 'store/store';
import { UrlKeeper } from 'utils/initial-url-keeper';
import { onceStateComparision, assert } from 'utils/utils';
import { getRegionSetToLoad, mapIdsToFeatures } from 'utils/map.utils';
import { IPCCProjections, RegionConfiguration } from 'domain/models/map.models';
import { IPCCUrlPlotParams } from 'domain/models/url.model';
import {
  AvailableGeojson,
  MapConfiguration,
  RegionSet,
} from 'domain/models/map-configuration.model';
import { defaultPlotState } from 'utils/plots/plot-state.controller';
import {
  getConfiguration,
  getRegionsGeoJSON,
  getFeatureInfo,
  getReviewCode,
  exportMap,
  getRegionsConfig,
} from 'services/map.service';
import {
  MapIdentifier,
  setConfiguration,
  setSelectedOptions,
  setMapView,
  setSimpleMapView,
  setRegionSet,
  setGeoJson,
  setRegionsSelected,
  setReferenceProjection,
  setPointInfo,
  setRegionsConfiguration,
  setHatching,
  setAtlasMode,
  AtlasMode,
} from 'store/map';

import { ProjectionControl } from 'components/map-controls/ProjectionsControl';
import { PermalinkTooltip } from 'components/permalink-tooltip/PermalinkTooltip';
import { InfoControl } from 'components/map-controls/InfoControl';
import { ZoomControl } from 'components/map-controls/ZoomControl';
import { MirrorControl } from 'components/map-controls/MirrorControl';
import { MapContainer } from 'components/map/MapContainer';
import { MapPageErrorBoundaryRedirect } from './MapPageErrorBoundary';
import { DownloadControl } from 'components/map-controls/DownloadControl';
import { MetadataControl } from 'components/map-controls/MetadataControl';
import { Loading } from 'components/loading-map-indicator/Loading';
import { RegionalInformationMobileWarning } from 'components/regional-information-mobile-warning/RegionalInformationMobileWarning';
import { AtlasTour } from 'components/tour/AtlasTour';

import { getMetadata } from 'services/metadata.service';
import { Metadata } from 'domain/models/metadata.model';

import { ChartsContainerErrorBoundary } from 'components/charts-container/ChartsContainerErrorBoundary';
import { Routes } from 'core';
import { ShareControl } from 'components/map-controls/ShareControl';

import './MapPage.scss';

const ChartsContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "charts.module" */ 'components/charts-container/ChartsContainer'
    )
);
const MetadataContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "metadata.module" */ 'components/metadata/MetadataContainer'
    )
);

export const MapPageWithoutErrorBoundary = () => {
  // SELECTORS
  const primaryMapSelectedOptions = useSelector(
    (store: IStore) => store.map.primaryMap.selectedOptions
  );
  const secondaryMapSelectedOptions = useSelector(
    (store: IStore) => store.map.secondaryMap.selectedOptions
  );

  const atlasMode = useSelector((store: IStore) => store.map.commons.mode);

  const regionSetSelected = useSelector((store: IStore) => ({
    primaryMap: store.map.primaryMap.regionSet,
    secondaryMap: store.map.secondaryMap.regionSet,
  }));

  const mapProjection = useSelector(
    (store: IStore) => store.map.commons.referenceProjection
  );

  const regionsSelected = useSelector((store: IStore) => ({
    primaryMap: store.map.primaryMap.regionsSelected,
    secondaryMap: store.map.secondaryMap.regionsSelected,
  }));

  const hatching = useSelector((store: IStore) => ({
    primaryMap: store.map.primaryMap.hatching,
    secondaryMap: store.map.secondaryMap.hatching,
  }));

  const geoJsons = useSelector((store: IStore) => store.map.commons.geoJson);

  const mapViews = useSelector((store: IStore) => ({
    simpleView: store.map.commons.simpleView,
    view: store.map.commons.view,
  }));

  const config = useSelector(
    (store: IStore) => store.map.commons.config,
    onceStateComparision
  );

  const selectedRegionSetConfiguration = useSelector(
    (store: IStore) => store.map.commons.regionsConfig
  );

  // STATE
  const [shouldRenderMap, setShouldRenderMap] = useState(false);

  const [showCharts, setShowCharts] = useState(false);

  const [showMetadata, setShowMetadata] = useState(false);

  const [url, setUrl] = useState<string>('');

  const [mirroring, setMirroring] = useState(false);

  const [infoActive, setInfoActive] = useState(false);

  const [reviewCode, setReviewCode] = useState('');

  const [plotState, setPlotState] = useState<IPCCUrlPlotParams | null>(null);

  const [metadataData, setMetadataData] = useState<
    Metadata[] | null | undefined
  >(null);

  const [metadataLoading, setMetadataLoading] = useState(false);

  const [graticuleActive, setGraticuleActive] = React.useState(false);

  // REFS
  const firstRender = useRef({ withHash: false, firstRender: true });
  const defaultConfig = useRef<MapConfiguration>();
  const simpleConfig = useRef<MapConfiguration>();

  // Third Party
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const { t } = useTranslation();

  // EFECTS
  useEffect(() => {
    Promise.all([
      getConfiguration(AtlasMode.COMPLETE),
      getConfiguration(AtlasMode.SIMPLE),
      getRegionsConfig(),
    ]).then(
      ([configuration, simpleConfiguration, regionsConfig]: [
        MapConfiguration,
        MapConfiguration,
        RegionConfiguration
      ]) => {
        defaultConfig.current = configuration;
        simpleConfig.current = simpleConfiguration;
        dispatch(setRegionsConfiguration(regionsConfig));
        const initialUrl = UrlKeeper.getUrl();
        let hash = initialUrl.hash || DefaultHash;
        try {
          firstRender.current.withHash = true;
          firstRender.current.firstRender = false;
          onUpdateHash(
            hash,
            configuration,
            simpleConfiguration,
            !initialUrl.hash
          );
        } catch (err) {
          console.error('Failed to load url hash', err);
          // Si el hash que se ha recuperado falla por que no esta bien formado, lo volvemos a intentar con
          // el hash por defecto. Si este también falla, que Dios se apiade de nuestras almas...
          onUpdateHash(DefaultHash, configuration, simpleConfiguration, true);
        }
        setShouldRenderMap(true);
      }
    );
  }, []);

  useEffect(() => {
    if (primaryMapSelectedOptions.dataset) {
      try {
        updateUrl(primaryMapSelectedOptions, 'primaryMap');
      } catch (err) {
        console.error(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapViews.simpleView,
    regionSetSelected,
    regionsSelected,
    primaryMapSelectedOptions,
    secondaryMapSelectedOptions,
    atlasMode,
    plotState,
    hatching,
  ]);

  useEffect(() => {
    if (atlasMode === AtlasMode.SIMPLE) {
      dispatch(setConfiguration(simpleConfig.current!));
    } else {
      dispatch(setConfiguration(defaultConfig.current!));
    }
  }, [atlasMode]);

  useEffect(() => {
    batch(() => {
      setMetadataLoading(false);
      setMetadataData(null);
      setShowMetadata(false);
    });
  }, [
    primaryMapSelectedOptions,
    hatching.primaryMap,
    mapViews.simpleView.zoom,
    mapViews.simpleView.center,
  ]);

  useEffect(() => {
    if (regionsSelected.primaryMap.length === 0) {
      setShowCharts(false);
    } else {
      setShowCharts(true);
    }
  }, [regionsSelected, primaryMapSelectedOptions.valueType]);

  const updateUrl = (state: any, map: MapIdentifier) => {
    const primaryState =
      map === 'primaryMap' ? state : primaryMapSelectedOptions;
    let secondaryState;
    if (mirroring) {
      secondaryState =
        map === 'secondaryMap' ? state : secondaryMapSelectedOptions;
    }
    try {
      const serialized = serialize(
        {
          ...primaryState,
          regionSet: regionSetSelected.primaryMap,
          regions: regionsSelected.primaryMap,
          hatching: hatching.primaryMap,
        },
        {
          ...secondaryState,
          regionSet: regionSetSelected.secondaryMap,
          regions: regionsSelected.secondaryMap,
          hatching: hatching.secondaryMap,
        },
        {
          ...mapViews.simpleView,
          proj: mapProjection.code,
          mode: atlasMode ?? AtlasMode.COMPLETE,
        },
        plotState || defaultPlotState
      );

      window.location.hash = serialized.encoded;
      setUrl(window.location.href);
    } catch (err) {
      window.location.hash = DefaultHash;
      setUrl(window.location.href);
      console.error(err);
    }
  };

  const onOptionsChange = (
    options: ControllerOptionsState,
    mapIdentifier: MapIdentifier
  ) => {
    batch(() => {
      if (
        regionSetSelected[mapIdentifier] !== 'none' &&
        !options.variable.regionset.find((rs) => {
          if (rs === regionSetSelected[mapIdentifier]) {
            return true;
          }
          return false;
        })
      ) {
        const defaultRegionSet = options.variable.regionset.find(
          (rs) => rs === 'ar6'
        );
        onRegionSetChange(
          { value: defaultRegionSet || options.variable.regionset[0] },
          mapIdentifier
        );
      }
      if (
        options.dataset?.model.projection &&
        options.dataset?.model.projection !==
          primaryMapSelectedOptions.dataset?.model.projection
      ) {
        dispatch(
          setReferenceProjection(Projections[options.dataset?.model.projection])
        );
      }

      dispatch(setSelectedOptions(options, mapIdentifier));
    });
  };

  const onRegionSetChange = async (
    evt: any,
    mapIdentifier: MapIdentifier,
    newProj?: keyof IPCCProjections
  ) => {
    const geojsonName = getRegionSetToLoad(
      evt.value,
      newProj || mapProjection.code
    );
    if (!geojsonName) {
      return;
    }
    let geoJson = geoJsons[geojsonName];
    if (!geoJson) {
      geoJson = await getRegionsGeoJSON(geojsonName);
    }
    batch(() => {
      dispatch(setRegionSet(evt.value, mapIdentifier));
      dispatch(setGeoJson(geoJson, geojsonName));
      dispatch(setRegionsSelected([], 'primaryMap'));
      dispatch(setRegionsSelected([], 'secondaryMap'));
    });
  };

  const onReferenceProjectionChange = (value: keyof IPCCProjections) => {
    const proj = Projections[value];
    dispatch(setReferenceProjection(proj));

    onRegionSetChange(
      { value: regionSetSelected.primaryMap },
      'primaryMap',
      value
    );
    onRegionSetChange(
      { value: regionSetSelected.secondaryMap },
      'secondaryMap',
      value
    );

    if (value !== 'EPSG:54030') {
      setGraticuleActive(false);
    }
  };

  /**
   * Method called only on mount to read url hash and apply parameters.
   * @param newURL
   * @param configuration
   */
  const onUpdateHash = (
    hash: string,
    configuration: Nullable<MapConfiguration> = config,
    simpleConfiguration: MapConfiguration,
    lookForModeAtStore: boolean = false
  ): void => {
    if (configuration) {
      const tmpUrl = `${AppConstants.Api.baseUrl}${configuration.properties.WMS_URL}${hash}`;
      setUrl(tmpUrl);

      // Transform url hash to parameters: SelectorOptions, RegionSet, RegionSelected, LatLng, Zoom, etc...
      const deserializedData = deserialize(hash, configuration);
      const {
        regionSet: primaryRegionSet,
        regions: primaryRegions,
        hatching: primaryHatching,
        ...newPrimaryMapSelectedOptions
      } = deserializedData.primary;

      const { proj, mode, ...simpleMapView } = deserializedData.common;
      const plotState = deserializedData.plot;

      let _mode = lookForModeAtStore ? atlasMode ?? mode : mode;

      if (_mode === AtlasMode.SIMPLE) {
        dispatch(setConfiguration(simpleConfiguration));
      } else {
        dispatch(setConfiguration(configuration));
      }

      if (deserializedData.secondary) {
        setMirroring(true);
      } else {
        setMirroring(false);
      }
      batch(async () => {
        // Update Store
        dispatch(setAtlasMode(_mode));
        dispatch(setSimpleMapView(simpleMapView));
        dispatch(setMapView(simpleMapView));
        dispatch(
          setSelectedOptions(newPrimaryMapSelectedOptions, 'primaryMap')
        );
        if (deserializedData.secondary) {
          dispatch(
            setSelectedOptions(deserializedData.secondary, 'secondaryMap')
          );
        }

        dispatch(setReferenceProjection(proj));

        dispatch(setHatching(primaryHatching, 'primaryMap'));
        dispatch(
          setHatching(
            deserializedData.secondary?.hatching || 'SIMPLE',
            'secondaryMap'
          )
        );
        dispatch(setRegionSet(primaryRegionSet as RegionSet, 'primaryMap'));
        dispatch(
          setRegionSet(
            deserializedData.secondary?.regionSet as RegionSet,
            'secondaryMap'
          )
        );

        let primaryGeoJson, secondaryGeoJson;
        // We need GeoJSON features to get selectedRegions from url
        const primaryGeoJsonToLoad = getRegionSetToLoad(
          primaryRegionSet as RegionSet,
          proj.code
        );
        let secondaryGeoJsonToLoad = primaryGeoJsonToLoad;
        if (
          deserializedData.secondary &&
          primaryRegionSet !== deserializedData.secondary?.regionSet
        ) {
          secondaryGeoJsonToLoad = getRegionSetToLoad(
            deserializedData.secondary.regionSet as RegionSet,
            proj.code
          );
          if (secondaryGeoJsonToLoad) {
            [primaryGeoJson, secondaryGeoJson] = await Promise.all([
              getRegionsGeoJSON(primaryGeoJsonToLoad as RegionSet),
              getRegionsGeoJSON(secondaryGeoJsonToLoad),
            ]);
          }
        } else {
          primaryGeoJson = await getRegionsGeoJSON(
            primaryGeoJsonToLoad as RegionSet
          );
          secondaryGeoJson = primaryGeoJson;
        }

        dispatch(
          setGeoJson(primaryGeoJson, primaryGeoJsonToLoad as AvailableGeojson)
        );
        if (deserializedData.secondary) {
          dispatch(
            setGeoJson(
              secondaryGeoJson,
              secondaryGeoJsonToLoad as AvailableGeojson
            )
          );
        }

        dispatch(
          setRegionsSelected(
            mapIdsToFeatures(primaryRegions, (primaryGeoJson || {}).features),
            'primaryMap'
          )
        );
        if (deserializedData.secondary) {
          dispatch(
            setRegionsSelected(
              mapIdsToFeatures(
                deserializedData.secondary.regions,
                (secondaryGeoJson || {}).features
              ),
              'secondaryMap'
            )
          );
        }

        setPlotState(plotState);
        if (plotState.showing) {
          setShowCharts(true);
        }
      });
    }
  };

  const onMetadataClick = async () => {
    if (metadataData) {
      setShowMetadata(true);
    } else {
      try {
        setMetadataLoading(true);
        const metadata = await getMetadata(
          primaryMapSelectedOptions as ControllerOptionsState,
          mapProjection,
          hatching.primaryMap // Only can be downloaded with one map
        );
        setMetadataData(metadata);
        setShowMetadata(true);
      } catch (err) {
        setMetadataData(undefined);
      } finally {
        setMetadataLoading(false);
      }
    }
  };

  const onInfoClick = useCallback(
    async (evt: MapBrowserPointerEvent) => {
      const prepareData = (data: any) =>
        data.map((i: any) => ({
          ...i,
          point: [lng, lat],
        }));

      const [lng, lat] = transform(
        evt.coordinate,
        mapProjection.epsg,
        'EPSG:4326'
      );
      try {
        if (mirroring) {
          const [primaryInfo, secondaryInfo] = await Promise.all([
            getFeatureInfo(
              primaryMapSelectedOptions as ControllerOptionsState,
              { lng, lat },
              'EPSG:4326',
              config!.properties.WMS_URL
            ),
            getFeatureInfo(
              secondaryMapSelectedOptions as ControllerOptionsState,
              { lng, lat },
              'EPSG:4326',
              config!.properties.WMS_URL
            ),
          ]);
          batch(() => {
            dispatch(
              setPointInfo(
                {
                  data: prepareData(primaryInfo),
                  selectedOptions: primaryMapSelectedOptions,
                  originalCoords: evt.coordinate,
                },
                'primaryMap'
              )
            );
            dispatch(
              setPointInfo(
                {
                  data: prepareData(secondaryInfo),
                  selectedOptions: secondaryMapSelectedOptions,
                  originalCoords: evt.coordinate,
                },
                'secondaryMap'
              )
            );
          });
        } else {
          const info = await getFeatureInfo(
            primaryMapSelectedOptions as ControllerOptionsState,
            { lng, lat },
            'EPSG:4326',
            config!.properties.WMS_URL
          );
          dispatch(
            setPointInfo(
              {
                data: prepareData(info),
                selectedOptions: primaryMapSelectedOptions,
                originalCoords: evt.coordinate,
              },
              'primaryMap'
            )
          );
        }
      } catch (err) {
        console.error(err);
        showErrorMessage();
        return null;
      }
    },
    [
      primaryMapSelectedOptions,
      secondaryMapSelectedOptions,
      mapProjection,
      mirroring,
      config,
      dispatch,
    ]
  );

  const showErrorMessage = async () => {
    try {
      const reviewCode = await getReviewCode();

      const url = `${window.location.origin}${Routes.Review}/${reviewCode}`;

      dispatch(
        addAlert({
          message: t('map:error.message', { code: url }),
          variant: 'error',
          title: t('map:error.title'),
          duration: 15000,
        })
      );
    } catch (err) {}
  };

  const onDownloadClick = async (format: 'png' | 'tiff' | 'netcdf') => {
    try {
      const map = (window as any).ipccMapInstance;
      const bbox = map.getView().calculateExtent(map.getSize());

      const mapCanvas = document.querySelector(
        '.map canvas'
      ) as HTMLCanvasElement;
      const r = await exportMap(
        primaryMapSelectedOptions as ControllerOptionsState,
        t,
        mapCanvas,
        bbox
      )[format]();
      assert(Boolean(r), 'Map Download Failed');
    } catch (err) {
      console.error(err);
      showErrorMessage();
    }
  };

  const onZoomChange = useCallback(
    (mode: 'zoom-in' | 'zoom-out') => {
      const setView = (zoom: number) => {
        mapViews.view.animate({
          zoom,
          duration: 100,
        });
        dispatch(setMapView(mapViews.view));
      };
      const actualZoom = mapViews.view.getZoom() || 0;
      if (mode === 'zoom-in') {
        const maxZoom = mapViews.view.getMaxZoom();
        if (actualZoom < maxZoom) {
          setView(actualZoom + 0.5);
        }
      } else {
        const minZoom = mapViews.view.getMinZoom();
        if (actualZoom > minZoom) {
          setView(actualZoom - 0.5);
        }
      }
    },
    [mapViews.view, dispatch]
  );

  const onMirroringClick = () => {
    if (mirroring) {
      // Eliminar de la url las referencias al segundo mapa.
      updateUrl(primaryMapSelectedOptions, 'primaryMap');
    }

    if (!mirroring) {
      dispatch(
        setSelectedOptions(
          primaryMapSelectedOptions as ControllerOptionsState,
          'secondaryMap'
        )
      );
      dispatch(setHatching(hatching.primaryMap, 'secondaryMap'));
    }
    setMirroring(!mirroring);
  };

  const onShareClick = async (
    brand: 'facebook' | 'twitter' | 'linkedin' | 'permalink'
  ) => {
    if (brand === 'permalink') {
      requestReviewCode();
    } else {
      const sharer = new ShareController();
      sharer
        .share({
          text: '',
          title: 'IPCC WGI Interactive Atlas',
          url: window.location.href,
        })
        [brand]()
        .then();
    }
  };

  const requestReviewCode = async () => {
    const code = await getReviewCode();
    setReviewCode(code);
  };

  const onRegionsSelectedChange = (
    regions: any[],
    mapIdentifier: MapIdentifier
  ): void => {
    dispatch(setRegionsSelected(regions, mapIdentifier));
  };

  const onChartContainerClose = useCallback(() => {
    setShowCharts(false);
    dispatch(setRegionsSelected([], 'primaryMap'));
  }, [setShowCharts, dispatch]);

  const onPlotStateChange = (plotState: IPCCUrlPlotParams) => {
    setPlotState(plotState);
  };

  return (
    <>
      <AtlasTour />
      <div className={cn('map__page', { mirroring }, { mobile: isMobile })}>
        <div className='primary-map__wrapper'>
          {shouldRenderMap && (
            <MapContainer
              primary
              mirroring={mirroring}
              config={config as MapConfiguration}
              mapView={mapViews.view}
              selectedOptions={
                primaryMapSelectedOptions as ControllerOptionsState
              }
              geoJson={
                geoJsons[
                  getRegionSetToLoad(
                    regionSetSelected.primaryMap,
                    mapProjection.code
                  )
                ]
              }
              mapProjection={mapProjection}
              regionSetSelected={regionSetSelected['primaryMap']}
              regionsSelected={regionsSelected.primaryMap}
              infoActive={infoActive}
              graticuleActive={graticuleActive}
              setRegionsSelected={(regions: any[]) =>
                onRegionsSelectedChange(regions, 'primaryMap')
              }
              onOptionsChange={(data) => onOptionsChange(data, 'primaryMap')}
              onRegionSetChange={onRegionSetChange}
              onInfoClick={onInfoClick}
            >
              {!isMobile && (
                <ZoomControl
                  onClick={onZoomChange}
                  cssClass={cn({
                    'min-disabled':
                      mapViews.view.getZoom() === mapViews.view.getMinZoom(),
                    'max-disabled':
                      (mapViews.view.getZoom() || 0) >=
                      mapViews.view.getMaxZoom(),
                  })}
                />
              )}
              <ProjectionControl
                onClick={() => {}}
                mirroring={mirroring}
                value={mapProjection.code}
                graticuleActive={graticuleActive}
                onChange={onReferenceProjectionChange}
                onGraticuleClick={() => setGraticuleActive(!graticuleActive)}
                cssClass='first-group-end projection-control'
                disabled={
                  ['HANT-44', 'ANT-44'].includes(
                    primaryMapSelectedOptions.dataset?.model.code || ''
                  )
                    ? ['EPSG:3413']
                    : []
                }
              />
              {!mirroring && (
                <MetadataControl
                  loading={metadataLoading}
                  disabled={metadataData === undefined}
                  cssClass={metadataData !== undefined ? '' : 'disabled'}
                  onClick={onMetadataClick}
                />
              )}
              {!mirroring && !isMobile && <div className='hide-on-collapse' />}
              {!mirroring && (
                <DownloadControl
                  onDownloadClick={onDownloadClick}
                  isOnCID={false}
                  cssClass='second-group-end'
                />
              )}
              {!mirroring && <ShareControl onShareClick={onShareClick} />}

              <InfoControl
                cssClass={infoActive ? 'active' : ''}
                onClick={() => setInfoActive(!infoActive)}
              />
              {!isMobile && (
                <>
                  <MirrorControl
                    mirroring={mirroring}
                    onClick={onMirroringClick}
                  />
                  <div className='map-controls-separators' />
                </>
              )}
            </MapContainer>
          )}
        </div>
        {mirroring && !isMobile && shouldRenderMap && (
          <div className='secondary-map__wrapper'>
            <MapContainer
              mirroring
              config={config as MapConfiguration}
              mapView={mapViews.view}
              selectedOptions={
                secondaryMapSelectedOptions as ControllerOptionsState
              }
              geoJson={
                geoJsons[
                  getRegionSetToLoad(
                    regionSetSelected.secondaryMap,
                    mapProjection.code
                  )
                ]
              }
              mapProjection={mapProjection}
              regionSetSelected={regionSetSelected['secondaryMap']}
              regionsSelected={regionsSelected.secondaryMap}
              infoActive={infoActive}
              graticuleActive={graticuleActive}
              setRegionsSelected={(regions: any[]) =>
                onRegionsSelectedChange(regions, 'secondaryMap')
              }
              onOptionsChange={(data) => onOptionsChange(data, 'secondaryMap')}
              onRegionSetChange={onRegionSetChange}
              onInfoClick={onInfoClick}
            />
          </div>
        )}
        {/* {!isMobile && (
          <PermalinkButton
            chartsOpen={showCharts}
            onClick={requestReviewCode}
          />
        )} */}
      </div>
      <ChartsContainerErrorBoundary>
        <Suspense fallback={<Loading show cssClass='map-page-loading' />}>
          <CSSTransition
            timeout={200}
            unmountOnExit
            classNames='resizable-container'
            in={showCharts && !mirroring}
          >
            <ChartsContainer
              regionsSelected={regionsSelected.primaryMap}
              selectedOptions={primaryMapSelectedOptions}
              config={config}
              regions={
                geoJsons[
                  getRegionSetToLoad(
                    regionSetSelected.primaryMap,
                    mapProjection.code
                  )
                ]
              }
              regionsConfig={selectedRegionSetConfiguration}
              regionSet={regionSetSelected.primaryMap}
              onClose={onChartContainerClose}
              onStateChange={onPlotStateChange}
              initialState={plotState}
            />
          </CSSTransition>
        </Suspense>
      </ChartsContainerErrorBoundary>
      <Suspense fallback={<Loading show cssClass='map-page-loading' />}>
        <CSSTransition
          timeout={300}
          classNames='metadata-container-transition'
          unmountOnExit
          in={showMetadata}
        >
          <MetadataContainer
            metadata={metadataData as Metadata[]}
            selectedOptions={
              primaryMapSelectedOptions as ControllerOptionsState
            }
            onClose={() => setShowMetadata(false)}
          />
        </CSSTransition>
      </Suspense>
      <CSSTransition
        in={reviewCode.length !== 0}
        unmountOnExit
        classNames='review-tooltip-transition'
        timeout={300}
      >
        <PermalinkTooltip code={reviewCode} onClose={() => setReviewCode('')} />
      </CSSTransition>
      <RegionalInformationMobileWarning />
    </>
  );
};

export const MapPage = () =>
  process.env.NODE_ENV !== 'development' ? (
    <MapPageErrorBoundaryRedirect>
      <MapPageWithoutErrorBoundary />
    </MapPageErrorBoundaryRedirect>
  ) : (
    <MapPageWithoutErrorBoundary />
  );
