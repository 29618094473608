import { Url } from 'simple-query-params';

import { ControllerOptionsState } from 'components/option-selector/options.model';
import { DeepNonNullable } from 'domain/models/shared.types';
import {
  HatchingType,
  MapConfiguration,
  ValueTypePrefixes,
} from 'domain/models/map-configuration.model';
import { AtlasMode, SimpleMapView } from 'store/map';
import { Serializer } from './url/serializer';
import { Deserializer } from './url/deserializer';
import {
  IPCCUrlParams,
  FullParams,
  IPCCUrlPlotParams,
  IPCCUrlParamsType,
} from 'domain/models/url.model';
import { WMSNames } from './url/commons';
import { Projections } from 'core/map.projections';
import { IPCCProjection } from 'domain/models/map.models';

export type UrlExtras = SimpleMapView & { proj: string; mode: AtlasMode };

export const serialize = (
  primaryMapConfig: DeepNonNullable<ControllerOptionsState> & {
    regions: any[];
    regionSet: string;
    hatching: HatchingType;
  },
  secondaryMapConfig: DeepNonNullable<ControllerOptionsState> & {
    regions: any[];
    regionSet: string;
    hatching: HatchingType;
  },
  extras: UrlExtras,
  plot: IPCCUrlPlotParams
): {
  raw: IPCCUrlParams;
  encoded: string;
} => {
  const serializer = new Serializer('ATLAS')
    .setCommonParams(extras)
    .setPrimaryMapParams(primaryMapConfig)
    .setPlotState(plot);

  if (secondaryMapConfig && secondaryMapConfig.dataset) {
    serializer.setSecondaryMapParams(secondaryMapConfig);
  }

  return serializer.getParams<IPCCUrlParams>();
};

export const deserialize = (
  hash: string,
  config: MapConfiguration
): {
  type: IPCCUrlParamsType;
  primary: FullParams;
  secondary?: FullParams;
  common: SimpleMapView & { proj: IPCCProjection; mode: AtlasMode };
  plot: IPCCUrlPlotParams;
} => {
  const deserializer = new Deserializer({ type: 'DATASET', hash, config });
  const primary = deserializer.getPrimaryMapParams();
  const secondary = deserializer.getSecondaryMapParams();
  const common = deserializer.getCommonMapParams();
  const plot = deserializer.getPlotParams();
  const type = deserializer.getParamsType();
  return {
    type,
    primary,
    secondary,
    common: {
      ...common,
      mode: common.mode ?? AtlasMode.COMPLETE,
      center: [common.lat, common.lng],
      proj: Projections[common.proj],
    },
    plot,
  };
};

export const serializeParamsToMapUrl = (
  {
    baseline,
    dataset,
    period,
    scenario,
    season,
    valueType,
    variable,
  }: DeepNonNullable<ControllerOptionsState>,
  extra: any = {},
  baseUrl: string
): Url => {
  let _baseUrl = baseUrl.endsWith('?') ? baseUrl : `${baseUrl}?`;
  const url = new Url(_baseUrl);

  const params = _serializeParams({
    baseline,
    dataset,
    period,
    scenario,
    season,
    valueType,
    variable,
  });

  Object.entries({ ...extra, ...params }).forEach(([K, v]: [string, any]) => {
    K && v && url.setValue(K, v);
  });

  return url;
};

export const getParam = (url: Url, name: string): any => {
  const _param = url.getParam(name);
  if (_param) {
    return _param.value;
  }
};

const _serializeParams = (
  options: DeepNonNullable<ControllerOptionsState>
): any => {
  return {
    [WMSNames.period]: options.period.wmsCode,
    [WMSNames.season]: options.season.code,
    dataset: `${options.dataset.model.code}-${options.scenario.code}`,
    layers: `${options.variable.code}${
      options.valueType ? ValueTypePrefixes[options.valueType] : ''
    }`,
    [WMSNames.baseline]: options.baseline?.code || '',
  };
};
