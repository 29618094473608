import { Url } from 'simple-query-params';
import { TFunction } from 'i18next';
import { saveAs } from 'file-saver';

import {
  AvailableGeojson,
  MapConfiguration,
} from 'domain/models/map-configuration.model';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import { serializeParamsToMapUrl } from 'utils/url-serializer';
import { replaceAll, toQueryParams } from 'utils/utils';
import { AppConstants, APP_BUILD_UUID, WmsParams } from 'core/constants';
import { selectedOptionsToReadableText } from 'utils/map.utils';
import { WMSKeeper } from 'utils/wms-url-keeper';
import { CIDConfiguration } from 'domain/models/cid.model';
import { AtlasMode } from 'store/map';
import { getI18n } from 'react-i18next';

export const getConfiguration = (
  mode: AtlasMode
): Promise<MapConfiguration> => {
  const endpoint =
    mode === AtlasMode.COMPLETE
      ? AppConstants.Api.endpoints.config
      : AppConstants.Api.endpoints.configSimple;

  return fetch(
    `${AppConstants.Api.baseUrl}${endpoint}?uuid=${APP_BUILD_UUID}`,
    {
      headers: {
        Authorization: AppConstants.Api.authorization.token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res: any) => res.json())
    .catch((err) => console.error(err));
};

export const getCdiConfiguration = (): Promise<CIDConfiguration> => {
  return fetch(`/data/cid-config.json?uuid=${APP_BUILD_UUID}`, {
    headers: {
      Authorization: AppConstants.Api.authorization.token,
      'Cache-Control': 'max-age=31536000',
    },
  }).then((res) => res.json());
};

export const getCoastlineGeoJSON = (): Promise<any> => {
  return fetch('/data/coastline.json', {
    headers: {
      Authorization: AppConstants.Api.authorization.token,
      'Cache-Control': 'max-age=31536000',
    },
  }).then((res) => res.json());
};

export const getFeatureInfo = (
  options: ControllerOptionsState,
  { lat, lng }: { lat: number; lng: number },
  crsCode: string,
  baseEndpoint: string
) => {
  const featureInfoUrl = `${AppConstants.Api.baseUrl}${baseEndpoint}?`;
  const dimPeriod = options.period.code;
  const dimTime = options.season.code;
  const queryParams = {
    x: lng,
    y: lat,
    CRS: crsCode,
    params: `&&DIM_period=${dimPeriod}&DIM_time_filter=${dimTime}`,
  };
  const url = serializeParamsToMapUrl(
    options,
    {},
    featureInfoUrl
  ).builtUrl.concat(
    `&${WmsParams.getFeatureInfoRequest}&`,
    toQueryParams(queryParams)
  );
  return fetch(url, {
    method: 'GET',

    headers: { Authorization: AppConstants.Api.authorization.token },
  }).then((res) => res.json());
};

export const getRegionsGeoJSON = (set: AvailableGeojson = 'ar6') => {
  const url =
    set === 'none'
      ? '/data/none.json'
      : `${
          AppConstants.Api.baseUrl
        }/region/${set.toLowerCase()}/features.json?uuid=${APP_BUILD_UUID}`;
  return fetch(url, {
    headers: {
      Authorization: AppConstants.Api.authorization.token,
      'Cache-Control': 'max-age=31536000',
    },
  })
    .then((res) =>
      res.json().then((geo: any) => {
        const { crs, ...d } = geo; // OpenLayers no se lleva bien con la propiedad CRS en los GeoJson
        return {
          ...d,
          features: d.features.map((f: any) => ({
            ...f,
            id: f.properties.id,
          })),
        };
      })
    )
    .catch((err) => null);
};

export const getReviewCode = (): Promise<string> => {
  const url =
    AppConstants.Api.baseUrl + AppConstants.Api.endpoints.savePermalink;
  const data = new FormData();
  data.append('config', window.location.hash);
  return fetch(url, {
    body: data,
    method: 'POST',
    headers: {
      Authorization: AppConstants.Api.authorization.token,
    },
  }).then((r: Response) => r.text());
};

export const exportMap = (
  options: ControllerOptionsState,
  t: TFunction,
  { width, height }: { width: number; height: number },
  newBBox: string
) => {
  const mapTitle = selectedOptionsToReadableText(options, t);
  // const _fileName = selectedOptionsToCodes(options);
  const _fileName = mapTitle?.title ?? '';

  const mapParams = new Url(WMSKeeper.getUrl());
  mapParams.setValue(
    'title',
    encodeURIComponent(mapTitle?.mapExportTitle || '')
  );
  mapParams.setValue('BBOX', newBBox);
  mapParams.setValue('HEIGHT', height);
  mapParams.setValue('WIDTH', width);
  mapParams.setValue('language', getI18n().language);

  const promise = (endpoint: string, fileName: string, mimeType: string) =>
    // Fetch no codifica bien el caracter %. Hay que hacerlo a mano, pero con cuidado,
    // % se usa para codificar los meta caracteres de las urls como %20 para el espacio.
    // Si usamos replaceAll(str, /%/, '%25') se bloqueará la web por que estamos sustituyendo
    // constantemente % por %25 y sigue encontrando % siempre. ¯\_(ツ)_/¯
    fetch(
      `${AppConstants.Api.baseUrl}${endpoint}?${replaceAll(
        mapParams.hash,
        /\(%\)/,
        '%25'
      )}`,
      {
        headers: { Authorization: AppConstants.Api.authorization.token },
      }
    )
      .then((res: Response) => res.blob())
      .then((blob: Blob) => {
        saveAs(new Blob([blob], { type: mimeType }), fileName);
        return true;
      })
      .catch((err) => {
        throw new Error(err);
      });

  return {
    png: () => {
      const fileNameWExt = !_fileName?.endsWith('.png')
        ? `${_fileName}.png`
        : _fileName;
      return promise(
        AppConstants.Api.endpoints.export.map.png,
        fileNameWExt || 'ipcc_map_export.png',
        'image/png'
      );
    },
    tiff: () => {
      const fileNameWExt = !_fileName?.endsWith('.zip')
        ? `${_fileName}.zip`
        : _fileName;
      return promise(
        AppConstants.Api.endpoints.export.map.tiff,
        fileNameWExt || 'ipcc_map_export.zip',
        'application/zip'
      );
    },
    netcdf: () => {
      const fileNameWExt = !_fileName?.endsWith('.zip')
        ? `${_fileName}.zip`
        : _fileName;
      return promise(
        AppConstants.Api.endpoints.export.map.netcdf,
        fileNameWExt || 'ipcc_map_export.zip',
        'application/zip'
      );
    },
  };
};

export const exportCidMap = ({
  width,
  height,
  bbox,
  cid,
  magnitude,
  crs,
  title,
}: {
  width: number;
  height: number;
  bbox: string;
  cid: string;
  magnitude: 'projections' | 'trends';
  crs: string;
  title: string;
}) => {
  const url = new Url(
    AppConstants.Api.baseUrl + AppConstants.Api.endpoints.export.map.cid.png
  );
  url
    .setValue('WIDTH', width)
    .setValue('HEIGHT', height)
    .setValue('CRS', crs)
    .setValue('BBOX', bbox)
    .setValue('cid', cid)
    .setValue('magnitude', magnitude)
    .setValue('title', title)
    .setValue('language', getI18n().language);

  return fetch(url.builtUrl)
    .then((res) => res.blob())
    .then((blob) => {
      saveAs(
        new Blob([blob], { type: 'image/png' }),
        `ipcc_cid_${cid}_${magnitude}.png`
      );
    });
};

export const getRegionsConfig = () =>
  fetch(`/data/region-config.json?uuid=${APP_BUILD_UUID}`, {
    headers: {
      Authorization: AppConstants.Api.authorization.token,
      'Content-Type': 'application/json',
    },
  })
    .then((res: any) => res.json())
    .catch((err) => console.error(err));
