import React from 'react';

import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';

import {
  ExpandableBaseControl,
  ExpandableChildControl,
} from './base/ExpandableBaseControl';

import {
  TIFFIcon,
  PNGIcon,
  NetCDFIcon,
  DownloadIcon,
  PDFIcon,
} from 'components/shared/icons';

import './MapControls.scss';

export const DownloadControl = ({
  onDownloadClick,
  isOnCID = false,
  cssClass,
}: {
  onDownloadClick: (brand: 'png' | 'tiff' | 'netcdf') => any;
  isOnCID: boolean;
  cssClass?: string;
}) => {
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation(['map']);

  return (
    <ExpandableBaseControl
      open={open}
      onClick={() => {
        setOpen(!open);
      }}
      cssClass={`download-control ${cssClass}`}
      title={t('map:controls.download.title')}
      tooltip={t('map:controls.download.tooltip')}
      icon={
        <DownloadIcon
          width='20px'
          height='20px'
          fill='white'
          style={{ zIndex: 1 }}
        />
      }
    >
      {!isOnCID && (
        <ExpandableChildControl
          onClick={() => {
            open && onDownloadClick('tiff');
          }}
          title={t('map:controls.download.tiff.title')}
          tooltip={
            !isMobile ? t('map:controls.download.tiff.tooltip') : undefined
          }
        >
          <TIFFIcon width='20px' height='20px' />
        </ExpandableChildControl>
      )}
      <ExpandableChildControl
        onClick={() => {
          open && onDownloadClick('png');
        }}
        title={t('map:controls.download.png.title')}
        tooltip={!isMobile ? t('map:controls.download.png.tooltip') : undefined}
      >
        <PNGIcon width='20px' height='20px' />
      </ExpandableChildControl>
      {!isOnCID && (
        <ExpandableChildControl
          onClick={() => {
            open && onDownloadClick('netcdf');
          }}
          title={t('map:controls.download.netcdf.title')}
          tooltip={
            !isMobile ? t('map:controls.download.netcdf.tooltip') : undefined
          }
        >
          <NetCDFIcon width='20px' height='20px' />
        </ExpandableChildControl>
      )}
    </ExpandableBaseControl>
  );
};

export const CIDDownloadControl = ({
  onDownloadClick,
  cssClass,
}: {
  onDownloadClick: (brand: 'png' | 'pdf') => any;
  cssClass?: string;
}) => {
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation(['map']);

  return (
    <ExpandableBaseControl
      open={open}
      onClick={() => {
        setOpen(!open);
      }}
      cssClass={`download-control ${cssClass}`}
      title={t('map:controls.download.title')}
      tooltip={t('map:controls.download.tooltip')}
      icon={
        <DownloadIcon
          width='20px'
          height='20px'
          fill='white'
          style={{ zIndex: 1 }}
        />
      }
    >
      <ExpandableChildControl
        onClick={() => {
          open && onDownloadClick('png');
        }}
        title={t('map:controls.download.png.title')}
        tooltip={!isMobile ? t('map:controls.download.png.tooltip') : undefined}
      >
        <PNGIcon width='20px' height='20px' />
      </ExpandableChildControl>
      <ExpandableChildControl
        onClick={() => {
          open && onDownloadClick('pdf');
        }}
        title={t('map:controls.download.png.title')}
        tooltip={!isMobile ? t('map:controls.download.png.tooltip') : undefined}
      >
        <PDFIcon width='20px' height='20px' />
      </ExpandableChildControl>
    </ExpandableBaseControl>
  );
};
