import React from 'react';

import { useTranslation } from 'react-i18next';

import { PlusIcon } from 'components/shared/icons';

import './Alert.scss';

type AlertVariant = 'success' | 'error' | 'warning' | 'info';

export interface AlertProps {
  title?: string;
  message: string;
  variant: AlertVariant;
  duration?: number;
  onDismiss?(): void;
}

export const Alert = ({
  title,
  message,
  variant = 'error',
  onDismiss,
}: AlertProps) => {
  const { t } = useTranslation('components');

  return (
    <div className={`ipcc-alert ${variant}`}>
      <div className='ipcc-alert__content'>
        {title && <div className='ipcc-alert__title'>{title}</div>}
        <div
          className='ipcc-alert__message'
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
      <PlusIcon
        onClick={() => onDismiss && onDismiss()}
        xlinkTitle={t('components:alert.close.title')}
        className={`close-icon ${variant}`}
      ></PlusIcon>
    </div>
  );
};

export const AlertContainer = (props: React.PropsWithChildren<{}>) => (
  <div className='ipcc-alert__container'>{props.children}</div>
);
