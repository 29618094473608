import { IPCCUrlPlotParams } from 'domain/models/url.model';

export const defaultPlotState: IPCCUrlPlotParams = {
  activeTab: 'plume',
  mask: 'none',
  scatterYMag: null,
  scatterYVar: null,
  showing: false
};

export class PlotStateController {
  static encode(state: IPCCUrlPlotParams | null): string {
    return btoa(JSON.stringify(state));
  }

  static decode(rawString: string): IPCCUrlPlotParams | null {
    try {
      return JSON.parse(atob(rawString)) as IPCCUrlPlotParams;
    } catch (err) {
      console.error(err);
      return defaultPlotState;
    }
  }
}
