import { getReviewCode } from 'services/map.service';
import { Routes } from 'core';

export class ShareController {
  // Investigar si al control nativo de compartir se le pueden pasar opciones personalizadas como el permalink
  // @ts-ignore
  static nativeAvailable = false; // !!navigator.share;

  public share({
    title,
    text,
    url,
  }: {
    title: string;
    text: string;
    url: string;
  }) {
    return ShareController.nativeAvailable
      ? this._nativeShare({ title, text, url })
      : this._fallbackShare({ title, text, url });
  }

  private _fallbackShare({
    title,
    text,
    url,
  }: {
    title: string;
    text: string;
    url: string;
  }) {
    return {
      facebook: () =>
        new Promise((res) => {
          const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            url
          )}`;
          window.open(fbUrl, '_blank');
          res(null);
        }),
      twitter: () =>
        new Promise((res) => {
          getReviewCode().then((permalink: string) => {
            const twUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
              `${window.location.host}${Routes.Review}/${permalink}`
            )}`;
            window.open(twUrl, '_blank');
          });
        }),
      linkedin: () =>
        new Promise((res) => {
          const lkUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
          window.open(lkUrl, '_blank');
        }),
    };
  }

  private _nativeShare({
    title,
    text,
    url,
  }: {
    title: string;
    text: string;
    url: string;
  }) {
    // @ts-ignore
    const share = () => navigator.share({ title, text, url });
    return {
      facebook: share,
      twitter: share,
      linkedin: share
    };
  }
}
