import {
  MapConfiguration,
  MapConfigurationPeriod,
  MapConfigurationTempFilter,
  MapConfigurationScenario,
  MapConfigurationModel,
  MapConfigurationVariable,
  MapConfigurationBaseline,
} from 'domain/models/map-configuration.model';
import { getBy } from 'utils/utils';
import {
  FullParams,
  IPCCUrlParams,
  IPCCUrlMapParams,
  IPCCUrlPlotParams,
  IPCCUrlCommonParams,
  IPCCUrlParamsType,
  IPCCUrlCIDParams,
} from 'domain/models/url.model';
import { defaultPlotState } from 'utils/plots/plot-state.controller';
import { CIDMode, CIDVariable } from 'domain/models/cid.model';

type DeserializerCtorArgs =
  | {
      hash: string;
      type: 'CID';
    }
  | {
      hash: string;
      type: 'DATASET';
      config: MapConfiguration;
    };

export class Deserializer<T extends IPCCUrlParams | IPCCUrlCIDParams> {
  private _deserializedParams: T;
  private _config: MapConfiguration | null;

  constructor(args: DeserializerCtorArgs) {
    this._deserializedParams = this._deserialize(args.hash.replace('#', ''));
    this._config = args.type === 'DATASET' ? args.config : null;
  }

  getPrimaryMapParams(): FullParams {
    return this._getMapParams(
      (this._deserializedParams as IPCCUrlParams).primary
    );
  }

  getSecondaryMapParams(): FullParams | undefined {
    return (this._deserializedParams as IPCCUrlParams).secondary
      ? this._getMapParams(
          (this._deserializedParams as IPCCUrlParams).secondary as any
        )
      : undefined;
  }

  getParamsType(): IPCCUrlParamsType {
    return this._deserializedParams.type;
  }

  getCommonMapParams(): IPCCUrlCommonParams {
    return this._deserializedParams.commons;
  }

  getPlotParams(): IPCCUrlPlotParams {
    return (this._deserializedParams as IPCCUrlParams).plot || defaultPlotState;
  }

  getCIDSelectedIndex(): string | string[] {
    return (this._deserializedParams as IPCCUrlCIDParams).selectedIndex;
  }

  getCIDSelectedVariable(): CIDVariable {
    return (this._deserializedParams as IPCCUrlCIDParams).selectedVariable;
  }

  getCidMode(): CIDMode {
    return (this._deserializedParams as IPCCUrlCIDParams).mode;
  }

  getCidSelectedCountry(): string {
    return (this._deserializedParams as IPCCUrlCIDParams).selectedCountry;
  }

  private _deserialize(hash: string): T {
    try {
      const decoded = atob(hash);
      return JSON.parse(decoded) as T;
    } catch (err) {
      throw new Error('Unable to decode url hash. Reason: ' + err.message);
    }
  }

  private _getMapParams(params: IPCCUrlMapParams): FullParams {
    if (!this._config) {
      throw new Error();
    }
    return {
      period: getBy(
        'wmsCode',
        params.period,
        this._config.periods
      ) as MapConfigurationPeriod,
      season: getBy(
        'code',
        params.season,
        this._config.temporalFilters
      ) as MapConfigurationTempFilter,
      scenario: getBy(
        'code',
        params.scenario,
        this._config.scenarios
      ) as MapConfigurationScenario,
      dataset: getBy(
        ['model', 'code'],
        params.dataset,
        this._config.models
      ) as MapConfigurationModel,
      variable: getBy(
        'code',
        params.variable,
        this._config.variables
      ) as MapConfigurationVariable,
      valueType: params.valueType,
      baseline: getBy(
        'code',
        params.baseline,
        this._config.baselines
      ) as MapConfigurationBaseline,
      regionSet: params.regionSet,
      regions: params.regionsSelected
        ? Array.isArray(params.regionsSelected)
          ? params.regionsSelected
          : [params.regionsSelected]
        : [],
      hatching: params.hatching || 'SIMPLE',
    };
  }
}
