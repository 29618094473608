import { createActionCreator, createReducer } from 'deox';
import { AlertProps } from 'components/alert/Alert';
import { getShowTour, setShowTour } from 'utils/tour.utils';

function* id() {
  let index = 0;
  while (true) {
    yield index++;
  }
}

const idGenerator = id();

export interface CoreStore {
  alerts: Array<AlertProps & { id: number }>;
  showTour: {
    atlas: boolean;
    cid: boolean;
    charts: boolean;
  };
}

const initialState: CoreStore = {
  alerts: [],
  showTour: {
    atlas: getShowTour('atlas'),
    cid: getShowTour('cid'),
    charts: getShowTour('charts')
  },
};

const ADD_ALERT = '[Core] :: Add alert';
const REMOVE_ALERT = '[Core] :: Remove alert';
const POP_ALERT = '[Core] :: Pop alert';
const SHOW_TOUR = '[Core] :: Show tour';
const HIDE_TOUR = '[Core] :: Hide tour';

export const addAlert = createActionCreator(
  ADD_ALERT,
  (resolve) => (props: AlertProps) => resolve(props)
);

export const removeAlert = createActionCreator(
  REMOVE_ALERT,
  (resolve) => (id: number) => resolve(id)
);

export const popAlert = createActionCreator(POP_ALERT, (resolve) => () =>
  resolve()
);

export const showTour = createActionCreator(
  SHOW_TOUR,
  (resolve) => (page: 'atlas' | 'cid' | 'charts') => resolve(page)
);

export const hideTour = createActionCreator(
  HIDE_TOUR,
  (resolve) => (page: 'atlas' | 'cid' | 'charts') => resolve(page)
);

export const coreReducer = createReducer(initialState, (handle) => [
  handle(addAlert, (state: CoreStore, { payload }) => ({
    ...state,
    alerts: [
      ...state.alerts,
      {
        ...payload,
        id: idGenerator.next().value as number,
      },
    ],
  })),
  handle(removeAlert, (state: CoreStore, { payload }) => ({
    ...state,
    alerts: state.alerts.filter((a) => a.id !== payload),
  })),
  handle(popAlert, (state: CoreStore) => ({
    ...state,
    alerts: state.alerts.slice(0, state.alerts.length - 1),
  })),
  handle(showTour, (state: CoreStore, { payload }) => {
    setShowTour(payload, true)
    return {
      ...state,
      showTour: {
        ...state.showTour,
        [payload]: true,
      },
    };
  }),
  handle(hideTour, (state: CoreStore, { payload }) => {
    setShowTour(payload, false)
    return {
      ...state,
      showTour: {
        ...state.showTour,
        [payload]: false,
      },
    };
  }),
]);
