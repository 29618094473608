import {
  ControllerOptionsData,
  NullableControllerOptionsState,
  SectionsDiff,
} from './options.model';
import { getBy, head } from 'utils/utils';
import {
  MapConfiguration,
  MapConfigurationVariable,
  MapConfigurationScenario,
  MapConfigurationModel,
  ValueType,
  MapConfigurationTempFilter,
  MapConfigurationPeriod,
  MapConfigurationBaseline,
  ModelBaselines,
} from 'domain/models/map-configuration.model';
import { Nullable } from 'domain/models/shared.types';

const scenariosRelations = {
  rcp85: 'ssp585',
  rcp45: 'ssp245',
  rcp26: 'ssp126',
  ssp585: 'rcp85',
  ssp245: 'rcp45',
  ssp126: 'rcp26',
};

export const fixedSeasons = [
  { code: 'year', order: 1 },
  { code: 'DecFeb', order: 2 },
  { code: 'MarMay', order: 3 },
  { code: 'JunAug', order: 4 },
  { code: 'SepNov', order: 5 },
];

const getScenarioRelation = (
  scenarioCode: string,
  config: MapConfiguration
): MapConfigurationScenario | undefined => {
  const relation = (scenariosRelations as any)[scenarioCode];
  if (relation) {
    return config.scenarios.find(
      (sc: MapConfigurationScenario) => sc.code === relation
    );
  }
};

const getFixedSeason = (seasonCode: string) =>
  fixedSeasons.find((fs) => fs.code === seasonCode);

export const getNextData = (
  state: NullableControllerOptionsState,
  config: MapConfiguration
): {
  nextData: ControllerOptionsData;
  nextState: NullableControllerOptionsState;
} => {
  const configDataset = config.models.find(
    (m) => m.model.code === state.dataset?.model.code
  );

  const defaultDataSet =
    configDataset ?? (head(config.models) as MapConfigurationModel);

  const variables = getFilteredVariables(defaultDataSet, config);
  const defaultVariable = getDefaultVariable(
    state,
    variables,
    defaultDataSet.variables,
    config
  ) as MapConfigurationVariable;

  const scenarios = getFilteredScenarios(
    defaultDataSet,
    config,
    defaultVariable.scenarios
  );
  const defaultScenario = getDefaultScenario(state, scenarios, config);
  const inModelScenario =
    defaultDataSet.scenarios[defaultScenario?.code as any];

  const magnitudes = getFilteredValueTypes(defaultVariable, inModelScenario);
  const defaultMagnitude = getDefaultMagnitude(state, magnitudes);

  const baselines = getFilteredBaselines(
    config.baselines,
    defaultDataSet.baselines,
    defaultScenario,
    defaultVariable
  );

  const defaultBaseline = getDefaultBaseline(
    state.baseline,
    baselines,
    config.baselines
  );

  const periods = getFilteredPeriods(
    defaultDataSet.periods[(defaultScenario?.code as any) || ''],
    config.periods,
    defaultVariable
  );

  const defaultPeriod = getDefaultPeriod(state, periods);

  const seasons = getFilteredSeasons(defaultVariable, config);
  const defaultSeason = getDefaultSeason(state, seasons, config);

  return {
    nextData: {
      magnitudes,
      models: sortModels(config.models),
      periods: periods,
      scenarios: sort(scenarios),
      temporalFilters: seasons,
      variables: variables,
      baselines: sort(baselines),
    },
    nextState: {
      baseline: defaultBaseline,
      dataset: defaultDataSet,
      valueType: defaultMagnitude,
      period: defaultPeriod,
      scenario: defaultScenario,
      season: defaultSeason,
      variable: defaultVariable,
    },
  };
};

// #region - Variables

const getFilteredVariables = (
  dataset: MapConfigurationModel,
  config: MapConfiguration
) => config.variables;
// @see https://gitlab.predictia.es/ipcc/data-preparation-scripts/-/issues/116#note_7883
/* 
  dataset
    ? sort(config.variables.filter((v) => dataset.variables.includes(v.code)))
    : sort(config.variables);
  */

const getDefaultVariable = (
  state: NullableControllerOptionsState,
  filteredVariables: MapConfigurationVariable[],
  datasetVars: string[],
  config: MapConfiguration
): Nullable<MapConfigurationVariable> => {
  const datasetFilteredVars = filteredVariables.filter((v) =>
    datasetVars.includes(v.code)
  );
  if (state.variable) {
    const found = getBy('code', state.variable.code, datasetFilteredVars);
    if (found) {
      const newConfigVar = config.variables.find((v) => v.code === found.code);
      if (newConfigVar) {
        return newConfigVar;
      }
    }
  }
  return head(datasetFilteredVars);
};

// #endregion

// #region - Value Types

// #endregion

// #region - Scenarios

const getFilteredScenarios = (
  dataset: MapConfigurationModel,
  config: MapConfiguration,
  scenariosOnVariables: string[]
): MapConfigurationScenario[] => {
  const filteredScenariosK = Object.keys(dataset.scenarios).filter(
    (code: string) => scenariosOnVariables.includes(code)
  );
  return sort(
    config.scenarios.filter((scenario: MapConfigurationScenario) => {
      return filteredScenariosK.includes(scenario.code);
    })
  );
};

const getDefaultScenario = (
  state: NullableControllerOptionsState,
  scenarios: MapConfigurationScenario[],
  config: MapConfiguration
): Nullable<MapConfigurationScenario> => {
  if (state.scenario) {
    const found = getBy('code', state.scenario.code, scenarios);
    if (found) {
      if (!found.disabled) {
        const newConfigScenario = getBy('code', found.code, config.scenarios);
        if (newConfigScenario) {
          return newConfigScenario;
        }
      } else {
        return getBy('disabled', false, scenarios);
      }
    } else {
      const relation = getScenarioRelation(state.scenario.code, config);
      if (relation && getBy('code', relation.code, scenarios)) {
        return relation;
      }
    }
  }
  return head(scenarios);
};

// #endregion

// #region - Magnitudes

const getFilteredValueTypes = (
  variable: MapConfigurationVariable,
  scenarioTypes: ValueType[]
): ValueType[] => {
  return variable.valueType.filter((v: ValueType) => scenarioTypes.includes(v));
};

const getDefaultMagnitude = (
  state: NullableControllerOptionsState,
  magnitudes: ValueType[]
): Nullable<ValueType> => {
  if (state.valueType) {
    if (magnitudes.includes(state.valueType)) {
      return state.valueType;
    }
  }
  return head(magnitudes);
};

// #endregion

// #region - Seasons

const getFilteredSeasons = (
  variable: MapConfigurationVariable,
  config: MapConfiguration
): MapConfigurationTempFilter[] => {
  const yearFilter = getBy('code', 'year', config.temporalFilters);
  if (!variable.temporalFilter.includes('ALL')) {
    return yearFilter ? [yearFilter] : [];
  } else {
    return config.temporalFilters
      .filter((tf: MapConfigurationTempFilter) => getFixedSeason(tf.code))
      .sort(
        (tf1: MapConfigurationTempFilter, tf2: MapConfigurationTempFilter) => {
          const fixed1 = getFixedSeason(tf1.code);
          const fixed2 = getFixedSeason(tf2.code);
          if (fixed1 && fixed2) {
            return fixed1.order - fixed2.order;
          }
          return 0;
        }
      );
  }
};

const getDefaultSeason = (
  state: NullableControllerOptionsState,
  fixedSeasons: MapConfigurationTempFilter[],
  config: MapConfiguration
): Nullable<MapConfigurationTempFilter> => {
  if (state.variable) {
    if (state.variable.temporalFilter.includes('ALL')) {
      const configSeason = getBy(
        'code',
        state.season?.code,
        config.temporalFilters
      );
      if (configSeason) {
        return configSeason;
      }
    }
  }
  return head(fixedSeasons);
};

// #endregion

// #region - Baselines

const getFilteredBaselines = (
  configBaselines: MapConfigurationBaseline[],
  datasetBaselines: ModelBaselines,
  selectedScenario: Nullable<MapConfigurationScenario>,
  selectedVariable: MapConfigurationVariable
): MapConfigurationBaseline[] => {
  const scenarioBaselines = selectedScenario
    ? datasetBaselines[selectedScenario.code] ?? {}
    : {};
  const variableBaselines = scenarioBaselines[selectedVariable.code] ?? [];

  return configBaselines.filter((bs) => variableBaselines.includes(bs.code));
};

const getDefaultBaseline = (
  baseline: Nullable<MapConfigurationBaseline>,
  datasetBaselines: MapConfigurationBaseline[],
  configBaselines: MapConfigurationBaseline[]
): Nullable<MapConfigurationBaseline> => {
  const getConfigBaseline = (baselineCode: string) =>
    configBaselines.find((b) => b.code === baselineCode);
  if (datasetBaselines.find((bs) => bs.code === baseline?.code)) {
    return getConfigBaseline(baseline?.code || '') || baseline;
  } else {
    const firstBaseline = head(datasetBaselines);
    return getConfigBaseline(firstBaseline?.code || '') || baseline;
  }
};

// #endregion

// #region - Periods

// TODO - Sort
const getFilteredPeriods = (
  datasetPeriods: { [Variable: string]: string[] },
  periods: MapConfigurationPeriod[],
  selectedVariable: MapConfigurationVariable
): MapConfigurationPeriod[] => {
  const datasetVarPeriods = datasetPeriods[selectedVariable.code] ?? {};
  return periods.filter((p: MapConfigurationPeriod) => {
    const inDataset = (datasetVarPeriods || []).includes(p.code);
    return inDataset;
  });
};

const getDefaultPeriod = (
  state: NullableControllerOptionsState,
  periods: MapConfigurationPeriod[]
): Nullable<MapConfigurationPeriod> => {
  if (state.period) {
    const found = periods.find(
      (per: MapConfigurationPeriod) => per.code === state.period!.code
    );
    if (found) {
      return found;
    }
  }
  return head(periods);
};

// #endregion

export const sort = <T extends { weight: number }>(list: T[]): T[] => {
  return list.sort((e1: T, e2: T) => {
    if (e1.weight && e2.weight) {
      return Number(e1.weight) - Number(e2.weight);
    } else if (e1.weight) {
      return -1;
    } else if (e2.weight) {
      return 1;
    }
    return 0;
  });
};

const sortModels = (models: MapConfigurationModel[]) =>
  models.sort((e1: MapConfigurationModel, e2: MapConfigurationModel) => {
    if (e1.model.weight && e2.model.weight) {
      return Number(e1.model.weight) - Number(e2.model.weight);
    } else if (e1.model.weight) {
      return -1;
    } else if (e2.model.weight) {
      return 1;
    }
    return 0;
  });

export const getSectionsDiff = (
  prevState: NullableControllerOptionsState,
  nextState: NullableControllerOptionsState,
  changedKey?: keyof NullableControllerOptionsState
): SectionsDiff => {
  let dataset = false,
    variable = false,
    period = false,
    season = false;

  // Used changedKey to prevent blink on those options that was changed manually by the user

  if (changedKey !== 'dataset') {
    if (prevState.dataset?.model.code !== nextState.dataset?.model.code) {
      dataset = true;
    }
  }

  if (changedKey !== 'variable') {
    if (prevState.variable?.code !== nextState.variable?.code) {
      variable = true;
    }
  }

  if (changedKey !== 'valueType') {
    if (prevState.valueType !== nextState.valueType) {
      period = true;
    }
  }

  if (changedKey !== 'period' && !period) {
    if (prevState.period?.code !== nextState.period?.code) {
      period = true;
    }
  }

  if (changedKey !== 'scenario' && !period) {
    if (prevState.scenario?.code !== nextState.scenario?.code) {
      period = true;
    }
  }

  if (changedKey !== 'baseline' && !period) {
    if (prevState.baseline?.code !== nextState.baseline?.code) {
      period = true;
    }
  }

  if (changedKey !== 'season') {
    if (prevState.season?.code !== nextState.season?.code) {
      season = true;
    }
  }

  return {
    dataset,
    variable,
    period,
    season,
  };
};

export const shouldResetLegendConfig = (
  changedOption?: keyof NullableControllerOptionsState
): boolean => {
  return (
    changedOption === 'dataset' ||
    changedOption === 'valueType' ||
    changedOption === 'variable'
  );
};
