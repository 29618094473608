import React from 'react';

import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';

import {
  ExpandableBaseControl,
  ExpandableChildControl,
} from './base/ExpandableBaseControl';

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  LinkIcon,
  ShareIcon,
} from 'components/shared/icons';

import './MapControls.scss';

export const ShareControl = ({
  onShareClick,
  cssClass,
}: {
  onShareClick: (
    brand: 'facebook' | 'twitter' | 'linkedin' | 'permalink'
  ) => any;
  cssClass?: string;
}) => {
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation(['map']);

  return (
    <ExpandableBaseControl
      open={open}
      onClick={() => {
        setOpen(!open);
      }}
      cssClass={`share-control ${cssClass}`}
      title={t('map:controls.share.title')}
      tooltip={t('map:controls.share.tooltip')}
      icon={
        <ShareIcon
          width='20px'
          height='20px'
          fill='white'
          style={{ zIndex: 1 }}
        />
      }
    >
      <ExpandableChildControl
        cssClass={`share-icon facebook ${open ? 'open' : 'close'}`}
        title={t('map:controls.share.facebook.title')}
        tooltip={
          !isMobile ? t('map:controls.share.facebook.tooltip') : undefined
        }
        onClick={() => open && onShareClick('facebook')}
      >
        <FacebookIcon width='20px' height='20px' />
      </ExpandableChildControl>
      <ExpandableChildControl
        cssClass={`share-icon twitter ${open ? 'open' : 'close'}`}
        title={t('map:controls.share.twitter.title')}
        tooltip={
          !isMobile ? t('map:controls.share.twitter.tooltip') : undefined
        }
        onClick={() => open && onShareClick('twitter')}
      >
        <TwitterIcon width='20px' height='20px' />
      </ExpandableChildControl>
      <ExpandableChildControl
        cssClass={`share-icon linkedin ${open ? 'open' : 'close'}`}
        title={t('map:controls.share.linkedin.title')}
        tooltip={
          !isMobile ? t('map:controls.share.linkedin.tooltip') : undefined
        }
        onClick={() => open && onShareClick('linkedin')}
      >
        <LinkedinIcon width='20px' height='20px' />
      </ExpandableChildControl>
      <ExpandableChildControl
        cssClass={`share-icon permalink ${open ? 'open' : 'close'}`}
        title={t('map:controls.share.permalink.title')}
        tooltip={
          !isMobile ? t('map:controls.share.permalink.tooltip') : undefined
        }
        onClick={() => open && onShareClick('permalink')}
      >
        <LinkIcon width='20px' height='20px' />
      </ExpandableChildControl>
    </ExpandableBaseControl>
  );
};
