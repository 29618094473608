import React from 'react';

import { useTranslation } from 'react-i18next';

import { PlusIcon } from '../icons';

import './Tooltip.scss';

interface TooltipProps {
  top: string;
  left: string;
  backdrop?: boolean;
  className?: string;
  onClose(): void;
}

export const Tooltip = (props: React.PropsWithChildren<TooltipProps>) => {
  const { t } = useTranslation('components');

  React.useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        document.removeEventListener('keydown', handler);
        props.onClose();
      }
    };

    document.addEventListener('keydown', handler, { passive: true });

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, []);

  const tp = (
    <div
      role='dialog'
      className={`ipcc-tooltip ${props.className || ''}`}
      style={{ top: props.top, left: props.left }}
    >
      <i className='close-icon' title={t('components:permalink.close.title')}>
        <PlusIcon
          aria-label={t('components:permalink.close.title')}
          tabIndex={0}
          className='close-icon'
          width='20px'
          height='20px'
          onClick={props.onClose}
        ></PlusIcon>
      </i>
      {props.children}
    </div>
  );
  return props.backdrop ? (
    <div className='ipcc-tooltip__backdrop'>{tp}</div>
  ) : (
    tp
  );
};
