import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { IPCCProjections } from 'domain/models/map.models';

export const ProjectionsNames: { [K: string]: keyof IPCCProjections } = {
  EPSG_54030: 'EPSG:54030',
  EPSG_54030_Pacific: 'EPSG:54030pc',
  EPSG_3413: 'EPSG:3413',
  EPSG_3412: 'EPSG:3412',
};

export const Projections: IPCCProjections = {
  'EPSG:54030': {
    code: 'EPSG:54030',
    epsg: 'EPSG:54030',
    proj4: '+proj=robin +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs',
    zoom: 2,
    center_lat: 0,
    center_long: 0,
    label: 'Robinson',
  },
  'EPSG:54030pc': {
    code: 'EPSG:54030pc',
    epsg: 'EPSG:54030',
    proj4:
      '+proj=robin +lon_0=-150 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
    zoom: 2,
    center_lat: 0,
    center_long: -150,
    extent: [-19000000, -9000000, 19000000, 9000000],
    label: 'Robinson (Pacific-centric)',
  },
  'EPSG:3413': {
    code: 'EPSG:3413',
    epsg: 'EPSG:3413',
    proj4:
      '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs',
    zoom: 3,
    center_lat: 0,
    center_long: 0,
    label: 'Stereographic (north)',
  },
  'EPSG:3412': {
    code: 'EPSG:3412',
    epsg: 'EPSG:3412',
    proj4:
      '+proj=stere +lat_0=-90 +lat_ts=-70 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +a=6378273 +b=6356889.449 +units=m +no_defs',
    zoom: 3,
    center_lat: 0,
    center_long: 0,
    label: 'Stereographic (south)',
  },
};

export const registerProjection = (projName: keyof IPCCProjections) => {
  proj4.defs(projName, Projections[projName].proj4);
  register(proj4);
};
