import React from 'react';
import { useTranslation } from 'react-i18next';

import './AnimatedMenuButton.scss';

export const AnimatedMenuButton = ({
  open,
  onClick,
  cssClass,
}: {
  open: boolean;
  onClick(): void;
  cssClass?: string;
}) => {
  const { t } = useTranslation('components');

  return (
    <div
      aria-label={t('components:header.mobileMenuButton.aria.label')}
      tabIndex={0}
      className={`ipcc-menu-button ${open ? 'opened' : ''} ${cssClass || ''}`}
      onClick={() => {
        onClick();
      }}
    >
      <div className='stick stick-1' />
      <div className='stick stick-2' />
      <div className='stick stick-3' />
    </div>
  );
};
