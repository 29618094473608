import React from 'react';

import { useTranslation } from 'react-i18next';

interface BasicStepProps {
  className?: string;
  onNextClick?: VoidFunction;
  onPrevClick?: VoidFunction;
  onSkipClick?: VoidFunction;
}
export const BasicStep = ({
  className = '',
  ...props
}: React.PropsWithChildren<BasicStepProps>) => {
  const { t } = useTranslation('components');
  return (
    <div className={`ipcc-basic-tour-step ${className}`}>
      <div className='basic-tour-step-body'>{props.children}</div>
      <div className='basic-tour-buttons-container'>
        {props.onSkipClick ? (
          <button
            className='basic-tour-button skip-button'
            onClick={props.onSkipClick}
            aria-label={t('components:tour.buttons.skip.aria.label')}
            tabIndex={0}
          >
            {t('components:tour.buttons.skip.label')}
          </button>
        ) : props.onPrevClick ? null : (
          <div />
        )}
        {props.onPrevClick ? (
          <button
            className='basic-tour-button prev-button'
            onClick={props.onPrevClick}
            aria-label={t('components:tour.buttons.previous.aria.label')}
            tabIndex={0}
          >
            {t('components:tour.buttons.previous.label')}
          </button>
        ) : (
          <div />
        )}
        {props.onNextClick && (
          <button
            className='basic-tour-button next-button'
            onClick={props.onNextClick}
            aria-label={t('components:tour.buttons.next.aria.label')}
            tabIndex={0}
          >
            {t('components:tour.buttons.next.label')}
          </button>
        )}
      </div>
    </div>
  );
};

interface InitialStepProps extends BasicStepProps {
  title: string | React.ReactElement;
}
export const InitialStep = ({
  children,
  title,
  ...rest
}: React.PropsWithChildren<InitialStepProps>) => {
  return (
    <BasicStep
      {...rest}
      className={`ipcc-initial-step ${rest.className || ''}`}
    >
      <h1 className='ipcc-tour-step-title'>{title}</h1>
      {children}
    </BasicStep>
  );
};
