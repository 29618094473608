import React from 'react';

import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { HatchingType, ValueType } from 'domain/models/map-configuration.model';

import './HatchingSegments.scss';

export const HatchingSegments = ({
  hatching,
  valueType,
  side,
}: {
  hatching: HatchingType;
  valueType: ValueType;
  side: 'left' | 'right';
}) => {
  const { t } = useTranslation(['components']);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const AdvancedHatching = () => {
    return (
      <>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.COMPREHENSIVE.types.robust.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          C
        </div>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.COMPREHENSIVE.types.noRobust.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          {BasicPatternRotated}
        </div>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.COMPREHENSIVE.types.conflicting.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          {CrossPattern}
        </div>
      </>
    );
  };

  const SimpleHatching = () => {
    return (
      <>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.SIMPLE.types.highAgreement.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          C
        </div>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.SIMPLE.types.lowAgreement.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          {BasicPattern}
        </div>
      </>
    );
  };

  const SimpleTrendHatching = () => {
    return (
      <>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.SIGNIFICANCE.types.significant.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          C
        </div>
        <div
          className='hatching-segment'
          tabIndex={0}
          data-tip={t(
            'components:legend.hatching.SIGNIFICANCE.types.notSignificant.tooltip'
          )}
          data-for={`legend-hatching-tooltip-${side}`}
          style={{ backgroundImage: 'url(#hatching-pattern)' }}
        >
          {CrossPattern}
        </div>
      </>
    );
  };

  const Hatching = React.useMemo(() => {
    if (valueType === 'TREND') {
      if (hatching === 'SIMPLE') {
        return <SimpleTrendHatching />;
      } else {
        return null;
      }
    } else {
      if (hatching === 'SIMPLE') {
        return <SimpleHatching />;
      } else if (hatching === 'COMPREHENSIVE') {
        return <AdvancedHatching />;
      } else {
        return null;
      }
    }
  }, [valueType, hatching]);

  const className = React.useMemo(
    () =>
      hatching === 'DISABLED'
        ? 'hide'
        : hatching === 'SIMPLE'
        ? 'small'
        : hatching === 'COMPREHENSIVE'
        ? 'big'
        : 'hide',
    [hatching]
  );

  return (
    <div className={`hatching-segments-container ${className}`}>
      <ReactTooltip
        id={`legend-hatching-tooltip-${side}`}
        effect='solid'
        place={side}
        className='tooltip'
        event='focus'
      />
      {Hatching}
    </div>
  );
};

const BasicPattern = (
  <svg width={20} height={20}>
    <defs>
      <pattern
        id='basic-pattern'
        patternUnits='userSpaceOnUse'
        width='8'
        height='8'
      >
        <path
          d='M-1,1 l4,-4M0,8 l8,-8M6,10 l4,-4'
          style={{ stroke: 'grey', strokeWidth: 1 }}
        />
      </pattern>
    </defs>
    <rect x='0' y='0' width='100%' height='100%' fill='url(#basic-pattern)' />
  </svg>
);

const BasicPatternRotated = (
  <svg width={20} height={20}>
    <defs>
      <pattern
        id='basic-pattern-rotated'
        patternUnits='userSpaceOnUse'
        width='8'
        height='8'
      >
        <path
          d='M-1,1 l4,-4M0,8 l8,-8M6,10 l4,-4'
          style={{ stroke: 'grey', strokeWidth: 1 }}
        />
      </pattern>
    </defs>
    <rect
      style={{ transformOrigin: 'center center', transform: 'rotate(90deg)' }}
      x='0'
      y='0'
      width='100%'
      height='100%'
      fill='url(#basic-pattern-rotated)'
    />
  </svg>
);

const CrossPattern = (
  <svg width={20} height={20}>
    <defs>
      <pattern
        id='conflict-pattern-1'
        width='8'
        height='8'
        patternUnits='userSpaceOnUse'
      >
        <line x1='0' x2='8' y1='0' y2='8' stroke='grey' strokeWidth='1' />
      </pattern>
      <pattern
        id='conflict-pattern-2'
        width='8'
        height='8'
        patternUnits='userSpaceOnUse'
      >
        <line x1='8' x2='0' y1='0' y2='8' stroke='grey' strokeWidth='1' />
      </pattern>
    </defs>
    <rect width='100%' height='100%' fill={`url(#conflict-pattern-1)`} />
    <rect width='100%' height='100%' fill={`url(#conflict-pattern-2)`} />
  </svg>
);
