import { createStore, combineReducers, Store } from 'redux';

import { coreReducer, CoreStore } from './core';
import { mapReducer, MapStore } from './map';
import { cidMapReducer, CIDMapStore } from './map/cid-map';

export interface IStore extends Store {
  core: CoreStore;
  map: MapStore;
  cdiMap: CIDMapStore;
}

const rootReducer = combineReducers({
  core: coreReducer,
  map: mapReducer,
  cdiMap: cidMapReducer
});

export const store: IStore = createStore(rootReducer);
