import React from 'react';

import { useTranslation } from 'react-i18next';

import { MapConfigurationTempFilter } from 'domain/models/map-configuration.model';

import { Select } from 'components/shared/select/Select';

import './DateSelector.scss';

const MonthWeights: any = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};
export interface DateSelectorProps {
  startMonth: string;
  endMonth: string;
  temporalFilters: MapConfigurationTempFilter[];
  onChange(value: MapConfigurationTempFilter): void;
}

export const DateSelector = ({
  startMonth = 'Jan',
  endMonth = 'Feb',
  temporalFilters,
  onChange,
}: DateSelectorProps) => {
  const { t } = useTranslation(['config', 'components']);

  const months = temporalFilters.filter(
    (tf: MapConfigurationTempFilter) => tf.length === 1
  );

  const getMonth = (monthCode: string) =>
    months.find(
      (tf: MapConfigurationTempFilter) => tf.code === monthCode
    ) as MapConfigurationTempFilter;

  const getMonthLiteral = React.useCallback(
    (monthCode: string) => {
      if (monthCode !== 'custom' && monthCode !== 'year') {
        const firstMonth = monthCode.substring(0, 3);
        const lastMonth = monthCode.substring(3, 7);
        let literal = t('config:season.' + firstMonth);
        if (lastMonth) {
          literal +=
            ' ' + t('config:season.to') + ' ' + t('config:season.' + lastMonth);
        }
        return literal;
      } else {
        return t('config:season.' + monthCode);
      }
    },
    [t]
  );

  const buildOnChangeMonth = (
    action: 'startMonth' | 'endMonth',
    value: string
  ): MapConfigurationTempFilter => {
    const valueMonth = getMonth(value);
    if (action === 'startMonth') {
      const valueEndMonth = getMonth(endMonth);
      if (valueEndMonth.code === valueMonth.code) {
        return valueMonth;
      }
      const result: MapConfigurationTempFilter = {
        code: `${value}${valueEndMonth.code}`,
        filterType: 'ALL',
        length: 1,
        startMonth: valueMonth.startMonth,
      };
      return result;
    } else {
      const valueStartMonth = getMonth(startMonth);
      if (valueStartMonth.code === valueMonth.code) {
        return valueMonth;
      }
      const result: MapConfigurationTempFilter = {
        code: `${valueStartMonth.code}${value}`,
        filterType: 'ALL',
        length: 1,
        startMonth: valueStartMonth.startMonth,
      };
      return result;
    }
  };

  const startMonthOptions = React.useMemo(() => {
    return months.map((tf: MapConfigurationTempFilter) => ({
      label: getMonthLiteral(tf.code),
      value: tf.code,
      weight: MonthWeights[tf.code],
    })).sort((m1, m2) => m1.weight - m2.weight);
  }, [months, getMonthLiteral]);

  return (
    <div className='ipcc-date-selector' id='date-selector'>
      <Select
        label={t('components:optionSelector.season.startDate.title')}
        id='startDate'
        tabIndex={0}
        options={startMonthOptions}
        value={startMonth}
        menuIsOpen={true}
        menuPlacement='top'
        menuPosition='absolute'
        maxMenuHeight={300}
        onChange={({ value }: any) =>
          onChange(buildOnChangeMonth('startMonth', value))
        }
      />
      <Select
        label={t('components:optionSelector.season.endDate.title')}
        id='endDate'
        onChange={({ value }: any) =>
          onChange(buildOnChangeMonth('endMonth', value))
        }
        tabIndex={0}
        menuPlacement='top'
        menuPosition='absolute'
        maxMenuHeight={300}
        value={endMonth}
        options={startMonthOptions}
      />
    </div>
  );
};
