import React from 'react';

import { Translation } from 'react-i18next';

import { DefaultHash } from 'core/constants';

import './StaticPage.scss';

export class MapPageErrorBoundaryRedirect extends React.Component<
  React.PropsWithChildren<any>,
  {
    withError: boolean;
    countdown: number;
    error: any;
    errorInfo: string | null;
  }
> {
  static initialState = {
    withError: false,
    countdown: 5,
    error: null,
    errorInfo: null
  };

  constructor(props: any) {
    super(props);

    this.state = MapPageErrorBoundaryRedirect.initialState;
  }

  static getDerivedStateFromError() {
    return { withError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('Error', error);
    console.log('ErrorInfo', errorInfo)

    this.setState(
      {
        withError: true,
        error,
        errorInfo
      },
      () => {
        setInterval(() => {
          if (this.state.countdown > 0) {
            this.setState({
              countdown: this.state.countdown - 1
            });
          } else {
            this.redirect();
          }
        }, 1000);
      }
    );
  }

  redirect() {
    window.location.hash = DefaultHash;
    window.location.reload();
  }

  render() {
    if (this.state.withError) {
      return (
        <div className='error-boundary__container'>
          <Translation>
            {t => (
              <>
                <h3 className='error-boundary__message'>
                  {t('errorBoundary.message')} {this.state.countdown}{' '}
                  {t('errorBoundary.seconds')}
                </h3>
                <span
                  className='error-boundary__action-redirect'
                  onClick={this.redirect}
                >
                  {t('errorBoundary.actions.redirect.title')}
                </span>
              </>
            )}
          </Translation>
          {/* <code>
            {this.state.error?.stack  || ''}
            {JSON.stringify(this.state.errorInfo || {}) || ''}
          </code> */}
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
