import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { Routes } from 'core';

import { LangSelect } from 'components/lang-select/LangSelect';

import ipccLogo from 'assets/icons/ipcc_altlogo_full_white.png';

import './MobileMenu.scss';

export const MobileMenu = ({ onRouteSelected }: any) => {
  const { t } = useTranslation(['components']);

  const { pathname } = useLocation();

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 300);
  }, []);

  return (
    <div className='ipcc-mobile-menu'>
      <div className='ipcc-mobile-icon__container' role='navigation'>
        <CSSTransition
          unmountOnExit
          in={show}
          timeout={300}
          classNames='ipcc-logo-transition'
        >
          <img
            src={ipccLogo}
            alt={t('components:header.images.ipcc.aria.alt')}
          />
        </CSSTransition>
      </div>
      <div className='ipcc-mobile-links__container' role='navigation'>
        <CSSTransition
          unmountOnExit
          in={show}
          timeout={300}
          classNames={'ipcc-link-transition'}
        >
          <Link
            className='ipcc-mobile-link'
            onClick={onRouteSelected}
            tabIndex={0}
            aria-label={t('components:header.menu.home.aria.label')}
            to={Routes.Landing}
          >
            {t('components:header.menu.home.title')}
          </Link>
        </CSSTransition>
        <CSSTransition
          unmountOnExit
          in={show}
          timeout={300}
          classNames={'ipcc-link-transition'}
        >
          <Link
            className='ipcc-mobile-link'
            tabIndex={0}
            aria-label={t('components:header.menu.about.aria.label')}
            onClick={onRouteSelected}
            to={
              pathname === Routes.CID
                ? Routes.RegionalSyntesisAbout
                : Routes.RegionalInformationAbout
            }
            target='_blank'
          >
            {t('components:header.menu.about.title')}
          </Link>
        </CSSTransition>

        <CSSTransition
          unmountOnExit
          in={show}
          timeout={300}
          classNames={'ipcc-link-transition'}
        >
          <Link
            className='ipcc-mobile-link'
            tabIndex={0}
            aria-label={t('components:header.menu.license.aria.label')}
            onClick={onRouteSelected}
            to={Routes.License}
            target='_blank'
          >
            {t('components:header.menu.license.title')}
          </Link>
        </CSSTransition>

        {/* <CSSTransition
          unmountOnExit
          in={show}
          timeout={300}
          classNames={'ipcc-link-transition'}
        >
          <LangSelect />
        </CSSTransition> */}
      </div>
    </div>
  );
};
