import React from 'react';

import cn from 'classnames';

import './BaseControl.scss';
import { useTranslation } from 'react-i18next';
import { validateKeyboardEvent } from 'utils/utils';

export interface BaseControlProps {
  onClick?(): any;
  cssClass?: string;
  title?: string;
  tooltip?: string;
  ref?: React.RefObject<any>;
}

export const BaseControl = (
  props: React.PropsWithChildren<BaseControlProps>
) => {
  const { t } = useTranslation('map');

  const className = cn('ipcc-base-control', 'ol-unselectable', props.cssClass);
  return (
    <div
      role='button'
      tabIndex={0}
      aria-label={t('map:controls.aria.label', { label: props.tooltip })}
      ref={props.ref}
      className={className}
      onKeyDown={validateKeyboardEvent(() => props.onClick && props.onClick())}
      onClick={() => props.onClick && props.onClick()}
      title={props.title}
      data-tooltip={props.tooltip}
    >
      {props.children}
    </div>
  );
};
