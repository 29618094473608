import React from 'react';

import { useTranslation } from 'react-i18next';

import { RegionSet } from 'domain/models/map-configuration.model';

import { Select } from 'components/shared/select/Select';

import './MapControls.scss';

const RegionSetSortInfo: { [region in RegionSet]: number } = {
  ar6: 10,
  biome: 60,
  continent: 20,
  monsoon: 30,
  riverbasin: 40,
  smallisland: 50,
  none: 60,
};

const sortFn = (rs1: RegionSet, rs2: RegionSet): number =>
  RegionSetSortInfo[rs1] - RegionSetSortInfo[rs2];

export const RegionSetControl = ({
  cssClass,
  value,
  regionsSets,
  onChange,
}: {
  cssClass?: string;
  value: RegionSet;
  regionsSets: RegionSet[];
  onChange: (value: any) => void;
}) => {
  const { t } = useTranslation(['map', 'config']);
  return (
    <Select
      label={t('map:controls.regionSetControl.label')}
      className={cssClass}
      value={value}
      options={regionsSets.sort(sortFn).map((set: RegionSet) => ({
        value: set,
        label: t(`config:regionSet.${set}`),
      }))}
      onChange={onChange}
    />
  );
};
