export const getDeviceOrientation = () => {
  return window.screen.orientation ? window.screen.orientation.type : undefined;
};

export const isLandscape = () => {
  const orientation = getDeviceOrientation();
  if (orientation) {
    return orientation.startsWith('landscape');
  }
  return false;
};

export const onOrientationChange = (cb: (evt: any) => void) => {
  if (window.screen.orientation) {
    window.screen.orientation.addEventListener('change', cb);
    return () => window.screen.orientation.removeEventListener('change', cb);
  }
};

export const isSmallScreen = () => document.documentElement.clientWidth <= 767;

export const getIsMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}
