import React from 'react';

const windowResizeObserver = new (class WindowResizeObserver {
  private listeners: VoidFunction[] = [];

  constructor() {
    window.addEventListener('resize', this.listener.bind(this), {
      passive: true,
    });
  }

  private listener() {
    for (let listener of this.listeners) {
      listener();
    }
  }

  public subscribe(callback: VoidFunction) {
    this.listeners.push(callback);
  }

  public unsubscribe(callback: VoidFunction) {
    this.listeners.filter((cb) => cb !== callback);
  }
})();

export const useDevicePixelRatio = () => {
  const [devicePixelRatio, setDevicePixelRatio] = React.useState(
    window.devicePixelRatio
  );

  React.useEffect(() => {
    const callback = () => setDevicePixelRatio(window.devicePixelRatio);
    windowResizeObserver.subscribe(callback);

    return windowResizeObserver.unsubscribe(callback);
  }, []);

  return devicePixelRatio;
};

export const isDeviceZoomed = () => window.devicePixelRatio !== 1;
