import { LegendSegment } from 'components/json-legend/JSONLegend';
import { head, last } from './utils';

export type LegendData = {
  maxValue: number;
  minValue: number;
  colors: string[];
  zeroColor: string | undefined;
};

export type ParsedLegendSegment = {
  maxValue: string;
  minValue: string;
  maxLabel?: string;
  minLabel?: string;
  firstColor: string;
  lastColor: string;
  index: number;
  zeroColor?: string;
  meanValue: number;
};

export type RGBColorArray = [number, number, number];

export const getDataAttrs = (legend: HTMLDivElement) => {
  const children = Array.from(legend.children);

  const values: number[] = [],
    colors: string[] = [];
  let zeroColor: string | undefined = undefined;

  children.forEach((el: Element) => {
    const { lastColor, firstColor, firstValue, lastValue } = (
      el as HTMLDivElement
    ).dataset;

    let val = 0;

    if ((lastValue as string).includes('Infinity')) {
      val = Number(firstValue);
    } else {
      val = Number(lastValue);
      if (val === 0) {
        zeroColor = lastColor;
      }

      if (Number(firstValue) === 0) {
        zeroColor = firstColor;
      }
    }

    colors.push(firstColor || '');
    values.push(val);
  });

  if (!zeroColor) {
    zeroColor = getNearestToZero(colors, values);
  }

  return { values, colors, zeroColor };
};

const getNearestToZero = (colors: string[], values: number[]): string => {
  const delta = Math.abs(values[0] - values[1]);

  for (let i = 0; i < colors.length; i++) {
    const value = values[i];

    if (Math.abs(value) - delta < 0) {
      return colors[i];
    }
  }

  return colors[Math.floor(colors.length / 2)];
};

export const extractDataFromRawLegend = (rawLegend: string): LegendData => {
  const container = document.createElement('div') as HTMLDivElement;
  container.style.display = 'none';
  container.innerHTML = rawLegend;

  document.body.append(container);

  const legend = container.firstChild;

  const { values, colors, zeroColor } = getDataAttrs(legend as HTMLDivElement);

  container.remove();

  return {
    maxValue: Math.max(...values.filter((v: number) => !isNaN(v))),
    minValue: Math.min(...values.filter((v: number) => !isNaN(v))),
    colors,
    zeroColor,
  };
};

export const extractDataForLegendSegments = (
  rawLegend: string
): ParsedLegendSegment[] => {
  const container = document.createElement('div') as HTMLDivElement;
  container.style.display = 'none';
  container.innerHTML = rawLegend;

  document.body.append(container);

  const legend = container.firstChild;

  if (!legend) {
    return [];
  }

  const children = Array.from((legend as HTMLDivElement).children);

  const segmentData: ParsedLegendSegment[] = [];

  children.forEach((el: Element, index: number) => {
    const {
      lastColor,
      firstColor,
      firstValue,
      lastValue,
      firstLabel,
      lastLabel,
    } = (el as HTMLDivElement).dataset;

    let zeroColor,
      _firstValue = firstValue,
      _lastValue = lastValue,
      meanValue,
      hasInfinity = false;

    if ((firstValue as string).includes('Infinity')) {
      hasInfinity = true;
      meanValue = Number(lastValue);
    } else if ((lastValue as string).includes('Infinity')) {
      hasInfinity = true;
      meanValue = Number(firstValue);
    } else {
      meanValue = (Number(firstValue) + Number(lastValue)) / 2;
    }

    if (Number(_firstValue) === 0) {
      zeroColor = firstColor!.substring(0, 7);
    }
    if (Number(_lastValue) === 0) {
      zeroColor = lastColor!.substring(0, 7);
    }
    if (!hasInfinity) {
      segmentData.push({
        index,
        zeroColor,
        firstColor: firstColor!.substring(0, 7) as string,
        lastColor: lastColor!.substring(0, 7) as string,
        maxValue: hasInfinity ? meanValue.toString() : (_firstValue as string),
        minValue: hasInfinity ? meanValue.toString() : (_lastValue as string),
        meanValue,
        maxLabel: firstLabel ?? (_firstValue as string),
        minLabel: lastLabel ?? (_lastValue as string),
      });
    }
  });
  container.remove();
  return segmentData.reverse();
};

export const hexToRgb = (hex: string): RGBColorArray | undefined => {
  const _hex = hex.substring(0, Math.min(hex.length, 7)); // Discard Alpha
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : undefined;
};

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

export const rgbToHex = ([r, g, b]: RGBColorArray) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const diffUint8ClampedArray = (
  prev: Uint8ClampedArray | null,
  curr: Uint8ClampedArray | null
): boolean => {
  if (!prev && curr) {
    return true;
  } else if (prev && curr) {
    const [r1, g1, b1, a1] = prev;
    const [r2, g2, b2, a2] = curr;

    return r1 !== r2 || g1 !== g2 || b1 !== b2 || a1 !== a2;
  } else if (prev && !curr) {
    return true;
  }
  return false;
};

export const getSegmentsBetween = (
  segments: ParsedLegendSegment[],
  selectedSegments: LegendSegment[]
): LegendSegment[] => {
  let result: LegendSegment[] = [];
  const sortedSelected = selectedSegments.sort((s1, s2) => s1.index - s2.index);
  const _first = head(sortedSelected)!;
  const _last = last(sortedSelected)!;
  const sortedSegments = segments.sort((s1, s2) => s1.index - s2.index);
  for (let segment of sortedSegments) {
    if (segment.index >= _first?.index && segment.index <= _last.index) {
      const newSegment: Partial<LegendSegment> = {
        from: hexToRgb(segment.firstColor),
        to: hexToRgb(segment.lastColor),
        index: segment.index,
      };
      result.push(newSegment as LegendSegment);
    }
  }

  return result;
};
