import React from 'react';

import { useTranslation } from 'react-i18next';

import { BaseControl } from './base/BaseControl';
import { PlusIcon, MinusIcon } from 'components/shared/icons';

import './MapControls.scss';

export const ZoomControl = ({
  onClick,
  cssClass
}: {
  onClick: (mode: 'zoom-in' | 'zoom-out') => any;
  cssClass?: string;
}) => {
  const { t } = useTranslation(['map']);
  return (
    <>
      <BaseControl
        onClick={() => onClick('zoom-in')}
        cssClass={`zoom-control zoom-in-control ${cssClass}`}
        title={t('map:controls.zoomIn.title')}
        tooltip={t('map:controls.zoomIn.title')}
      >
        <PlusIcon width='18px' height='18px' fill='white' />
      </BaseControl>
      <BaseControl
        onClick={() => onClick('zoom-out')}
        cssClass={`zoom-control zoom-out-control ${cssClass}`}
        title={t('map:controls.zoomOut.title')}
        tooltip={t('map:controls.zoomOut.title')}
      >
        <MinusIcon width='18px' height='18px' fill='white' />
      </BaseControl>
    </>
  );
};
