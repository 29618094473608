import React from 'react';

import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { useIsMobile } from 'hooks/useIsMobile';

import { PlusIcon } from 'components/shared/icons';

import './MapControls.scss';

interface MobileContainerProps {
  open: boolean;
  onClick(evt: any): void;
  className?: string;
}

export const MobileContainer = (
  props: React.PropsWithChildren<MobileContainerProps>
) => {
  const isMobile = useIsMobile();

  const transitionDuration = 600;
  const childCount = React.Children.toArray(props.children).filter(
    (child: any) => {
      return child && React.isValidElement(child);
    }
  ).length;
  const maxDelay = 100 * childCount;

  let i = 0;

  return (
    <div
      className={cn(
        'mobile-map-controls__container',
        {
          open: props.open,
          mobile: isMobile,
        },
        props.className
      )}
    >
      <div
        className={cn('mobile-map-controls__controls', { open: props.open })}
      >
        {React.Children.map(props.children, (child: React.ReactNode) => {
          const transitionDelay = maxDelay - (i + 1) * 100;
          if (child && React.isValidElement(child)) {
            i++;
            return (
              <CSSTransition
                key={`${transitionDelay}`}
                timeout={transitionDuration + transitionDelay}
                classNames='mobile-control-transition'
                unmountOnExit
                in={props.open}
              >
                <div style={{ transitionDelay: `${transitionDelay}ms` }}>
                  {child}
                </div>
              </CSSTransition>
            );
          } else {
            return child;
          }
        })}
      </div>
      <div
        className={cn('mobile-map-controls__button', { open: props.open })}
        onClick={props.onClick}
        role='button'
      >
        <PlusIcon width='30px' height='30px' />
      </div>
    </div>
  );
};
