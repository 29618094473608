import React from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from 'hooks/useIsMobile';

import { DropDownBox } from 'components/shared/drop-down-box/DropDownBox';
import { ArrowDownIcon } from 'components/shared/icons';

import './Options.scss';

export const OptionSelector = ({
  last,
  icon,
  title,
  className = '',
  subtitle,
  id,
  body,
}: any) => {
  const { t } = useTranslation('components');

  const [open, setOpen] = React.useState(false);

  const isMobile = useIsMobile();

  return (
    <DropDownBox
      id={id}
      className={cn(
        'option__dropdown-box',
        { last },
        { mobile: isMobile },
        { open },
        className
      )}
      tabIndex={0}
      aria-label={t('components:optionSelector.dropDownBox.header.aria.label', {
        title,
      })}
      animation='fade'
      onDropDownHover={() => setOpen(true)}
      onDropDownLeave={() => setOpen(false)}
    >
      <div className={`option__dropdown-header `}>
        <div className='option__image-wrapper'>{icon}</div>
        <div className='option__title--wrapper'>
          <span className='option__title'>{title}</span>
        </div>
        <ArrowDownIcon className='option-arrow' width='15px' height='15px' />
      </div>
      <div
        className='option__dropdown-content'
        aria-label={t('components:optionSelector.dropDownBox.body.aria.label', {
          title,
        })}
      >
        {body}
      </div>
    </DropDownBox>
  );
};
