import React from 'react';
import { ChartErrorMessage } from './ChartErrorMessage';

export class ChartsContainerErrorBoundary extends React.Component<
  React.PropsWithChildren<any>,
  { withError: boolean }
> {
  static initialState = {
    withError: false,
  };

  constructor(props: any) {
    super(props);
    this.state = ChartsContainerErrorBoundary.initialState;
  }

  static getDerivedStateFromError() {
    return { withError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(
      JSON.stringify(error),
      JSON.stringify(errorInfo),
      window.location.href
    );
  }

  render = () =>
    this.state.withError ? <ChartErrorMessage show /> : this.props.children;
}
