import { IPCCProjections } from './map.models';
import { Nullable } from './shared.types';

export type RegionSet =
  | 'ar6'
  | 'biome'
  | 'monsoon'
  | 'continent'
  | 'riverbasin'
  | 'smallisland'
  | 'none';
export type AvailableGeojson =
  | 'ar6'
  | 'ar6pacific'
  | 'biome'
  | 'biomepacific'
  | 'monsoon'
  | 'monsoonpacific'
  | 'continent'
  | 'continentpacific'
  | 'riverbasin'
  | 'riverbasinpacific'
  | 'smallisland'
  | 'smallislandpacific'
  | 'none';

export type ValueType = 'ANOMALY' | 'RELATIVE_ANOMALY' | 'VALUE' | 'TREND';

export type HatchingType = 'DISABLED' | 'SIMPLE' | 'COMPREHENSIVE';

export const ValueTypePrefixes: { [K in ValueType]: string } = {
  ANOMALY: '_anom',
  RELATIVE_ANOMALY: '_relanom',
  VALUE: '',
  TREND: '_trend',
};

export const GeojsonsByRegionSetAndProjection: {
  [P in keyof IPCCProjections]: {
    [RS in RegionSet]: AvailableGeojson;
  };
} = {
  'EPSG:3412': {
    ar6: 'ar6',
    biome: 'biome',
    monsoon: 'monsoon',
    continent: 'continent',
    riverbasin: 'riverbasin',
    smallisland: 'smallisland',
    none: 'none',
  },
  'EPSG:3413': {
    ar6: 'ar6',
    biome: 'biome',
    monsoon: 'monsoon',
    continent: 'continent',
    riverbasin: 'riverbasin',
    smallisland: 'smallisland',
    none: 'none',
  },
  'EPSG:54030': {
    ar6: 'ar6',
    biome: 'biome',
    monsoon: 'monsoon',
    continent: 'continent',
    riverbasin: 'riverbasin',
    smallisland: 'smallisland',
    none: 'none',
  },
  'EPSG:54030pc': {
    ar6: 'ar6pacific',
    biome: 'biomepacific',
    monsoon: 'monsoonpacific',
    continent: 'continentpacific',
    riverbasin: 'riverbasinpacific',
    smallisland: 'smallislandpacific',
    none: 'none',
  },
} as const;

export const CoastlineByProjection: { [P in keyof IPCCProjections]: string } = {
  'EPSG:3412': '/data/coastline.json',
  'EPSG:3413': '/data/coastline.json',
  'EPSG:54030': '/data/coastline.json',
  'EPSG:54030pc': '/data/coastline_pacific_robinson.json',
};

export type Style = {
  [K in ValueType]: string;
};

export type Units = {
  [K in ValueType]: string;
};

export type ModelCount = {
  [scenario: string]: {
    [variable: string]: {
      [period: string]: number;
    };
  };
};

export interface MapConfigurationModelScenarios {
  [K: string]: ValueType[];
}

export interface MapConfigurationProperties {
  WMS_URL: string;
}

export interface MapConfigurationModelInfo {
  code: string;
  name: string;
  gcm: Nullable<string>;
  project: string;
  family: string;
  weight: number;
  projection: keyof IPCCProjections;
}

export type ModelPeriods = {
  [Scenario: string]: {
    [Variable: string]: string[];
  };
};

export type ModelBaselines = {
  [Scenario: string]: {
    [Variable: string]: string[];
  };
};

export interface MapConfigurationModel {
  model: MapConfigurationModelInfo;
  variables: string[];
  scenarios: MapConfigurationModelScenarios;
  regions: RegionSet[];
  baselines: ModelBaselines;
  periods: ModelPeriods;
  modelCount: ModelCount;
}

export interface MapConfigurationVariable {
  code: string;
  name: string;
  description: string;
  units: Units;
  link: null | 'highlight';
  variableType: string;
  temporalFilter: string[];
  valueType: ValueType[];
  scenarios: string[];
  regionset: RegionSet[];
  weight: number;
  styles: Style;
  tsEnsemble: {
    [Model: string]: boolean;
  };
}

export interface MapConfigurationScenario {
  code: string;
  name: string;
  description: string;
  weight: number;
  disabled?: boolean;
}

export interface MapConfigurationTempFilter {
  code: string;
  startMonth: number;
  length: number;
  filterType: string;
}

export interface MapConfigurationPeriod {
  code: string;
  wmsCode: string;
  periodType: string;
  name: string;
  startYear: Nullable<number>;
  endYear: Nullable<number>;
  weight: number;
}

export interface MapConfigurationBaseline {
  name: string;
  code: string;
  weight: number;
}

export interface MapConfiguration {
  properties: MapConfigurationProperties;
  models: MapConfigurationModel[];
  variables: MapConfigurationVariable[];
  scenarios: MapConfigurationScenario[];
  temporalFilters: MapConfigurationTempFilter[];
  periods: MapConfigurationPeriod[];
  valueTypes: ValueType[];
  baselines: MapConfigurationBaseline[];
}
