import { PlusIcon } from 'components/shared/icons';
import React from 'react';

import { useSecretCode, CODE } from 'hooks/useSecretCode';

import './Credits.scss';

const code = 'atlas-credits'.split('');

interface CreditsProps {}
export const Credits = (props: CreditsProps) => {
  const [showCredits, setShowCredits] = React.useState(false);

  const onUnlock = React.useCallback(() => {
    setShowCredits((s) => !s);
  }, []);

  useSecretCode({ sequence: code, onUnlock });

  const dispatchKeyDown = (key: string) => {
    window.dispatchEvent(new KeyboardEvent('keydown', { key }));
  };

  const onClose = () => {
    for (let char of code) {
      dispatchKeyDown(char);
    }
  };

  return showCredits ? (
    <div className='atlas-credits'>
      <div className='credits-backdrop'></div>
      <PlusIcon className='close-icon' onClick={onClose} />
      <div className='credits-modal'>
        <p className='credits-title'>
          Congratulations, you found the Easter Egg on the Interactive Atlas!
        </p>
        <p>
          The tool was developed by the Atlas team, comprising many people.
          WIthout them and their work, this tool would never exist. The
          technical implementation was executed by{' '}
          <a href='https://predictia.es' target='_blank'>
            Predictia
          </a>
          . The names behind the Atlas team are:
        </p>
        <p className='list-title'>Coordinating Lead Authors:</p>
        <ul>
          <li>José Manuel Gutiérrez (Spain)</li>
          <li>Richard G. Jones (UK)</li>
          <li>Gemma Teresa Narisma (Philippines)</li>
          <li>Lead Authors</li>
          <li>Muhammad Amjad (Pakistan)</li>
          <li>Irina V. Gorodetskaya (Portugal, Belgium/Russian Federation)</li>
          <li>Michael Grose (Australia)</li>
          <li>Nana Ama Browne Klutse (Ghana)</li>
          <li>Svitlana Krakovska (Ukraine)</li>
          <li>Jian Li (China)</li>
          <li>Daniel Martínez-Castro (Cuba)</li>
          <li>Linda Mearns (USA)</li>
          <li>Sebastian H. Mernild (Norway/Denmark, Denmark)</li>
          <li>Lincoln Muniz Alves (Brazil)</li>
          <li>Thanh Ngo-Duc (Vietnam)</li>
          <li>Bart van den Hurk (The Netherlands)</li>
          <li>Jin-Ho Yoon (Republic of Korea)</li>
        </ul>
        <p className='list-title'>Contributing Authors (Interactive Atlas):</p>
        <ul>
          <li>Maialen Iturbide (Spain)</li>
          <li>Jorge Baño-Medina (Spain)</li>
          <li>Joaquín Bedia (Spain)</li>
          <li>Ana Casanueva (Spain)</li>
          <li>Ezequiel Cimadevilla (Spain)</li>
          <li>Antonio S. Cofiño (Spain)</li>
          <li>Javier Díez Sierra (Spain)</li>
          <li>Jesús Fernández (Spain)</li>
          <li>Markel García (Spain)</li>
          <li>Sixto Herrera (Spain)</li>
          <li>Rodrigo Manzanas (Spain)</li>
          <li>Josipa Milovac (Spain/Croatia)</li>
          <li>Juan José Sáenz de la Torre (Spain)</li>
          <li>Daniel San Martín (Spain)</li>
          <li>Iván Luis Sánchez (Spain)</li>
          <li>Elena Suárez (Spain)</li>
          <li>Max Tuni (Spain)</li>
        </ul>

        <p className='list-title'>Review Editors:</p>
        <ul>
          <li>Inés Camilloni (Argentina)</li>
          <li>Jens Hesselbjerg Christensen (Denmark)</li>
          <li>Fatima Driouech (Morocco)</li>
        </ul>
        <p className='list-title'>Chapter Scientists:</p>
        <ul>
          <li>Maialen Iturbide (Spain)</li>
          <li>Ma. Laurice Preciado Jamero (Philippines)</li>
          <li>Émilie Vanvyve (UK/Belgium)</li>
        </ul>
      </div>
    </div>
  ) : null;
};
