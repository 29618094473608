import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import MapBrowserPointerEvent from 'ol/MapBrowserEvent';
import { Url } from 'simple-query-params/lib/url';
import View from 'ol/View';

import {
  HatchingType,
  MapConfiguration,
  RegionSet,
} from 'domain/models/map-configuration.model';
import { ControllerOptionsState } from 'components/option-selector/options.model';
import { MapIdentifier } from 'store/map';
import { serializeParamsToMapUrl } from 'utils/url-serializer';
import { AppConstants } from 'core/constants';
import { useIsMobile } from 'hooks/useIsMobile';
import {
  DynamicLegendStyleTag,
  IPCCProjection,
} from 'domain/models/map.models';
import { selectedOptionsToLegendParameters } from 'utils/map.utils';
import { uniq } from 'utils/utils';

import { Map } from './Map';
import { OptionController } from 'components/option-selector/OptionController';
import { MapCaption } from 'components/map-caption/MapCaption';
import { RegionSetControl } from 'components/map-controls/RegionSetControl';

import './Map.scss';
import './Map.responsive.scss';
import { HatchingControl } from 'components/map-controls/HatchingControl';
import { useSelector } from 'react-redux';
import { IStore } from 'store/store';
import { SelectAllControl } from 'components/map-controls/SelectAllControl';

interface MapContainerProps {
  mirroring: boolean;
  config: MapConfiguration;
  mapView: View;
  selectedOptions: ControllerOptionsState;
  primary?: boolean;
  regionSetSelected: RegionSet;
  regionsSelected: any[];
  geoJson: any;
  mapProjection: IPCCProjection;
  infoActive: boolean;
  graticuleActive: boolean;
  setRegionsSelected(features: any[]): void;
  onOptionsChange(...args: any): void;
  onRegionSetChange(evt: any, mapIdentifier: MapIdentifier): void;
  onInfoClick(evt: MapBrowserPointerEvent): Promise<any>;
}

export function MapContainer({
  mirroring,
  config,
  mapView,
  selectedOptions,
  primary,
  geoJson,
  mapProjection,
  infoActive,
  regionsSelected,
  regionSetSelected,
  graticuleActive,
  children,
  setRegionsSelected,
  onOptionsChange,
  onRegionSetChange,
  onInfoClick,
}: React.PropsWithChildren<MapContainerProps>) {
  const [url, setUrl] = useState(new Url(''));
  const [legendParameters, setLegendParameters] = useState({});
  const [availableRegionSets, setAvailableRegionSets] = useState<RegionSet[]>(
    []
  );

  const mapIdentifier = React.useMemo(
    () => (primary ? 'primaryMap' : 'secondaryMap'),
    [primary]
  );

  const hatching = useSelector(
    (store: IStore) => store.map[mapIdentifier].hatching
  );
  const legendConfig = useSelector(
    (store: IStore) => store.map[mapIdentifier].legendConfiguration
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    if (selectedOptions.period && selectedOptions.dataset) {
      let legendOpts = {};
      if (legendConfig) {
        const { styleTickInterval, ...rest } = legendConfig;
        legendOpts = {
          ...legendConfig,
          STYLES: DynamicLegendStyleTag,
        };
      }
      const builtUrl = serializeParamsToMapUrl(
        selectedOptions,
        { hatching, ...legendOpts },
        `${AppConstants.Api.baseUrl}${config.properties.WMS_URL}`
      );
      setUrl(builtUrl);
    }
  }, [selectedOptions, config, hatching, legendConfig]);

  useEffect(() => {
    if (selectedOptions.variable) {
      const { regionset } = selectedOptions.variable;
      if (regionset.length === 1) {
        // onRegionSetChange(
        //   { value: regionset[0] },
        //   mapIdentifier
        // );
      }
    }
  }, [selectedOptions.variable, onRegionSetChange, primary]);

  useEffect(() => {
    const datasetRegions = selectedOptions.dataset.regions || [];
    const variableRegions = selectedOptions.variable.regionset || [];

    const prodRegionSets = uniq(
      datasetRegions.filter((rs) => variableRegions.includes(rs)).concat('none')
    );
    setAvailableRegionSets(prodRegionSets);

    setLegendParameters(selectedOptionsToLegendParameters(selectedOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOptions.dataset,
    selectedOptions.scenario,
    selectedOptions.variable,
    selectedOptions.valueType,
  ]);

  return (
    <div
      className={cn(
        'map__container',
        { primary },
        { secondary: !primary },
        { mirroring },
        { mobile: isMobile }
      )}
    >
      <div className='map__container__options'>
        {config && (
          <OptionController
            mapIdentifier={primary ? 'primaryMap' : 'secondaryMap'}
            onOptionsChange={onOptionsChange}
            selectedOptions={selectedOptions}
            config={config}
          />
        )}
      </div>
      <div className='map-selectors-container'>
        {!mirroring && availableRegionSets.length > 1 && (
          <div className='region-set-selector-wrapper'>
            <RegionSetControl
              onChange={(evt) => onRegionSetChange(evt, 'primaryMap')}
              regionsSets={availableRegionSets}
              value={regionSetSelected}
              cssClass='region-set-selector map-selector'
            />
            <SelectAllControl />
          </div>
        )}
        {['ANOMALY', 'RELATIVE_ANOMALY', 'TREND'].includes(
          selectedOptions.valueType
        ) &&
          !mirroring && (
            <HatchingControl
              mapIdentifier={mapIdentifier}
              cssClass='hatching-control map-selector reversed'
            />
          )}
      </div>
      {url && (
        <Map
          mirroring={mirroring}
          url={url.builtUrl}
          mapIdentifier={mapIdentifier}
          onMapClick={onInfoClick}
          geoJson={geoJson}
          mapProjection={mapProjection}
          mapView={mapView}
          legendParameters={legendParameters}
          variable={selectedOptions.variable}
          valueType={selectedOptions.valueType}
          infoActive={infoActive}
          selectedRegions={regionsSelected}
          graticuleActive={graticuleActive}
          setSelectedRegions={setRegionsSelected}
        >
          {children}
        </Map>
      )}
      <MapCaption
        optionsSelected={selectedOptions}
        regionsSelected={regionsSelected}
        primary
        mirroring={mirroring}
      />
    </div>
  );
}
